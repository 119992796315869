import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants.js";
import media from "../../global/media";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SliderCardComponent from "./SliderCardComponent";
import { decodeContent, randomImageGenerator } from "../../global/constants";
const CardWrapper = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  line-height: 2;
  padding: 10px;
  padding: 40px 10px;
  z-index: 1;
  ${media.sm} {
    padding: 40px 20px;
  }
  .card-center {
    display: flex;
    justify-content: center;
  }
  span {
    font-weight: 600;
  }
`;
const PromotionWrapper = styled.div`
  background: ${(prop) => prop.bgColor || "#fff"};
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  width: 95%;
  margin-left: 0;
  ${media.sm} {
    margin-left: 5%;
    flex-direction: row;
  }
  .centerContent {
    position: relative;
    padding: 1em 0.5em;
    width: 100%;
    display: flex;
  }
`;

const HomePageComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const facilities = templateData.propertyDetails.facilities.facility;
  const accommodationSlugName = templateData.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  const contentLimit = 400;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    speed: 1000,
    cssEase: "linear",
    arrow: true,
  };
  return (
    <div>
      
        <SliderCardComponent
          roomTypes={roomTypes}
          mainSlugName={ `${process.env.PUBLIC_URL}` + accommodationSlugName[0]["menuSlugName"]}
        />
     
      <SectionWrapper bgColor="#191919">
        <Grid>
          <Row>
            <Col xs={12} sm={6}>
              <div className="image-wrap">
                <img
                  src={randomImageGenerator(templateData.propertyImages.image)}
                  alt=""
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="content-wrap">
                <h2>{templateData.propertyDetails.propertyName}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      templateData.aboutUs === "1"
                        ? decodeContent(
                            templateData.propertyDetails.propertyDescription.substring(
                              0,
                              contentLimit
                            )
                          ) +
                          "..." +
                          ` <a href=${process.env.PUBLIC_URL}/about-us/>Read more</a>`
                        : decodeContent(
                            templateData.propertyDetails.propertyDescription
                          ),
                  }}
                ></p>
              </div>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      <SectionWrapper bgColor="#191919">
        <Grid>
          <PromotionWrapper bgColor="#191919">
            <Row>
              <Col xs={12}>
                <h2 class="left">Property Facilities</h2>
              </Col>
            </Row>
            <div className="centerContent">
              <ul className="facilities">
                {facilities.slice(0, 36).map((item, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon={faChevronRight} />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </PromotionWrapper>
        </Grid>
      </SectionWrapper>
    </div>
  );
};

export default HomePageComponent;
