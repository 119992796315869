import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";

import { SectionWrapper } from "../Template7Pages/templateConstants";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
} from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { NormalLink } from "../Template7Pages/Links";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { bookRoomUrl } from "../../global/constants";
import media from "../../global/media";
import constant from "../Template7Pages/constant";
import { Link } from "react-router-dom";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";


const AccommodationImageWrap = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  padding: 0 0 4em 0;
  position: relative;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  ul {
    margin-top: 40px !important;
  }
  .booknow a {
    width: auto;
    display: inline-block;
    border-radius: 0;
    padding: 10px 20px;
  }
`;

const SliderWrapper = styled.div`
  height: 300px;
  overflow: hidden;
  .slick-slider {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58, 49, 56, 0.8);
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    left: 20px;
    top: calc(300px - 50%);
  }
  .slick-next {
    right: 20px;
    top: calc(300px - 50%);
  }
`;

const SlideImage = styled.div`
  img {
    width: 100%;
  }
`;

const NoHeaderImage = styled.div`
  height: 100px;
`;

const BreadCrumb = styled.div`
  background: #eee;
  padding: 10px 0;
  a {
    text-decoration: none;
    color: ${constant.primaryColor} !important;
  }
  span {
    color: ${constant.secondaryColor};
    padding: 0 5px;
  }
`;
const LinkWrapper = styled.a`
  padding: 5px 10px;
  color: #fff;
  position: relative;
  margin-top: 10px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: 2px solid ${constant.primaryColor};
  font-size: 13px;
  background: ${constant.primaryColor};
`;

const RoomTypeComponent = ({ data, templateData }) => {
  const facilities = data.RoomTypeFacilities
    ? data.RoomTypeFacilities.RooomFacility &&
      data.RoomTypeFacilities.RooomFacility.Facility
      ? data.RoomTypeFacilities.RooomFacility.Facility
      : []
    : [];
    const breadCrumbName = templateData.menuLinks.menuLink.filter(i=> i.pageId === "2" );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.RoomTypeName.section}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      <NoHeaderImage />
      {data.PreviousPageURL && (
        <BreadCrumbComponent 
          previousUrl = {data.PreviousPageURL}
          currentUrl = {data.RoomTypeName.section}
          previousName={breadCrumbName[0].MenuLinkpageName}
      />
      )}
      <SectionWrapper className="margin">
        <Grid>
          <Row>
            <Col md={12}>
              <h2>
                {data.RoomTypeName.section}
                <span></span>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <AccommodationImageWrap>
                <Row>
                  <Col xs={12} md={6}>
                    {data.RoomTypeImages.Images.length > 0 ? (
                      <SliderWrapper>
                        <Slider {...settings}>
                          {data.RoomTypeImages.Images.map((item, index) => (
                            <SlideImage key={index}>
                              <img src={sliderImageURL + item} alt="" />
                            </SlideImage>
                          ))}
                        </Slider>
                      </SliderWrapper>
                    ) : (
                      <img src={noImageUrl} alt="" />
                    )}
                  </Col>
                  <Col xs={12} md={6}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeContent(data.RoomTypeDescription),
                      }}
                      className="roomTypeContent"
                    ></div>
                    <Row>
                      <Col md={12}>
                        <FontAwesomeIcon icon={faHome} className="iconColor" />
                        &nbsp;&nbsp;
                        {data.RoomTypeName.totalRooms} Rooms
                      </Col>
                      <Col md={12}>
                        <FontAwesomeIcon icon={faUsers} className="iconColor" />
                        &nbsp;&nbsp;
                        {data.RoomTypeName.maxGuestCapacity} persons/per room
                      </Col>
                      <Col md={12}>
                        <LinkWrapper
                          className="booknow"
                          href={`${bookRoomUrl(data.RoomcatgId)}`}
                          target="_blank"
                        >
                          Book Now
                        </LinkWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </AccommodationImageWrap>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      {facilities.length > 0 && (
        <SectionWrapper className="margin" bgColor="#f5f5f5">
          <Grid>
            <Row>
              <Col xs={12} md={12}>
                <h2>
                  Room Facilities<span></span>
                </h2>
              </Col>
              <Col xs={12} md={12}>
                <ul className="facilities">
                  {facilities.map((value, index) => (
                    <li>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="checked"
                      />{" "}
                      {decodeURIComponent(value)}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
      )}
    </div>
  );
};

export default RoomTypeComponent;
