import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import constant from "../../Template/Template8Pages/constant";
import { Link } from "react-router-dom";
import media from "../media";

const BreadCrumb = styled.div`
  background: ${(prop) => prop.bgColor || "#fffafa"};

  padding: 10px 0;
  a {
    text-decoration: none;
    color: ${constant.primaryColor} !important;
  }
  span {
    color: ${constant.secondaryColor};
    padding: 0 5px;
  }
`;

const BreadCrumbComponent = ({
  previousUrl,
  previousName,
  currentUrl,
  bgColor,
}) => {
  return (
    <BreadCrumb bgColor={bgColor}>
      <Grid>
        <Row>
          <Col md={12}>
            <Link to="/">Home</Link> <span>>></span>
            {previousUrl && (
              <>
                <Link to={previousUrl}>{previousName}</Link>
                <span> >> </span>
              </>
            )}
            {currentUrl}
          </Col>
        </Row>
      </Grid>
    </BreadCrumb>
  );
};

export default BreadCrumbComponent;
