import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { propertyImageURL } from "../../global/constants";
import {Link } from 'react-router-dom';

library.add(fab);

const FooterLayout1Wrapper = styled.div`
  width: 100%;
  color: #fff;
  padding: 2em 0;
  word-break: break-all;
  background: #121212;
  line-height: 1.8;
  h3 {
    margin-bottom: 20px;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
`;
const FooterLinkWrapper=styled.div`
display:flex;

@media screen and (max-width:820px){
    flex-direction:column;
}
`
const FooterLinkTitle=styled.h3`
    margin-bottom:20px;

    @media screen and (max-width:992px){
    margin-top:20px;
}
`

const FooterLinkItems=styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    text-align:left;
    box-sizing:border-box;
    color:#fff;
    
    @media screen and (max-width:420px){
        margin:0;
        padding:10px;
        width:100%;
}
`
const FooterLink=styled(Link)`
    color:#fff;
    text-decoration:none;
    margin-bottom:0.5rem;
   

    &:hover{
        color:#f08c36;
        transition: all 0.2s ease-ease-in-out;
    }
`
const SocialLinkWrapper=styled.div`
padding-top:12px;
display:flex;
justify-content:flex-start;
align-items:center;
`

const SocialLink = styled.a`
  color: #fff;
  border-radius: 5px;
  padding: 8px 12px;
  margin-right: 20px;
  border: solid 1px #fff;
`;

const FooterLayout1 = ({ contactInfo, socialLinks, menuLinks }) => (
  <FooterLayout1Wrapper>
    <Grid>
      <Row>
        <Col xs={12} lg={4} md={6} >
          <h3>Contact us</h3>
          <p>
            {contactInfo.address.location}
            <br />
            {contactInfo.address.city}, {contactInfo.address.country}
          </p>
          <p>
            Telephone:
            <a href={`tel://${contactInfo.telephone.phone.replace(/\s/g, "")}`}>
              {contactInfo.telephone.phone}
            </a>
          </p>
          <p>
            Email:
            <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
          </p>
          <p>
            Web:
            <a href={contactInfo.website}>{contactInfo.website}</a>
          </p>
          <SocialLinkWrapper>
          {socialLinks.facebook && (
            <SocialLink href={socialLinks.facebook}>
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </SocialLink>
          )}
          {socialLinks.linkedIn && (
            <SocialLink href={socialLinks.linkedIn}>
              <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
            </SocialLink>
          )}
          {socialLinks.tripadvisor && (
            <SocialLink href={socialLinks.tripadvisor}>
              <FontAwesomeIcon icon={["fab", "tripadvisor"]} />
            </SocialLink>
          )}
          </SocialLinkWrapper>
        </Col>
        <Col xs={12} lg={8} md={12}>
        <FooterLinkWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Useful Links</FooterLinkTitle>
            <Row>
              
              {menuLinks.map((item, index) => (
                <Col xs={12} sm={6} md={4} lg={4}>
                  <FooterLink to={item.menuSlugName}
                  exact>
                  {item.menuLinkName}</FooterLink>
                </Col>
              ))}
              
            </Row>
            
          </FooterLinkItems>
      </FooterLinkWrapper>
        </Col>
      </Row>
    </Grid>
  </FooterLayout1Wrapper>
);

export default FooterLayout1;
