import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderImageURL } from "../global/constants";
import constant from "../Template/Template11Pages/constant";
import media from "../global/media";
import { ArrowLeft, ArrowRight } from "../global/svg";

// function createCSS(length) {
//     let styles = ""
//     let n = null
//     for (let i = 0; i < length; i += 1) {
//         styles += `:nth-child(${i}) {
//       animation-delay: ${i * 3}s;
//     }`
//     }
//     return css`
//         ${styles}
//     `
// }
// console.log(createCSS(2))

const SliderWrapper = styled.div`
  position: relative;
  height: 50vh;

  opacity: 1;
  transition: opacity 2000s ease-in-out;
  ${media.sm} {
    height: 90vh;
  }
  .slick-vertical .slick-slide {
    height: 50vh;
    margin: 0;
    position: relative;

    ${media.sm} {
      height: 90vh;
    }
    &.slick-active .main-image {
    }
  }
  .slick-track {
    margin: 0;
  }

  .slick-dots {
    bottom: 0%;
    right: 0%;
    width: fit-content;
    height: fit-content;
    /* transform: translate(-50%,-50%); */
    ${media.sm} {
      top: 54%;
      right: 15%;
    }
    ${media.md} {
      top: 5%;
      right: 0%;
    }
  }
  .slick-dots li button:before {
    height: 8px;
    width: 8px;
    margin: 8px 8px;
    float: left;
    border-radius: 50%;
    border: 1px solid #b1b1b1;
    transition: all 0.2s ease-in-out;
    color: transparent;
    opacity: 1;
  }
  .slick-dots .slick-active button:before {
    height: 16px;
    width: 16px;
    margin: 4px;
    border-radius: 50%;
    border: 1px solid #fff;
  }

  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: ${constant.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
    content: "";
  }

  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
`;
const SlideImage = styled.div`
  background-blend-mode: multiply;
  background: url("${(prop) => prop.bgimage}");
  background-size: cover;
  background-position: center;
  height: 50vh;
  position: relative;
  top: 0;
  left: 0;
  margin: 0;

  ${media.sm} {
    height: 100vh;
  }
  img {
    width: 100%;
  }
`;
const Caption = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0%;
  text-align: left;
  width: 40%;
  transform: translateY(-50%);
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-size: 1.4em;
  padding: 20px 50px;
  ${media.md} {
    left: 5%;
    text-align: left;
    bottom: 10%;
  }
  h2 {
    font-weight: 200;
    font-size: 20px;
    line-height: 30px;
    ${media.md} {
      font-size: 30px;
      line-height: 40px;
    }
  }
`;
const InnerLayer = styled.div`
  background: rgba(15, 22, 42, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HomePageSlider = ({ sliderImages, propertyLogo }) => {
  const [offsetY, setOffsetY] = useState(0);
  const [animationHeight, setAnimationHeight] = useState("100vh");

  const scrollEffect = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    findWidth();
    window.addEventListener("scroll", scrollEffect);
    return () => window.removeEventListener("scroll", scrollEffect);
  }, []);

  const findWidth = () => {
    if (document.body.clientWidth <= 968) {
      setAnimationHeight("50vh");
    } else {
      setAnimationHeight("100vh");
    }
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1500,
    cssEase: "linear",

    fade: true,
    arrow: true,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <SliderWrapper id="homepageslider">
      <Slider {...settings}>
        {sliderImages.sliderImage.map((item, index) => (
          <SlideImage
            className="main-image"
            bgimage={sliderImageURL + item}
            key={index}
          >
            <InnerLayer>
              {sliderImages.sliderLabel[index] ||
              sliderImages.sliderDesc[index] ? (
                <Caption class="caption">
                  {sliderImages.sliderLabel[index] && (
                    <h2>{sliderImages.sliderLabel[index]}</h2>
                  )}
                  {sliderImages.sliderDesc[index] && (
                    <p>{sliderImages.sliderDesc[index]}</p>
                  )}
                </Caption>
              ) : (
                <Caption></Caption>
              )}
            </InnerLayer>
          </SlideImage>
        ))}
      </Slider>
    </SliderWrapper>
    // <SliderSectionWrapper>
    //     <HeroImageWrapper className="hero-image-wrapper">
    //         <ImageSlider className="image-slider">
    //             <Dots className="dots">
    //                 {sliderImages.sliderImage.map((item, index) => (
    //                     <Dot key={index} className="dot"></Dot>
    //                 ))}
    //             </Dots>
    //             <ImageWrap className="image-wrap">
    //                 {sliderImages.sliderImage.map((item, index) => (
    //                     <>
    //                       <ImageDiv
    //                           bgImage={sliderImageURL + item}
    //                           key={index}
    //                           pageOffsetY={offsetY * 0.5}
    //                           className={`img from-top index-${index}`}
    //                           sliderLength={slideLength * 3}
    //                       />
    //                       <InnerLayer>
    //                           {sliderImages.sliderLabel[index] && (
    //                               <Caption>
    //                                   {sliderImages.sliderLabel[index] && (
    //                                     <h2>{ sliderImages.sliderLabel[index]}</h2>
    //                                   )}
    //                                   {sliderImages.sliderDesc[index] && (
    //                                     <p>{ sliderImages.sliderDesc[index]}</p>
    //                                   )}
    //                               </Caption>
    //                           )}
    //                       </InnerLayer>
    //                     </>
    //                 ))}
    //             </ImageWrap>
    //         </ImageSlider>
    //     </HeroImageWrapper>
    //     {/* mouseX={mouseMoveX } mouseY={ mouseMoveY} */}
    //     <HeroLogoWrapper >
    //       <img src={propertyLogo} alt="" />
    //     </HeroLogoWrapper>
    // </SliderSectionWrapper>
  );
};

export default HomePageSlider;
