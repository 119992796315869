import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "../Template8Pages/templateConstants";
import {
  propertyImageURL,
  sliderImageURL,
  noImageUrl,
} from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import constant from "../Template8Pages/constant";
import { decodeContent } from "../../global/constants";
import "slick-carousel/slick/slick-theme.css";
import media from "../../global/media";
import Lightbox from "react-image-lightbox";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

const SubItemPagesComponentWrapper = styled.div`
  padding: 3em 0;
`;

// const SliderWrapper = styled.div`
//   .slick-arrow {
//     z-index: 10;
//     font-size: 2em;
//     width: 50px;
//     height: 50px;
//     background: rgb(58, 49, 56, 0.8);
//     &:before {
//       font-size: 30px;
//     }
//     &:hover {
//       background: ${constant.primaryColor};
//     }
//   }
//   .slick-prev:before,
//   .slick-next:before {
//     font-family: none;
//     color: #fff;
//   }

//   .slick-prev {
//     left: 20px;
//   }
//   .slick-next {
//     right: 20px;
//   }
// `;

// const SlideImage = styled.div`
//   img {
//     width: 100%;
//   }
// `;

const HeaderImage = styled.div`
  height: 200px;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
`;

const NoHeaderImage = styled.div`
  height: 50px;
  ${media.md} {
    height: 100px;
  }
`;

const SubItemPagesComponent = ({ data, templateData, menuFound }) => {
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const openLightbox = (type, key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  const images = data.websitepageImagesSub.MPImagesSub;

  const lightBoxImages = [];
  images.map((value, index) =>
    lightBoxImages.push({
      image: propertyImageURL + value,
      // imgLabel: data.websitepageImagesSub.mPDesc[index],
      type: "property",
    })
  );

  return (
    <SubItemPagesComponentWrapper>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.websitetitle}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.headerImage ? (
        <HeaderImage bgImage={data.headerImage} />
      ) : (
        <NoHeaderImage />
      )}
      <SectionWrapper className="margin">
        <BreadCrumbComponent 
          currentUrl = {data.websitetitle}
          previousUrl = {menuFound.menuSlugName}
          previousName = {menuFound.menuLinkName}
        />
        {console.log(data.websitepagename)}
        {console.log(menuFound.menuLinkName)}
        <Grid>
          <Row>
            <Col xs={12} md={12}>
              <h2 class="left">{data.websitetitle}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeContent(data.websitepagecontent),
                }}
              ></div>
            </Col>
            <Col xs={12} md={12}>
              <Row>
                {images.length > 0 &&
                  images.map((image, index) => {
                    return (
                      <Col xs={12} sm={images.length === 2 ? 6 : 4} key={index}>
                        <img
                          src={image}
                          alt=""
                          onClick={() => openLightbox("property", index)}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Grid>

        {open && (
          <Lightbox
            mainSrc={lightBoxImages[photoIndex].image}
            nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
            prevSrc={
              lightBoxImages[
                (photoIndex + lightBoxImages.length - 1) % lightBoxImages.length
              ]
            }
            onCloseRequest={() => setOpen(false)}
            imageCaption={lightBoxImages[photoIndex].imgLabel}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + lightBoxImages.length - 1) % lightBoxImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightBoxImages.length)
            }
          />
        )}
      </SectionWrapper>
    </SubItemPagesComponentWrapper>
  );
};

export default SubItemPagesComponent;
