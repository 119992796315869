import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import constant from "../Template11Pages/constant";
import media from "../../global/media";

const LinkWrapper = styled.a`
  background: ${(prop) => prop.bgColor || constant.primaryColor};
  outline: 0;
  color: #fff !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  margin: 1rem 0 1rem 0;
  padding: 1em 2em;
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #000;
    color: #fff !important;
  }
`;

const AvailabilityWrapper = styled.div`
  display: inline-block;
  padding: 10px 8px;
  text-decoration: none;
  text-decoration: none;
  color: #0e3360;
  position: relative;
  transition: all 1s ease 0s;
  overflow: hidden;
  line-height: 1;
  background-color: #fff;
`;

const NormalLink = ({ href, text, bgColor }) => (
  <LinkWrapper href={href} target="_blank">
    {text}
  </LinkWrapper>
);

const AvailabilityBtn = ({ to, text }) => (
  <AvailabilityWrapper target="_blank" to={to}>
    {text}
  </AvailabilityWrapper>
);

const NextButton = styled.button`
  padding: 15px;
  background-color: #0a0a0a;
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  -webkit-appearance: none;
  line-height: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  z-index: 5;
  &:hover {
    background: #fff;
    color: #0a0a0a;
    svg {
      filter: invert(1);
    }
  }
  ${media.sm} {
    padding: 20px;
  }
`;

const PrevButton = styled.button`
  padding: 15px;
  background-color: #0a0a0a;
  color: #fff;
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  z-index: 5;
  &:hover {
    background: #fff;
    color: #0a0a0a;
    svg {
      filter: invert(1);
    }
  }
  ${media.sm} {
    padding: 20px;
  }
`;

const SliderButtonWrapper = styled.div`
  margin: 0;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: stretch;
  position: relative;
  width: 100%;
  transform: none;
  top: 0;
  left: 0;
  ${media.sm} {
    width: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
`;
export {
  NormalLink,
  AvailabilityBtn,
  NextButton,
  PrevButton,
  SliderButtonWrapper,
};
