import  React, {useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const LanguageTranslatComponent = () => {

    const [lang, setLang] = useState("English");
    const [flag, setFlag] = useState("flag-icon flag-icon-us");

    const selectLanguage = (e) => {
        console.log('target.value--->',e.target.value);
        setLang(e.target.value.split("_")[0]);
        setFlag(e.target.value.split("_")[1]);
        window["translateLanguage"](e.target.value.split("_")[0]);
    };


    return (
        <>

            <div className="dropdown language-dropdown">
                
                <Link
                    className="btn btn-dark dropdown-toggle notranslate lanButton"
                    to="/"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    value={lang}
                >
                    <img className={flag}></img>
                    {lang}
                </Link>

                <ul
                    className="dropdown-menu cstm-dropdown-menu mt-3 mb-3"
                    aria-labelledby="dropdownMenuLink"
                >
                    <li>

                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="English_flag-icon flag-icon-us">
                            <span className="flag-icon flag-icon-us me-2"></span>
                            English
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="Spanish_flag-icon flag-icon-es"
                        >
                            <span className="flag-icon flag-icon-es me-2"></span>
                            Spanish
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="Portuguese_flag-icon flag-icon-pt"
                        >
                            <span className="flag-icon flag-icon-pt me-2"></span>
                            Portuguese
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="German_flag-icon flag-icon-de"
                        >
                            <span className="flag-icon flag-icon-de me-2"></span>
                            German
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="Chinese_flag-icon flag-icon-cn"
                        >
                            <span className="flag-icon flag-icon-cn me-2"></span>
                            Chinese
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="Japanese_flag-icon flag-icon-jp"
                        >
                            <span className="flag-icon flag-icon-jp me-2"></span>
                            Japanese
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="Korean_flag-icon flag-icon-kr"
                        >
                            <span className="flag-icon flag-icon-kr me-2"></span>
                            Korean
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="Hindi_flag-icon flag-icon-in"
                        >
                            <span className="flag-icon flag-icon-in me-2"></span>
                            Hindi
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="dropdown-item notranslate"
                            onClick={(e) => selectLanguage(e)}
                            value="Arabic_flag-icon flag-icon-sa"
                        >
                            <span className="flag-icon flag-icon-sa me-2"></span>
                            Arabic
                        </Button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default LanguageTranslatComponent;
