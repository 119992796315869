import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { propertyImageURL } from "../../global/constants";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import media from "../../global/media";
import { bookNowUrl } from "../../global/constants";
import constant from '../../Template/Template6Pages/constant';

const HeaderLayout1Wrapper = styled.div`
  width: 100%;
  color: #fff;
  padding: 15px 0;
  background: #fff;
  .logo-header{
    display: flex;
    justify-content:center;
    align-items:center;
    /* place-items:center; */
  }
  .logo-header-link{
    display:flex;
    justify-content:center;
  }
`;
const NavBarWrapper = styled.div`
  font-size: 1.4em;

  cursor: pointer;
  display: inline-block;
  transition: all 0.5ms ease-in;
  .icon {
    color: #000;
  }
  ${media.md} {
    display: none;
  }
`;
const NavbarLinkWrapper=styled.div`
display:flex;
width:100%;
justify-content:flex-end;`


const NavBarLinkBook = styled.a`
  display: inline-block;
  ${media.md} {
    display: none;
  }
  margin-right: 20px;
  padding: 15px 20px;
  background: ${constant.primaryColor};
  color: ${constant.primaryColor};;
  position: relative;
  text-align: center;
  text-decoration: none;
  margin-left: 3%;
`;

const HeaderLayout1 = ({ propertyDetails, onNavClick }) => (
  <HeaderLayout1Wrapper >
    <Grid>
      <Row middle="xs" className="logo-header">
        <Col xs={12} md={12}>
          <Link to="/" className="logo-header-link">
            <img className="logo"
              src={propertyDetails.propertyLogo}
              alt={propertyDetails.propertyName}
            />
          </Link>
        </Col>
        <Col xs={12} md={12}>
          <Row >
            <Col xs={12}>
              <NavbarLinkWrapper>
                <NavBarLinkBook
                  href={`${bookNowUrl}${propertyDetails.propertyID}`}
                >
                  Book Now
                </NavBarLinkBook>
                <NavBarWrapper onClick={() => onNavClick(false)}>
                  <FontAwesomeIcon icon={faBars} className="icon" />
                </NavBarWrapper>
              </NavbarLinkWrapper>
              
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  </HeaderLayout1Wrapper>
);

export default HeaderLayout1;
