import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapComponent from "../../global/MapComponent";
import media from "../../global/media";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
// import constant from "./constant";

const ContactWrap = styled.div`
  h2{
    font-weight: normal;
  }
  ${media.md}{
    display: flex;
  }
`;

const ContactDeatils = styled.div`
  width: 100%;
  background: #eee;
  padding: 40px;
  h3{
    font-size: 24px;
    font-weight: normal;
    .icon-settings{
      font-size: 40px;
    }
  }
  .contactMargin{
    margin: 10px 0px;
  }
  ${media.md}{
    width: 40%;
    padding: 20px 30px;
    line-height: 2.5;
    h3{
      font-size: 24px;
      .icon-settings{
        font-size: 40px;
      }
    }
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  position: relative;
  ${media.md}{
    width: 60%;
  }
`;

const ContactUsComponent = ({ templateData }) => {
  const contact = templateData.propertyDetails.contactInfo;
  const latitude = templateData.propertyDetails.propertyLatitude;
  const longitude = templateData.propertyDetails.propertyLongitude;
  const googleMap = templateData.propertyDetails.googleMapApi;
  const googleMapIcon = templateData.propertyDetails.googleMapIcon;
  const address = templateData.propertyDetails.contactInfo.address;
  return (
    <SectionWrapper className="margin">
      <ContactWrap>
        <ContactDeatils>
          <Grid>
            <Row>
              <Col xs={12} md={12}>
                <h2>Get In Touch</h2>
              </Col>
              <Col xs={12} md={12} className="contactMargin">
                <h3><FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="icon-settings"
                /> Address</h3>
                <p>
                  {contact.address.location} {contact.city} {contact.address1}{" "}
                  {contact.address.country}
                </p>
              </Col>
              <Col xs={12} md={12} className="contactMargin">
                <h3><FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="icon-settings"
                /> Telephone</h3>
                <p>Fax: {contact.telephone.fax}</p>
                <p>Phone: {contact.telephone.phone}</p>
                <p>FreePhone: {contact.freephone}</p>
              </Col>
              <Col xs={12} md={12} className="contactMargin">
                <h3><FontAwesomeIcon
                  icon={faEnvelope}
                  className="icon-settings"
                /> Email Address</h3>
                <p>{contact.email}</p>
              </Col>
            </Row>
          </Grid>
        </ContactDeatils>
        <MapWrapper>
          <MapComponent
            latitude={latitude}
            longitude={longitude}
            googleMap={googleMap}
            address={address}
            iconImage={googleMapIcon}
            mapHeight="100vh"
          />
          </MapWrapper>
      </ContactWrap>
    </SectionWrapper>
  );
};

export default ContactUsComponent;