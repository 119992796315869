import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "styled-components";
import { propertyImageURL } from "../../global/constants";
import { NormalLink } from "./Links";
import BookingWidgetComponent from "../../global/BookingWidgetComponent";
import media from "../../global/media";
import constant from "./constant";

const HomePageWelcomeComponentWrapper = styled.div`
  padding: 4em 0;
  h2 {
    margin-bottom: 40px;
    position: relative;
    font-size: 2em;
  }
  p {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.3px;
    padding-right: 10%;
    color: #666;
    word-break: break-all;
    &::first-letter {
      color: #333;
      font-family: "Merriweather", serif;
      font-size: 80px;
      font-weight: bold;
      float: left;
      padding-right: 20px;
      padding-bottom: 1px;
    }
  }
  img {
    width: 100%;
  }
`;

const BookingEngingeWidgetComponentWrapper = styled.div`
  margin-top: 50px;
  z-index: 1;
  position: relative;
  ${media.md} {
    margin-top: -100px;
  }
  .bookingWidget {
    background: #fff;
    padding: 30px;
    box-shadow: 0 3px 5px 0 rgba(153, 153, 153, 0.5);
    display: flex;
    font-size: 14px;
    font-weight: bold;
    flex-wrap: wrap;
    //justify-content: ;
    align-items: center;
    .heading {
      font-size: 25px;
      display: none;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }
    .checkInWrapper,
    .checkOutWrapper {
      width: 100%;
      margin-right: 1%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 20%;
      }
      .react-datepicker-wrapper {
        width: 100%;
        margin-top: 10px;
        .datepicker-input {
          border-radius: 0;
          padding: 10px;
          font-size: 12px;
          border: solid 1px #ccc;
        }
      }
    }
    .roomsWrapper {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 1%;
      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 20%;
      }

      .roomsWrapperLabel {
        width: 100%;
        padding: 10px;
        border: solid 1px #ccc;
        font-size: 12px;
        font-weight: normal;
      }
    }
    .flexibleWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 18%;
      }
      margin-right: 1%;

      input {
        margin-top: 8px;
        padding: 10px;
        border: solid 1px #ccc;
        font-size: 12px;
        width: 100%;
      }
    }
    .buttonWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.md} {
        width: 18%;
      }
      button {
        padding: 15px;
        width: 100%;
        border: 0;
        background: ${constant.primaryColor};
        color: #fff;
      }
    }
  }
`;

const contentLimit = 400;

const HomePageWelcomeComponent = ({
  propertyName,
  content,
  image,
  maxGuestCapacity,
  propertyId,
}) => (
  <div>
    <BookingEngingeWidgetComponentWrapper>
      <BookingWidgetComponent
        maxGuestCapacity={maxGuestCapacity}
        propertyId={propertyId}
      />
    </BookingEngingeWidgetComponentWrapper>

    <HomePageWelcomeComponentWrapper>
      <Grid>
        <Row>
          <Col xs={12} md={6}>
            <h2>Welcome to {propertyName}</h2>
            <p>{content.substring(0, contentLimit) + "..."}</p>
            <br />
            <NormalLink to="/about-us/" text="Explore more" />
            <br />
          </Col>
          <Col xs={12} md={6}>
            <img src={propertyImageURL + image} alt={propertyName} />
          </Col>
        </Row>
      </Grid>
    </HomePageWelcomeComponentWrapper>
  </div>
);

export default HomePageWelcomeComponent;
