import React from "react";
import styled from "styled-components";

import {  Row, Col } from "react-flexbox-grid";
import {  sliderImageURL } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import HomePageSlider2 from "../../HomePageSlider/HomePageSlider2";
import HomePageComponent from "./HomePageComponent";
import CommonComponent from "./CommonComponent";
import AccommodationComponent from "./AccommodationComponent";
import GalleryComponent from "./GalleryComponent";
import ContactUsComponent from "./ContactUsComponent";
import ReviewsComponent from "./ReviewsComponent";


const HeaderImage = styled.div`
  height: 200px;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
`;

const SubPagesBlock = styled.div`
  height: 400px;
  margin-bottom: 20px;
  position: relative;
    background-image: url('${(prop) => prop.bgImage}');
    background-size: cover;
    width: 100%;
    
    .content-desc {
      transition: visibility 0.3s linear,opacity 0.3s linear;
      background: rgba(0,0,0, 0.8);
      color: #fff;
      width: 100%;
      height: 100%;
      padding: 20px;
      z-index:1;
      opacity: 0;
      visibility: hidden;
    }
    .content-title {
      position: absolute;
      bottom: 0;
      background: rgba(255,255,255,0.6);
      width: 100%;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      z-index: 2;
    }
    &:hover {
      .content-desc {
        visibility: visible;
        opacity: 1;
      }
    }
`;

const SubPagesBlockLink = styled.a`
  height: 400px;
  margin: 50px 0px;
  display: block;
  position: relative;
    background-image: url('${(prop) => prop.bgImage}');
    background-size: cover;
    width: 100%;
    .content-title {
      position: absolute;
      color: #000;
      bottom: 0;
      background: rgba(255,255,255,0.6);
      width: 100%;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      z-index: 2;
    }
`;

const CommonWrapper = styled.div`
    h2{
      line-height: 1.25;
      font-size: 2.25rem;
      font-weight: 500;
    }
    ul li, p{
      line-height: 1.7em;
      font-size: 0.9rem;
    }
`;

const PagesComponent = ({ data, templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const facilities = templateData.propertyDetails.facilities.facility;
  const propertyImage = templateData.propertyImages.image;
  const noImageUrl = "/images/multipages/noimage.png";

  const maxGuestCapacity = () => {
    let maxGuestCapacity = 0;
    roomTypes.map((item) => {
      if (maxGuestCapacity < item.RoomTypeName.maxGuestCapacity) {
        maxGuestCapacity = item.RoomTypeName.maxGuestCapacity;
      }
    });

    return maxGuestCapacity;
  };

  return (
    <div>
      {data.sliderImages && (
        <HomePageSlider2 sliderImages={data.sliderImages} />
      )}
      {data.headerImage && (
        <HeaderImage bgImage={sliderImageURL + data.headerImage} />
      )}
      {data.menuSlugName === "/" ? (
        <HomePageComponent
          templateData={templateData}
          maxGuestCapacity={maxGuestCapacity()}
        />
      ) : data.menuSlugName === "/about-us/" ? (
        <CommonComponent>
          <h2>About us</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: decodeURIComponent(
                templateData.propertyDetails.propertyDescription
              ),
            }}
          ></div>
        </CommonComponent>
      ) : data.menuSlugName === "/facilities/" ? (
        <CommonComponent>
          <h2>Facilities</h2>
          <ul className="facilities">
            {facilities.map((item, index) => (
              <li>
                <FontAwesomeIcon icon={faChevronRight} />
                {item}
              </li>
            ))}
          </ul>
        </CommonComponent>
      ) : data.menuSlugName === "/accommodation/" ? (
        <AccommodationComponent templateData={templateData} />
      ) : data.menuSlugName === "/gallery/" ? (
        <GalleryComponent propertyImage={propertyImage} roomTypes={roomTypes} />
      ) : data.menuSlugName === "/contact-us/" ? (
        <ContactUsComponent templateData={templateData} />
      ) : data.menuSlugName === "/review/" ? (
        <ReviewsComponent templateData={templateData} />
      ) : data.MenuLinkAdditonalPageID > 0 ? (
        <CommonComponent>
          {templateData.multiPagesMain.mainPage.map((item, index) => {
            return (
              data.MenuLinkAdditonalPageID === item.websitepageid && (
                <CommonWrapper>
                  <Row>
                    <Col xs={12} md={item.websitepageImages ? 6 : 12}>
                      <h2 className="left">{item.websitetitle}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.websitepagecontent,
                        }}
                      ></div>
                    </Col>
                    {item.websitepageImages && (
                      <Col xs={12} md={6}>
                        {typeof item.websitepageImages.mPImages === "string" ? (
                          item.websitepageImages.mPImages && (
                            <SubPagesBlock
                              bgImage={
                                sliderImageURL + item.websitepageImages.mPImages
                              }
                            ></SubPagesBlock>
                          )
                        ) : (
                          <SubPagesBlock
                            bgImage={item.websitepageImages.mPImages[0]}
                          ></SubPagesBlock>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {item.multiPagesSub &&
                      item.multiPagesSub.subPage &&
                      item.multiPagesSub.subPage.map((item1, index) => {
                        return item.externalPages === "0" ? (
                          <Col xs={12} md={6}>
                            <SubPagesBlock
                              bgImage={`${
                                typeof item1.websitepageImagesSub
                                  .MPImagesSub === "string"
                                  ? item1.websitepageImagesSub.MPImagesSub
                                    ? item1.websitepageImagesSub.MPImagesSub
                                    : noImageUrl
                                  : item1.websitepageImagesSub.MPImagesSub[0]
                              }`}
                            >
                              <div className="content-title">
                                {item1.websitetitle}
                              </div>
                              <div
                                className="content-desc"
                                dangerouslySetInnerHTML={{
                                  __html: item1.websitepagecontent,
                                }}
                              ></div>
                            </SubPagesBlock>
                          </Col>
                        ) : (
                          <Col xs={12} md={6}>
                            <SubPagesBlockLink
                              href={item1.websiteSlugName}
                              bgImage={`${
                                typeof item1.websitepageImagesSub
                                  .MPImagesSub === "string"
                                  ? item1.websitepageImagesSub.MPImagesSub
                                    ? item1.websitepageImagesSub.MPImagesSub
                                    : noImageUrl
                                  : item1.websitepageImagesSub.MPImagesSub[0]
                              }`}
                            >
                              <div className="content-title">
                                {item1.websitetitle}
                              </div>
                            </SubPagesBlockLink>
                          </Col>
                        );
                      })}
                  </Row>
                </CommonWrapper>
              )
            );
          })}
        </CommonComponent>
      ) : (
        <CommonComponent>
          <h2>{data.MenuLinkpageName}</h2>
        </CommonComponent>
      )}
    </div>
  );
};

export default PagesComponent;
