import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderImageURL } from "../global/constants";
import constant from "../Template/Template8Pages/constant";
import media from "../global/media";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const SliderWrapper = styled.div`
  position: relative;
  height: 50vh;
  /* background-color: rgba(98, 98, 98, 0.4); */
  opacity: 1;
  transition: opacity 2000s ease-in-out;
  overflow: hidden;
  ${media.sm} {
    height: 100vh;
  }

  .slick-dots {
    bottom: 10px;
  }

  .slick-dots li button:before {
    font-size: 8px;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    font-size: 10px;
    color: #eee;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }
  .slick-arrow {
    z-index: 10;
    opacity: 0.5;
    transition: 0.5s all ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  .slick-prev {
    left: 5%;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      background: #000;
      border-radius: 50%;
      box-shadow: 0 0 10px #000;
      font-size: 38px !important;
      opacity: 1;
    }
  }
  .slick-next {
    right: 5%;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      background: #000;
      border-radius: 50%;
      box-shadow: 0 0 10px #000;
      font-size: 38px !important;
      opacity: 1;
    }
  }
`;
const SlideImage = styled.div`
  background-blend-mode: multiply;
  background: url("${(prop) => prop.bgimage}");
  background-size: cover;
  background-position: center;
  height: 50vh;
  position: relative;
  ${media.sm} {
    height: 100vh;
  }
  img {
    width: 100%;
  }
`;
const Caption = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1.4em;
  padding: 20px 50px;
  h2 {
    font-weight: 200;
    font-size: 30px;
    margin-bottom: 50px;
    ${media.md} {
      font-size: 40px;
    }
  }
`;

const InnerLayer = styled.div`
  background: rgba(15, 22, 42, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HomePageSlider = ({ menuLink, sliderImages }) => {
  // console.log(menuLink);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    fade: false,
    speed: 400,
    cssEase: "linear",
    arrow: true,
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {sliderImages.sliderImage.map((item, index) => (
          <SlideImage bgimage={sliderImageURL + item} key={index}>
            <InnerLayer>
              {sliderImages.sliderLabel[index] ||
              sliderImages.sliderDesc[index] ? (
                <Caption class="caption">
                  {sliderImages.sliderLabel[index] && (
                    <h2>{sliderImages.sliderLabel[index]}</h2>
                  )}
                  {sliderImages.sliderDesc[index] && (
                    <p>{sliderImages.sliderDesc[index]}</p>
                  )}
                </Caption>
              ) : (
                <Caption></Caption>
              )}
            </InnerLayer>
          </SlideImage>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default HomePageSlider;
