import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import media from "../../global/media";
import { propertyImageURL } from "../../global/constants";
import { bookNowUrl } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import constant from "../../Template/Template2Pages/constant";

const HeaderLayout2Wrapper = styled.div`
  width: 100%;
  color: #fff;
  padding: 2em 0;
`;

const NavBarLinkBook = styled.a`
  position: relative;
  background: ${constant.primaryColor};
  padding: 10px 60px;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 20px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  ${media.md} {
    position: absolute;
    width: auto;
    right: 10px;
    top: 25%;
  }
`;
const NavBarWrapper = styled.div`
  font-size: 1.4em;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  transition: all 0.5ms ease-in;
  color: #000;
  .icon {
    color: #000;
    float: right;
  }
  ${media.md} {
    position: absolute;
    width: auto;
    top: 25%;
    left: 10px;
    .icon {
      float: none;
    }
  }
  ${media.md} {
    display: none;
  }
`;

const HeaderLayout2 = ({ propertyDetails, onNavClick }) => (
  <HeaderLayout2Wrapper>
    <Grid>
      <Row center="xs">
        <Col xs={12} md={12}>
          <Link to="/">
            <img
              src={propertyImageURL + propertyDetails.propertyLogo}
              alt={propertyDetails.propertyName}
            />
          </Link>
        </Col>
      </Row>
    </Grid>
    <NavBarLinkBook href={`${bookNowUrl}${propertyDetails.propertyId}`}>
      Book Now
    </NavBarLinkBook>
    <NavBarWrapper onClick={() => onNavClick(false)}>
      Menu <FontAwesomeIcon icon={faBars} className="icon" />
    </NavBarWrapper>
  </HeaderLayout2Wrapper>
);

export default HeaderLayout2;
