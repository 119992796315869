const constant = {
  primaryColor: "#282f42",
  secondaryColor: "#000",
  bgBlack: "rgba(255,255,255, 0.2)",
  borderColor: "#333",
  blackColor: "#000",
  footerColor: "#496184",
  goldColor: "#FFD700",
  headerColor: "#fafafa",
  bodyColor: "#292929",
  whiteColor: "#fff",
  buttonColor: "#000",
  greyColor: "#7e7e7e",
};
export default constant;
