import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "../Template5Pages/templateConstants";

const CommonComponent = ({ children }) => (
  <SectionWrapper className="margin">
    <Grid>
      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </Grid>
  </SectionWrapper>
);
export default CommonComponent;
