import React from "react";

import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import RoomDetailComponent from "./RoomDetailComponent";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const accommodationSlugName = templateData.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  return (
    <SectionWrapper className="margin" bgColor="#f5f5f5">
      <BreadCrumbComponent 
        currentUrl = {"Accommodation"}
        bgColor = "#f5f5f5"
      />
      <Grid>
        <Row>
          <Col xs={12}>
            <h2>
              Accommodation<span></span>
            </h2>
          </Col>
        </Row>
        <Row className="room-types">
          {roomTypes.map((value, index) => {
            return (
              <Col lg={4} md={6} xs={12} className="cardCol" key={index}>
                <RoomDetailComponent
                  value={value}
                  mainSlugName={`${process.env.PUBLIC_URL}${accommodationSlugName[0]["menuSlugName"]}`}
                />
              </Col>
            );
          })}
        </Row>
      </Grid>
    </SectionWrapper>
  );
};

export default AccommodationComponent;
