import React, { useContext } from "react";
import styled from "styled-components";
// import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import media from "../../global/media";
import { propertyImageURL } from "../../global/constants";
import { WidgetOpen } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import constant from "../../Template/Template7Pages/constant";
import data from "./../../../hotelData.json";
import { Button,Navbar,Nav,NavDropdown,Form,FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles.css";

// const Nav = styled.nav`
//   background: #252323;
//   height: 85px;
//   display: flex;
//   justify-content: space-between;
//   padding: 0.2rem calc((100vw - 1000px) / 2);
//   z-index: 12;
//   /* Third Nav */
//   /* justify-content: flex-start; */
// `;

// const NavLink = styled(Link)`
//  font-size: large;
//   color: #808080;
//   display: flex;
//   align-items: center;
//   text-decoration: none;
//   padding: 0 1rem;
//   height: 100%;
//   cursor: pointer;
//   &.active {
//     color: #000000;
//   }
// `;

//  const Bars = styled(FaBars)`
//   display: none;
//   color: #808080;
//   @media screen and (max-width: 768px) {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   }
// `;

// const NavMenu = styled.div`
//   display: flex;
//   align-items: center;
//   margin-right: -24px;
//   /* Second Nav */
//   /* margin-right: 24px; */
//   /* Third Nav */
//   /* width: 100vw;
//   white-space: nowrap; */
//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;

// const NavBtn = styled.nav`
//   display: flex;
//   align-items: center;
//   margin-right: 24px;
//   /* Third Nav */
//   /* justify-content: flex-end;
//   width: 100vw; */
//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;

// const NavBtnLink = styled(Link)`
//   border-radius: 4px;
//   background: #808080;
//   padding: 10px 22px;
//   color: #000000;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;
//   /* Second Nav */
//   margin-left: 24px;
//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #808080;
//   }
// `;

//old
const HeaderLayout13Wrapper = styled.div`
  width: 100%;
  color: #eee;
  display: flex;
  justify-content: space-between;
  background: transparent;
  position: fixed;
  transition: 0.5s all ease-in-out;
  padding: 25px 15px;
  align-items: start;
  .inside-wrapper {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .logo {
    position: absolute;
    width: ${(prop) => (prop.width ? prop.width / 2 + "px" : "auto")};
    height: ${(prop) => (prop.height ? prop.height / 2 + "px" : "auto")};
    ${media.sm} {
      width: ${(prop) => (prop.width ? prop.width + "px" : "auto")};
      height: ${(prop) => (prop.height ? prop.height + "px" : "auto")};
    }
  }

  &.sticky {
    background: #ffffff;
  }
  &.shiftHeader {
    width: calc(100% - 250px);
  }
`;

const NavbarLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70%;
  align-items: center;
`;

const NavBarLinkBook = styled.button`
  position: relative;
  background: transparent;
  margin: 0 10px;
  padding: 11px 25px;
  display: block;
  text-align: center;
  border: solid 1px #000;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  background: #000;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: #fff;
    border-color: #fff;
    color: #919191;
  }
`;
const NavBarWrapper = styled.div`
line-height: 1.5;
  font-size: 1.1em;
  background: #000;
  padding: 12px;
  cursor: pointer;
  display: inline-block;
  transition: all 0.5ms ease-in;
  color: #fff;
  display: flex;
  align-items: center;
  ${media.sm} {
    padding: 12px;
  }
  .icon {
    color: ${constant.secondaryColor};
    font-weight: normal;

    &.faBars {
      display: ${(prop) =>
    prop.navigationToggle === "none" ? "block" : "none"};
    }
    &.faTimes {
      display: ${(prop) =>
    prop.navigationToggle === "block" ? "block" : "none"};
    }
  }
`;

const HeaderLayout13 = ({
  propertyDetails,
  colorConfiguration,
  onNavClick,
  navigationToggle,
}) => {
  return (
    <>

    <Navbar bg="dark" variant="dark" expand="lg" className="d-flex">
  <Navbar.Toggle aria-controls="navbarScroll" />
  <Navbar.Collapse id="navbarScroll">
    <Nav
      className="mr-auto my-2 my-lg-0"
      style={{ maxHeight: '100px' }}
      navbarScroll
      id="headerWrapper"
        headerColor={colorConfiguration.headercolor}
        width={propertyDetails.propertyLogoWidth || ""}
        height={propertyDetails.propertyLogoHeight || ""}
    >
      <Nav.Link to="/" onClick={() => window.scrollTo(100, 100)}></Nav.Link>
      <img
          height="50px"
          className="logo"
          src={propertyImageURL + propertyDetails.propertyLogo}
          alt={propertyDetails.propertyName}
        />
        {
          data.menuLinks.menuLink.slice(0, 4).map((item, index) => (
            <>
            <Nav.Link href={item.menuSlugName}>{item.MenuLinkpageName}</Nav.Link>
              {/* <NavLink to={item.menuSlugName} activeStyle>
                {item.MenuLinkpageName}
              </NavLink> */}
            </>

          )
          )
        }
    
      <NavDropdown title="More" id="navbarScrollingDropdown">
      {
        data.menuLinks.menuLink.slice(4, 10).map((item, index) => (
          <>
          <NavDropdown.Item href={item.menuSlugName}>{item.MenuLinkpageName}</NavDropdown.Item>
            {/* <li class="nav-item">
              <a class="menu-item" href={item.menuSlugName}>{item.MenuLinkpageName}</a>
            </li> */}
          </>
        )
        )
      }
      </NavDropdown>
    </Nav>
    <Form className="d-flex">
      <Button variant="outline-success" to='/' id="active-link" onClick={() => WidgetOpen(true)}>Book Now</Button>
    </Form>
  </Navbar.Collapse>
</Navbar>
    

    {/* <Nav>
      <NavMenu
        id="headerWrapper"
        headerColor={colorConfiguration.headercolor}
        width={propertyDetails.propertyLogoWidth || ""}
        height={propertyDetails.propertyLogoHeight || ""}
      >
        <NavLink to="/" onClick={() => window.scrollTo(100, 100)} />

        <img
          className="logo"
          src={propertyImageURL + propertyDetails.propertyLogo}
          alt={propertyDetails.propertyName}
        />
        {
          data.menuLinks.menuLink.slice(0, 4).map((item, index) => (
            <>
              <NavLink to={item.menuSlugName} activeStyle>
                {item.MenuLinkpageName}
              </NavLink>
            </>

          )
          )
        }
        {/* <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} /> */}

      {/* </NavMenu>
      <NavBtn>
        <NavBtnLink to='/' id="active-link" onClick={() => WidgetOpen(true)}>Book Now</NavBtnLink>
      </NavBtn>
    </Nav>  */}
    </>
    // <HeaderLayout13Wrapper
    //   id="headerWrapper"
    //   headerColor={colorConfiguration.headercolor}
    //   width={propertyDetails.propertyLogoWidth || ""}
    //   height={propertyDetails.propertyLogoHeight || ""}
    // >
    //   <Link to="/" onClick={() => window.scrollTo(100, 100)}>
    //     <img
    //       className="logo"
    //       src={propertyImageURL + propertyDetails.propertyLogo}
    //       alt={propertyDetails.propertyName}
    //     />
    //   </Link>
    //   <div className="inside-wrapper">
    //     <NavbarLinks>
    //       <NavBarLinkBook id="active-link" onClick={() => WidgetOpen(true)}>
    //         Book Now
    //       </NavBarLinkBook>
    //       <NavBarWrapper
    //         onClick={() => onNavClick(false, false)}
    //         navigationToggle={navigationToggle}
    //       >
    //         <FontAwesomeIcon icon={faBars} className="icon faBars" />
    //         <FontAwesomeIcon icon={faTimes} className="icon faTimes" />
    //       </NavBarWrapper>
    //     </NavbarLinks>
    //   </div>
    // </HeaderLayout13Wrapper>
    
  );
};

export default HeaderLayout13;
