import React from "react";
import styled from "styled-components";
import HeaderLayout1 from "./layouts/HeaderLayout1";
import HeaderLayout2 from "./layouts/HeaderLayout2";
import HeaderLayout3 from "./layouts/HeaderLayout3";
import HeaderLayout4 from "./layouts/HeaderLayout4";
import HeaderLayout5 from "./layouts/HeaderLayout5";
import HeaderLayout6 from "./layouts/HeaderLayout6";
import HeaderLayout7 from "./layouts/HeaderLayout7";
import HeaderLayout8 from "./layouts/HeaderLayout8";
import HeaderLayout9 from "./layouts/HeaderLayout9";
import HeaderLayout10 from "./layouts/HeaderLayout10";
import HeaderLayout11 from "./layouts/HeaderLayout11";
import HeaderLayout12 from "./layouts/HeaderLayout12";
import HeaderLayout13 from "./layouts/HeaderLayout13";

const HeaderWrapper = styled.div``;

const Header = ({
  templateId,
  propertyDetails,
  colorConfiguration,
  onNavClick,
  navigationToggle,
  bookingEngine,
  whiteLabelURL,
}) => {
  return (
    <HeaderWrapper>
      {templateId === "1" && (
        <HeaderLayout1
          propertyDetails={propertyDetails}
          onNavClick={onNavClick}
        />
      )}
      {templateId === "2" && (
        <HeaderLayout2
          propertyDetails={propertyDetails}
          onNavClick={onNavClick}
        />
      )}
      {templateId === "3" && (
        <HeaderLayout3
          propertyDetails={propertyDetails}
          onNavClick={onNavClick}
        />
      )}
      {templateId === "4" && (
        <HeaderLayout4
          propertyDetails={propertyDetails}
          onNavClick={onNavClick}
        />
      )}
      {templateId === "5" && (
        <HeaderLayout5
          propertyDetails={propertyDetails}
          onNavClick={onNavClick}
        />
      )}
      {templateId === "6" && (
        <HeaderLayout6
          propertyDetails={propertyDetails}
          onNavClick={onNavClick}
        />
      )}
      {templateId === "7" && (
        <HeaderLayout7
          propertyDetails={propertyDetails}
          colorConfiguration={colorConfiguration}
          onNavClick={onNavClick}
          navigationToggle={navigationToggle}
          bookingEngine={bookingEngine}
          whiteLabelURL={whiteLabelURL}
        />
      )}
      {templateId === "8" && (
        <HeaderLayout8
          propertyDetails={propertyDetails}
          colorConfiguration={colorConfiguration}
          onNavClick={onNavClick}
          navigationToggle={navigationToggle}
          bookingEngine={bookingEngine}
          whiteLabelURL={whiteLabelURL}
        />
      )}
      {templateId === "9" && (
        <HeaderLayout9
          propertyDetails={propertyDetails}
          colorConfiguration={colorConfiguration}
          onNavClick={onNavClick}
          navigationToggle={navigationToggle}
          bookingEngine={bookingEngine}
          whiteLabelURL={whiteLabelURL}
        />
      )}
      {templateId === "10" && (
        <HeaderLayout10
          propertyDetails={propertyDetails}
          colorConfiguration={colorConfiguration}
          onNavClick={onNavClick}
          navigationToggle={navigationToggle}
          bookingEngine={bookingEngine}
          whiteLabelURL={whiteLabelURL}
        />
      )}
      {templateId === "11" && (
        <HeaderLayout11
          propertyDetails={propertyDetails}
          colorConfiguration={colorConfiguration}
          onNavClick={onNavClick}
          navigationToggle={navigationToggle}
          bookingEngine={bookingEngine}
          whiteLabelURL={whiteLabelURL}
        />
      )}
      {templateId === "12" && (
        <HeaderLayout12
        propertyDetails={propertyDetails}
        colorConfiguration={colorConfiguration}
        onNavClick={onNavClick}
        navigationToggle={navigationToggle}
        bookingEngine={bookingEngine}
        whiteLabelURL={whiteLabelURL}
        />
      )}
      {templateId === "13" && (
        <HeaderLayout13
        propertyDetails={propertyDetails}
        colorConfiguration={colorConfiguration}
        onNavClick={onNavClick}
        navigationToggle={navigationToggle}
        bookingEngine={bookingEngine}
        whiteLabelURL={whiteLabelURL}
        />
      )}
    </HeaderWrapper>
  );
};

export default Header;
