import React from "react";
import styled from "styled-components"

const LoadingScreen = styled.div`
  position: absolute;
  width: 100%;
  height: ${prop => (prop.size ? prop.size : "40vh")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 1111;
  top: 0;
  right: 0;
  opacity: 1;
  .ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:75px;
  height:75px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:75px;
  font-family:sans-serif;
  font-size:10px;
  color:#32af91;
  letter-spacing:3px;
  text-transform:uppercase;
  text-shadow:0 0 10px #32af91;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #32af91;
  border-right:3px solid #32af91;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
span:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#32af91;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #32af91;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}
`


const LoadingSpinner = ({ size, text }) => {
  return (
    <LoadingScreen size={size}>
      <div class="ring">
        {text}
        <span></span>
      </div>
    </LoadingScreen>
  )
}

export default LoadingSpinner
