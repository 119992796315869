import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";

import { SectionWrapper } from "../Template9Pages/templateConstants";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
} from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import { ArrowLeft, ArrowRight } from "../../global/svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { NormalLink } from "../Template11Pages/Links";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import media from "../../global/media";
import constant from "../Template11Pages/constant";
import { Link } from "react-router-dom";
import { bookRoomUrl } from "../../global/constants";
import InsidePageHeaderComponent from "./InsidePageHeaderComponent";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

const RoomTypeWrapper = styled.div`
  position: relative;

  .breadCrumb-wrapper {
    position: absolute;
    width: 100%;
    z-index: 98;
    top: 85px;
    display: none;
    ${media.sm} {
      display: block;
    }
    ${media.sm} {
      top: 190px;
    }
  }
`;
const AccommodationImageWrap = styled.div`
  width: 100%;
  overflow: hidden;
  height: 30vh;
  position: relative;
  ${media.md} {
    height: 50vh;
  }
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  h2 {
    font-size: 1.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 1px 1px 2px #000;
    color: #fff;
    ${media.md} {
      font-size: 3em;
    }
  }
`;

const SliderWrapper = styled.div`
  height: 400px;
  overflow: hidden;
  .slick-slider {
    height: 100%;
  }
  .slick-slide {
    margin: 0 0 0 5px;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: ${constant.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
    content: "";
  }

  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
`;

const SlideImage = styled.div`
  background-blend-mode: multiply;
  background: url(${(prop) => prop.bgimage});
  background-size: cover;
  background-position: center;
  height: 50vh;

  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  transform: translateY(${(prop) => prop.pageOffsetY}px);
`;

const LinkWrapper = styled.a`
  background: ${(prop) =>
    prop.bgColor
      ? prop.bgColor
      : constant.primaryColor
      ? constant.primaryColor
      : "#fff"};
  outline: 0;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  margin: 1rem 0 1rem 0;
  padding: 1em 2em;
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  :hover {
    background: #fff;
    color: #000;
  }
`;
const ContentWrapper = styled.div`
  background: #fff;
  padding: 70px 0px;
  z-index: 10;
  position: relative;
  .roomTypeContent {
    line-height: 2;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        position: relative;
        padding-left: 20px;
        width: 100%;
        ${media.sm} {
          width: 50%;
        }
        ${media.md} {
          width: 33%;
        }
        svg {
          position: absolute;
          left: 0;
          top: 5px;
        }
      }
    }
    h3 {
      padding: 20px 0 0;
    }
  }
`;
const BreadCrumbComponentWrapper = styled.div`
  position: relative;
  width: 100%;
  background: #fffafa;
`;

const RoomTypeComponent = ({ data, templateData }) => {
  const facilities = data.RoomTypeFacilities
    ? data.RoomTypeFacilities.RooomFacility &&
      data.RoomTypeFacilities.RooomFacility.Facility
      ? data.RoomTypeFacilities.RooomFacility.Facility
      : []
    : [];
  const breadCrumbName = templateData.menuLinks.menuLink.filter(
    (i) => i.pageId === "2"
  );

  const totalRooms = data.RoomTypeName.totalRooms;
  const maxGuestCapacity = data.RoomTypeName.maxGuestCapacity;

  const imagesMain = data.RoomTypeImages.Images;
  const roomImages = [];
  imagesMain.map((item, index) =>
    roomImages.push({
      image: sliderImageURL + item,
      imgLabel: data.RoomTypeImages.imgLabel[index],
      type: "roomTypes",
    })
  );

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <RoomTypeWrapper>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.RoomTypeName.section}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      <AccommodationImageWrap>
        {imagesMain.length > 0 ? (
          <img src={imagesMain[0]} />
        ) : (
          <img src={noImageUrl} alt="" />
        )}
        <h2>{data.RoomTypeName.section}</h2>
      </AccommodationImageWrap>

      {data.PreviousPageURL && (
        <BreadCrumbComponentWrapper>
          <Grid>
            <BreadCrumbComponent
              previousUrl={data.PreviousPageURL}
              currentUrl={data.RoomTypeName.section}
              previousName={breadCrumbName[0].MenuLinkpageName}
            />
          </Grid>
        </BreadCrumbComponentWrapper>
      )}

      <Grid>
        <ContentWrapper>
          <Row>
            <Col xs={12} md={12}>
              <p>
                <i>
                  {totalRooms} Rooms, sleep {maxGuestCapacity} persons / per
                  room
                </i>
              </p>
              <p>&nbsp;</p>
              <div
                className="p-padding roomTypeContent"
                dangerouslySetInnerHTML={{
                  __html: decodeContent(data.RoomTypeDescription),
                }}
              ></div>
              <NormalLink
                href={`${bookRoomUrl(data.RoomcatgId)}`}
                text="Book Now"
                className="btn-line"
                bgColor={constant.primaryColor}
              />
            </Col>
          </Row>
          <Grid className="roomTypeContent ">
            <Row>
              <Col xs={12} md={12}>
                <h3>Room Specifications:</h3>
                {facilities.length > 0 && (
                  <ul className="facilities room-specification">
                    {data.RoomTypeFacilities.RooomFacility.Facility.map(
                      (item, index) => {
                        return (
                          item !== "" && (
                            <li key={index}>
                              <FontAwesomeIcon icon={faChevronRight} />
                              {item}
                            </li>
                          )
                        );
                      }
                    )}
                  </ul>
                )}
              </Col>
            </Row>
          </Grid>
        </ContentWrapper>
      </Grid>

      <SliderWrapper>
        <Slider {...settings}>
          {imagesMain.map((item, index) => (
            <SlideImage
              className="main-image"
              bgimage={item}
              key={index}
            ></SlideImage>
          ))}
        </Slider>
      </SliderWrapper>
    </RoomTypeWrapper>
  );
};

export default RoomTypeComponent;
