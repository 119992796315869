import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "styled-components";
// import { propertyImageURL } from "../../global/constants";
// import { NormalLink } from "../Template3Pages/Links";
import BookingWidgetComponent from "../../global/BookingWidgetComponent";
import media from "../../global/media";
import constant from "./constant";

const HomePageWelcomeComponentWrapper = styled.div`
  padding: 1em 0;
  width: 80%;
  margin: 0 auto;
  h2 {
    margin-bottom: 40px;
    position: relative;
    font-size: 2em;
  }
  .welcomeBlock{
    text-align: center;
    span{
      font-size: 1.5rem;
      color: ${constant.secondaryColor};
      font-weight: 500;
    }
    h2{
      font-size: 1.5em;
    }
    ${media.md}{
      text-align: left;
      span{
        font-size: 2.5rem;
        color: ${constant.secondaryColor};
        font-weight: 500;
      }
      h2{
        font-size: 2.5em;
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #666;
    word-break: break-all;
    column-gap: 30px;
    column-width: 16em;
  }
  img {
    width: 100%;
  }
  ${media.md}{
    padding: 4em 0;
  }
`;

const BookingEngingeWidgetComponentWrapper = styled.div`
  padding: 50px 0;
  z-index: 1;
  position: relative;
  background: ${constant.greyColor};
  ${media.md} {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
  }
  h2 {
    text-align: center;
  }

  .bookingWidget {
    padding: 30px;

    display: flex;
    font-size: 14px;
    font-weight: bold;
    flex-wrap: wrap;
    //justify-content: ;
    align-items: center;
    .heading {
      font-size: 25px;
      display: none;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }
    .checkInWrapper,
    .checkOutWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 46%;
        margin-right: 20px;
      }
      ${media.md} {
        width: 20%;
        margin-right: 0;
      }
      .react-datepicker-wrapper {
        width: 100%;
        margin-top: 10px;
        .datepicker-input {
          border-radius: 0;
          padding: 10px;
          font-size: 12px;
          border: 1px solid #ccc;
        }
      }
    }
    input{
      border: 1px solid lightgrey;
      font-size: 16px;
      font-weight: 500;
      height: 65px;
      &:focus{
        border-color: none;
      }
    }
    .roomsWrapper {
      width: 100%;
      margin-bottom: 20px;

      ${media.sm} {
        width: 46%;
        margin-right: 20px;
      }
      ${media.md} {
        width: 20%;
        margin-right: 0;
      }

      .roomsWrapperLabel {
        background: #fff;
        width: 100%;
        padding: 10px;
        border: 1px solid lightgrey;
        font-size: 12px;
        font-weight: 500;
        height: 65px;
        margin-top: 10px;
        display:flex;
        align-items:center;
      }
      
    }
    .flexibleWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 18%;
      }
      input {
        margin-top: 10px;
        padding: 10px;
        border: solid 1px #ccc;
        font-size: 12px;
        width: 100%;
      }
    }
    .buttonWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.md} {
        width: 18%;
      }
      button {
        padding: 15px;
        width: 100%;
        background: ${constant.primaryColor};
        color: #fff;
        height: 65px;
        margin-top: 10px;
        border: none;
        &:hover{
          background: ${constant.secondaryColor};
        }
      }
    }
  }
`;

const contentLimit = 400;

const HomePageWelcomeComponent = ({
  propertyName,
  content,
  image,
  maxGuestCapacity,
  propertyId,
}) => (
  <div>
    <BookingEngingeWidgetComponentWrapper>
      <BookingWidgetComponent
        maxGuestCapacity={maxGuestCapacity}
        propertyId={propertyId}
      />
    </BookingEngingeWidgetComponentWrapper>
    <HomePageWelcomeComponentWrapper>
      <Grid>
        <Row>
          <Col xs={12} md={4} className="welcomeBlock">
            <span>Welcome</span> 
            <h2>{propertyName}</h2>
          </Col>
          <Col xs={12} md={8}>
            <p>
              {decodeURIComponent(content.substring(0, contentLimit)) + "..."}
            </p>
            <br />
          </Col>
        </Row>
      </Grid>
    </HomePageWelcomeComponentWrapper>
  </div>
);

export default HomePageWelcomeComponent;
