import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import HomePageWelcomeComponent from "./HomePageWelcomeComponent";
import {
  propertyImageURL,
} from "../../global/constants";
import { SectionWrapper } from "../Template2Pages/templateConstants";
import media from "../../global/media";
import { fetchReviews } from "../../providers/api";
import constant from "./constant";
import { NormalLink } from "../Template2Pages/Links";
import { formatDate1, calculateRating } from "../../global/functions";
import Lightbox from "react-image-lightbox";
import Slider from "react-slick";
import { faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';

import "react-image-lightbox/style.css";

const AccommodationBlock = styled.a`
  display: block;
  width: 100%;
  margin-top: 20px;
  position: relative;
  text-decoration: none;
  color: ${constant.primaryColor};
  &:hover {
    background: ${constant.greyColor};
  }
`;
const AccommodationImageWrap = styled.div`
  height: ${(prop) => prop.mobileHeight || prop.height};
  ${media.sm} {
    height: ${(prop) => prop.height};
  }
  width: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &:hover {
  }
`;
const AccommodationBlockContent = styled.div`
  padding: 20px 20px 40px;
  h3 {
    font-size: 1em;
    text-transform: uppercase;
    padding: 20px 0;
    color: #000;
    width: 100%;
    font-weight: 600;
    margin: 0;
  }
  p {
    line-height: 2;
  }
`;
const ReviewBlock = styled.div`
  display: block;
  margin: 10px 0;
  padding: 20px;
  border-radius: 0px;
  text-align: center;
  position: relative;
`;
const ReviewTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;
const ReviewLabel = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
`;
const Rating = styled.div`
  margin-bottom: 20px;
`;
const ReviewName = styled.div`
  margin-bottom: 20px;
  font-style: italic;
`;
const ChannelName = styled.div``;

const ReviewWrapper = styled.div`
  height: auto;
  margin: auto;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    &:before {
      font-size: 30px;
    }
  }
  
  .slick-prev:before {
    content: ${faChevronLeft} !important;
    color: ${constant.primaryColor} !important;
    font-size: 30px;
  }
  .slick-next:before {
    content: ${faChevronRight} !important;
    color: ${constant.primaryColor} !important;
    font-size: 30px;
  }
`;

const GalleryImageWrap = styled.div`
  height: 160px;
  margin-bottom: 20px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:hover{
      padding: calc(2% - 3px);
    }
  }
`;

const NormalLinkExtend = styled(NormalLink)`
  margin: 10px 10px;
`;

const HomePageComponent = ({ templateData, maxGuestCapacity }) => {
  const roomTypes = templateData.roomTypes.roomType;

  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState([]);

  

  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];
  
  templateData.propertyImages.image.map((value) => images.push(propertyImageURL + value));
  
  const openLightbox = (key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  function getReviews(propertyID) {
    fetchReviews(propertyID).then((data) => {
      setResultData(data && data.resultValue ? data.resultValue : []);
      setLoading(false);
    });
  }

  useEffect(() => {
    getReviews(templateData.propertyDetails.propertyID);
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div>
      <HomePageWelcomeComponent
        propertyName={templateData.propertyDetails.propertyName}
        content={templateData.propertyDetails.propertyDescription}
        image={templateData.propertyImages.image[0]}
        maxGuestCapacity={maxGuestCapacity}
        propertyId={templateData.propertyDetails.propertyID}
      />
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={12}>
              <h2>Accommodation</h2>
            </Col>
          </Row>
          <Row top="xs">
            {roomTypes.map((item, index) => (
              <Col xs={12} md={6} lg={4} key={index}>
                <AccommodationBlock href={item.RoomTypeName.slugName}>
                    <AccommodationImageWrap
                      height="300px"
                    >
                      <img
                        src={propertyImageURL + item.RoomTypeImages.Images[0]} alt=""
                      />
                    </AccommodationImageWrap>
                    <AccommodationBlockContent className="block-content">
                      <h3>{item.RoomTypeName.section}</h3>
                      <p>{item.RoomTypeDescription.substring(0, 100) + "..."}</p>
                        <NormalLinkExtend
                          to={item.RoomTypeName.slugName}
                          text="Read more"
                          className="btn-line accomodation-btn"
                        />
                    </AccommodationBlockContent>
                </AccommodationBlock>
              </Col>
            ))}
          </Row>
        </Grid>
      </SectionWrapper>
      <SectionWrapper bgColor={constant.greyColor}>
        <Grid>
          <Row>
            <Col xs={12} sm={12}>
              <h2>View our gallery</h2>
            </Col>
          </Row>
          <Row top="xs">
            {images.slice(0, 12).map((value, index) => (
              <Col xs={12} sm={6} md={4} lg={3} key={index}>
                <GalleryImageWrap>
                  <img src={value} onClick={() => openLightbox(index)} alt=""/>
                </GalleryImageWrap>
              </Col>
            ))}
          </Row>
        </Grid>
      </SectionWrapper>
      {templateData.reviewMinderWidget === "1" && (
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={12}>
              <h2>OUR GUEST LOVE US</h2>
            </Col>
          </Row>
          <ReviewWrapper>
            <Slider {...settings}>
              {!loading ? (
                resultData &&
                resultData.reviewlist &&
                resultData.reviewlist.slice(0, 4).map((item, index) => (
                    <ReviewBlock key={index}>
                      <ReviewTitle>{item.review_title}</ReviewTitle>
                      <ReviewLabel
                        dangerouslySetInnerHTML={{
                          __html: item.your_view,
                        }}
                      ></ReviewLabel>
                      <Rating>
                        {calculateRating(
                          item.rating_overall,
                          constant.primaryColor
                        )}
                      </Rating>
                      <ReviewName>
                        {item.guest_name} ({formatDate1(item.review_time)})
                      </ReviewName>
                      <ChannelName>
                        Review from: {item.channel_name}
                      </ChannelName>
                    </ReviewBlock>
                ))
              ) : (
                <Col xs={12}>Loading...</Col>
              )}
              </Slider>
          </ReviewWrapper>
        </Grid>
      </SectionWrapper>
      )}
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default HomePageComponent;
