import React from "react";
import styled from "styled-components";

import FooterLayout1 from "./layouts/FooterLayout1";
import FooterLayout2 from "./layouts/FooterLayout2";
import FooterLayout3 from "./layouts/FooterLayout3";
import FooterLayout4 from "./layouts/FooterLayout4";
import FooterLayout5 from "./layouts/FooterLayout5";
import FooterLayout6 from "./layouts/FooterLayout6";
import FooterLayout7 from "./layouts/FooterLayout7";
import FooterLayout8 from "./layouts/FooterLayout8";
import FooterLayout9 from "./layouts/FooterLayout9";
import FooterLayout10 from "./layouts/FooterLayout10";
import FooterLayout11 from "./layouts/FooterLayout11";

const FooterWrapper = styled.div``;
const Footer = ({
  templateId,
  contactInfo,
  socialLinks,
  menuLinks,
  propertyDetails,
  propertyImages,
  onNavClick,
  footerTop,
  reviewMinderWidget,
  data
}) => {
  return (
    <FooterWrapper>
      {templateId === "1" && (
        <FooterLayout1
          menuLinks={menuLinks}
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          data={data}
        />
      )}
      {templateId === "2" && (
        <FooterLayout2
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          menuLinks={menuLinks}
          data={data}
        />
      )}
      {templateId === "3" && (
        <FooterLayout3
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          menuLinks={menuLinks}
          propertyDetails={propertyDetails}
          data={data}
        />
      )}
      {templateId === "4" && (
        <FooterLayout4
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          address={contactInfo.address1}
          data={data}
        />
      )}
      {templateId === "5" && (
        <FooterLayout5
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          data={data}
        />
      )}
      {templateId === "6" && (
        <FooterLayout6
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          data={data}
        />
      )}
      {templateId === "7" && (
        <FooterLayout7
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          menuLinks={menuLinks}
          data={data}
          // onNavClick={onNavClick}
          footerTop={footerTop}
          reviewMinderWidget={reviewMinderWidget}
        />
      )}
      {templateId === "8" && (
        <FooterLayout8
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          menuLinks={menuLinks}
          data={data}
          // onNavClick={onNavClick}
          footerTop={footerTop}
          reviewMinderWidget={reviewMinderWidget}
        />
      )}
      {templateId === "9" && (
        <FooterLayout9
          contactInfo={contactInfo}
          data={data}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          menuLinks={menuLinks}
          // onNavClick={onNavClick}
          footerTop={footerTop}
          reviewMinderWidget={reviewMinderWidget}
        />
      )}
      {templateId === "10" && (
        <FooterLayout10
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          data={data}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          menuLinks={menuLinks}
          // onNavClick={onNavClick}
          footerTop={footerTop}
          reviewMinderWidget={reviewMinderWidget}
        />
      )}
      {templateId === "11" && (
        <FooterLayout7
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          menuLinks={menuLinks}
          // onNavClick={onNavClick}
          data={data}
          footerTop={footerTop}
          reviewMinderWidget={reviewMinderWidget}
        />
      )}
      {templateId === "12" && (
        <FooterLayout7
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          menuLinks={menuLinks}
          // onNavClick={onNavClick}
          footerTop={footerTop}
          reviewMinderWidget={reviewMinderWidget}
          data={data}
        />
      )}
      {templateId === "13" && (
        <FooterLayout7
          contactInfo={contactInfo}
          socialLinks={socialLinks}
          propertyDetails={propertyDetails}
          propertyImages={propertyImages}
          menuLinks={menuLinks}
          // onNavClick={onNavClick}
          footerTop={footerTop}
          reviewMinderWidget={reviewMinderWidget}
          data={data}
        />
      )}
    </FooterWrapper>
  );
};

export default Footer;
