import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import { sliderImageURL, noImageUrl } from "../../global/constants";
import constant from "./constant";
import { bookNowUrl } from "../../global/constants";
import media from "../../global/media";
import { Link } from "react-router-dom";
import { IoMdArrowRoundForward as RightArrow } from "react-icons/io";

const AccommodationImageWrap = styled.div`
  height: 100%;
  border: 1px solid #ccc;
  margin-top: 15px;
  .card-img-top {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  h3 {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
  }
  .accommodationBtn {
    width: 100%;
    text-align: center;
    margin-top: -30px;
  }
  ${media.md} {
    margin-top: 0;
  }
`;

const LinkWrapper = styled(Link)`
  padding: 15px 25px;
  margin: 10px 10px;
  color: #fff;
  position: relative;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border: 2px solid ${constant.secondaryColor};
  border-radius: 50px;
  font-size: 16px;
  background: ${constant.secondaryColor};
  transition: all 0.4s ease-in-out;

  &:hover {
    background: ${constant.primaryColor};
    border: 2px solid ${constant.primaryColor};
    color: ${constant.secondaryColor};
  }
  .arrow {
    display: none;
    transition: all 0.4s ease-in-out;
    margin-left: 10px;
    position: absolute;
    top: 16px;
    right: 8px;
  }
  &:hover {
    .arrow {
      transition: all 0.4s ease-in-out;

      display: inline;
    }
  }
`;
const Arrow = styled(RightArrow)``;

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  return (
    <SectionWrapper className="margin">
      <h2>Accommodation</h2>
      <Grid>
        <Row>
          {roomTypes.map((value, index) => {
            return (
              <Col md={6} xs={12} className="cardCol">
                <AccommodationImageWrap>
                  {value.RoomTypeImages.Images.length > 0 ? (
                    <img
                      src={sliderImageURL + value.RoomTypeImages.Images[0]}
                      alt=""
                      class="card-img-top"
                    />
                  ) : (
                    <img src={noImageUrl} class="card-img-top" alt="" />
                  )}
                  <div className="accommodationBtn">
                    <LinkWrapper to={value.RoomTypeName.slugName}>
                      Read More
                      <Arrow className="arrow" />
                    </LinkWrapper>
                    <LinkWrapper
                      to={`${bookNowUrl}${templateData.propertyDetails.propertyID}`}
                    >
                      Book Now
                      <Arrow className="arrow" />
                    </LinkWrapper>
                  </div>
                  <div className="card-body">
                    <h3>{value.RoomTypeName.section}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeURIComponent(value.RoomTypeDescription),
                      }}
                    ></div>
                  </div>
                </AccommodationImageWrap>
              </Col>
            );
          })}{" "}
        </Row>
      </Grid>
    </SectionWrapper>
  );
};

export default AccommodationComponent;
