import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { Link } from "react-router-dom";
import HomePageWelcomeComponent from "../Template7Pages/HomePageWelcomeComponent";
import { SectionWrapper } from "./templateConstants.js";
import media from "../../global/media";
import constant from "../Template7Pages/constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import RoomDetailComponent from "./RoomDetailComponent";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardWrapper = styled.div`
  width: auto;
  background: #eee;
  display: flex;
  flex-wrap: wrap;
  line-height: 2;
`;

const PromotionWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  ${media.md} {
    flex-direction: row;
  }
  .centerContent {
    position: relative;
    padding: 1em 0.5em;
    width: 100%;
    display: flex;
  }
`;

const HomePageComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const facilities = templateData.propertyDetails.facilities.facility;
  const accommodationSlugName = templateData.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  return (
    <div>

      <HomePageWelcomeComponent
        propertyName={templateData.propertyDetails.propertyName}
        content={templateData.propertyDetails.propertyDescription}
        image={templateData.propertyImages.image[0]}
        roomTypes={roomTypes}
        aboutUs={templateData.aboutUs}
      />
      <SectionWrapper bgColor="#eee">
        <CardWrapper>
          <Grid>
            <Row>
              <Col xs={12}>
                <h2>
                 
                  Accommodation<span></span>
                </h2>
              </Col>
            </Row>
            <Row className="room-types">
              {roomTypes.map((item, index) => (
                <Col md={6} lg={4} xs={12} className="cardCol" key={index}>
                  <RoomDetailComponent
                    value={item}
                    mainSlugName={`${process.env.PUBLIC_URL}${accommodationSlugName[0]["menuSlugName"]}`}
                  />
                </Col>
              ))}
            </Row>
          </Grid>
        </CardWrapper>
      </SectionWrapper>

      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12}>
              <h2>
                Property Facilities<span></span>
              </h2>
            </Col>
          </Row>
          <PromotionWrapper>
            <div className="centerContent">
              <ul className="facilities">
                {facilities.slice(0, 36).map((item, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon={faChevronRight} />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </PromotionWrapper>
        </Grid>
      </SectionWrapper>
    </div>
  );
};

export default HomePageComponent;
