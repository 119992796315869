import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import { NormalLink } from "./Links";
import { sliderImageURL, noImageUrl } from "../../global/constants";
import { bookNowUrl } from "../../global/constants";

const AccommodationImageWrap = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  position: relative;
  border: solid 1px #ccc;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .direction-s {
    flex-direction: row-reverse;
  }
  .direction-r {
    flex-direction: row;
  }
  .room-desc {
    padding: 20px;
    p {
      margin-bottom: 20px;
    }
    .btn-div {
      display: flex;
      justify-content: space-evenly;
    }
  }

  h3 {
    font-size: 24px;
  }
`;

const NormalLinkExtend = styled(NormalLink)`
  margin-right: 10px;
`;

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  return (
    <SectionWrapper className="margin">
      <Grid>
        <Row>
          <Col xs={12}>
            <h2>Accommodation</h2>
            {roomTypes.map((value, index) => {
              return (
                <AccommodationImageWrap>
                  <Row className={"direction-" + (index % 2 ? "s" : "r")}>
                    <Col md={6} xs={12}>
                      {value.RoomTypeImages.Images.length > 0 ? (
                        <img
                          src={sliderImageURL + value.RoomTypeImages.Images[0]}
                          alt=""
                        />
                      ) : (
                        <img src={noImageUrl} alt="" />
                      )}
                    </Col>
                    <Col md={6} xs={12}>
                      <div className="room-desc">
                        <h3>{value.RoomTypeName.section}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodeURIComponent(
                              value.RoomTypeDescription
                            ),
                          }}
                        ></div>
                        <div className="btn-div">
                          <NormalLinkExtend
                            to={value.RoomTypeName.slugName}
                            text="Read more"
                            className="btn-line"
                          />
                          <NormalLinkExtend
                            to={`${bookNowUrl}${templateData.propertyDetails.propertyID}`}
                            text="Book Now"
                            className="btn-line"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </AccommodationImageWrap>
              );
            })}{" "}
          </Col>
        </Row>
      </Grid>
    </SectionWrapper>
  );
};

export default AccommodationComponent;
