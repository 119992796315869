import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import constant from "./constant";

const LinkWrapper = styled(Link)`
  display: inline-block;
  padding: 10px 4.5em 10px 10px;
  border: 2px solid ${constant.blackColor};
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.52px;
  text-decoration: none;
  color: ${constant.blackColor};
  position: relative;
  overflow: hidden;
  line-height: 1;
  margin-right: 1em;
  svg {
    z-index: 1;
    color: ${constant.blackColor};
    position: absolute;
    right: 1.8em;
  }
  &:hover {
    background-color: ${constant.blackColor};
    color: #fff;
    text-decoration: none;
    svg {
      color: #fff;
    }
  }
`;

const AvailabilityWrapper = styled.div`
  display: inline-block;
  padding: 10px 8px;
  text-decoration: none;
  text-decoration: none;
  color: #0e3360;
  position: relative;
  transition: all 1s ease 0s;
  overflow: hidden;
  line-height: 1;
  background-color: #fff;
`;

const NormalLink = ({ to, text }) => (
  <LinkWrapper to={to}>
    {text} <FontAwesomeIcon icon={faChevronRight} />
  </LinkWrapper>
);

const AvailabilityBtn = ({ to, text }) => (
  <AvailabilityWrapper to={to}>{text}</AvailabilityWrapper>
);

export { NormalLink, AvailabilityBtn };
