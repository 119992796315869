import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

import { EmptyStar } from "./svg";

const FaIcons = styled.div`
  font-size: 25px;
  color: ${(prop) => prop.color};
  .icon-settings {
    border: 1px;
  }
`;

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

/* yyyy-mm-dd */
const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

/* dd MMM yyyy */
const formatDate1 = (date) => {
  var d = new Date(date);
  return d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear();
};


const calculateRating = (rating, color) => {
  //let rating = 4.5;
  const rating1 = rating * 100;
  const ratingJson = [];
  for (let i = 0; i < 5; i++) {
    ratingJson.push(i);
  }
  const totalRating = 5;
  if (rating1 % 100 === 0) {
    let leftRating = totalRating - rating;

    return (
      <FaIcons color={color}>
        {ratingJson.slice(0, rating).map((item, index) => (
          <FontAwesomeIcon icon={faStar} className="icon-settings" />
        ))}
        {ratingJson.slice(0, leftRating).map((item, index) => (
          <EmptyStar />
        ))}
      </FaIcons>
    );
  } else {
    const ratingRound = Math.round(rating) - 1;
    const leftRating = totalRating - ratingRound - 1;
    return (
      <FaIcons color={color}>
        {ratingJson.slice(0, ratingRound).map((item, index) => (
          <FontAwesomeIcon icon={faStar} className="icon-settings" />
        ))}
        <FontAwesomeIcon icon={faStarHalfAlt} className="icon-settings" />
        {ratingJson.slice(0, leftRating).map((item, index) => (
          <EmptyStar />
        ))}
      </FaIcons>
    );
  }
};

function decodeEntities(encodedString) {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
      "nbsp":" ",
      "amp" : "&",
      "quot": "\"",
      "lt"  : "<",
      "gt"  : ">"
  };
  return encodedString.replace(translate_re, function(match, entity) {
      return translate[entity];
  }).replace(/&#(\d+);/gi, function(match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
  });
}

export { formatDate, formatDate1,decodeEntities, calculateRating};
