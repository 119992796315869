import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import media from "../../global/media";
import constant from "../Template8Pages/constant";
import { decodeContent, noImageUrl } from "../../global/constants";

const HomePageCardWrapper = styled(Link)`
  background: #fff;
  text-decoration: none;
  color: ${constant.bodyColor};
  display: block;
  margin-bottom: 70px;
  ${media.lg} {
    margin-bottom: 100px;
  }

  /* &:hover {
    span {
      text-decoration: underline;
    }
  } */
`;
const CardImage = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  /* padding:0 2.5%; */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: cover;
  }
  ${media.lg} {
  }
`;

const CardContent = styled.div`
  width: 100%;
  overflow: hidden;
  scroll-snap-align: start;

  h3 {
    font-size: 2em;
    font-weight: 600;
    color: ${constant.primaryColor};
    margin: 15px 0 0 0;
    letter-spacing: -0.01em;
    line-height: 1em;

    ${media.sm} {
      font-size: 2em;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.9em;
    letter-spacing: 0.5px;
    color: ${constant.bodyColor};
    padding: 10px 0;
    font-weight: 400;
    margin-bottom: 5px;
    span{
      font-size: 15px;
      text-decoration: none;
      text-transform: none;
    }
  }
`;
const contentLimit = 300;

const TemplateCardComponent = ({
  pageId,
  menuSlugName,
  headerImage,
  MenuLinkpageName,
  metaDesc,
  bookingEngine,
  multiPage,
  home,
  propertyImages,
  mainSlugName,
}) => {
  const regex = /<style.*?<\/style>/g;
  const propertyImageLength =
    propertyImages && propertyImages.length > 0 ? propertyImages.length : 0;
  let randomImageUrl = "";
  if (propertyImageLength > 0) {
    const randomnumber = Math.floor(Math.random() * propertyImageLength) + 1;
    randomImageUrl = propertyImages[randomnumber];
  }
  return (
    <>
      <HomePageCardWrapper
        key={pageId}
        to={mainSlugName ? mainSlugName + menuSlugName : menuSlugName}
        onClick={() => window.scrollTo(0, 0)}
      >
        <CardImage>
          {headerImage ? (
            <img src={headerImage} alt="" />
          ) : propertyImageLength > 0 && randomImageUrl ? (
            <img src={randomImageUrl} alt="" />
          ) : (
            <img src={noImageUrl} alt="" />
          )}
        </CardImage>

        <CardContent>
          {MenuLinkpageName && <h3>{MenuLinkpageName}</h3>}
          {bookingEngine ? (
            <p
              className="content-desc"
              dangerouslySetInnerHTML={{
                __html:
                  multiPage === 1
                    ? decodeContent(
                        metaDesc
                          .replace(regex, "")
                          .replace(/(<([^>]+)>)/gi, "")
                          .substring(0, contentLimit)
                      ) + metaDesc &&
                      metaDesc !== "" && "Read More <span>→</span>"
                    : decodeContent(metaDesc),
              }}
            ></p>
          ) : home === 1 ? (
            <p>
              Read More <span>→</span>
            </p>
          ) : (
            <p>
              {metaDesc
                .replace(regex, "")
                .replace(/(<([^>]+)>)/gi, "")
                .substring(0, contentLimit)}
            </p>
          )}
        </CardContent>
      </HomePageCardWrapper>
    </>
  );
};

export default TemplateCardComponent;
