import React from "react";
import styled from "styled-components";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
  bookNowUrl,
  bookRoomUrl,
} from "../../global/constants";
import constant from "./constant";

const AccommodationImageWrap = styled.div`
  height: 100%;
  background: #fff;
  text-decoration: none;
  color: ${constant.bodyColor};
  display: block;
  .card-img-top {
    width: 100%;
    object-fit: cover;
  }
  h3 {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: ${constant.primaryColor};
    text-transform: uppercase;
    letter-spacing: 3px;
    font-style: normal;
    word-break: normal;
  }
  .accommodationBtn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }
  .card-desc {
    font-size: 12px;
    height: 90px;
    overflow: hidden;
    color: ${constant.bodyColor};
    font-style: normal;
    word-break: normal;
  }
`;

const LinkWrapper = styled.a`
  padding: 5px 10px;
  color: #fff;
  position: relative;
  text-align: center;
  text-decoration: none;
  border: 2px solid ${constant.primaryColor};

  font-size: 13px;
  background: ${constant.primaryColor};
  &:first-child {
    background: none;
    color: ${constant.secondaryColor};
    border: 0;
    padding: 0;
    font-style: italic;
  }
`;
const RoomDetailComponent = ({ value, mainSlugName }) => (
  <AccommodationImageWrap>
    <LinkWrapper
      onClick={() => window.scrollTo(0, 0)}
      href={mainSlugName + value.RoomTypeName.slugName}
    >
      {value.RoomTypeImages.Images.length > 0 ? (
        <img
          src={sliderImageURL + value.RoomTypeImages.Images[0]}
          alt=""
          className="card-img-top"
        />
      ) : (
        <img src={noImageUrl} class="card-img-top" alt="" />
      )}
    </LinkWrapper>
    <div className="card-body">
      <LinkWrapper
        onClick={() => window.scrollTo(0, 0)}
        href={mainSlugName + value.RoomTypeName.slugName}
      >
        <h3>{value.RoomTypeName.section}</h3>
        <div
          className="card-desc"
          dangerouslySetInnerHTML={{
            __html: decodeContent(value.RoomTypeDescription),
          }}
        ></div>
      </LinkWrapper>
      <div className="accommodationBtn">
        <LinkWrapper
          onClick={() => window.scrollTo(0, 0)}
          href={mainSlugName + value.RoomTypeName.slugName}
        >
          more >>
        </LinkWrapper>
        <LinkWrapper href={`${bookRoomUrl(value.RoomcatgId)}`} target="_blank">
          Book Now
        </LinkWrapper>
      </div>
    </div>
  </AccommodationImageWrap>
);
export default RoomDetailComponent;
