import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";

const CapacityWrapper = styled.div`
  position: relative;
  cursor: pointer;
  .roomsWrapperLabel {
    margin-top: 8px;
    color: #000;
  }
`;

const RoomBoxContainer = styled.div`
  position: absolute;
  font-weight: normal;
  background: #fff;
  color: #000;
  font-size: 11px;

  .roomHead {
    background-color: #e6e6e6;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  .inline {
    display: inline-block;
    width: 100%;
  }
  .padding {
    padding: 5px;
  }
  .btn-counter {
    border: 1px solid transparent;
    width: 25%;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
  .half-width {
    width: 50%;
  }
  .float-right {
    float: right;
  }
`;
const RoomBlock = styled.div`
  border: solid 1px #ccc;
  width: 100%;
  padding: 10px;
`;
const RoomBlockInside = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0;
  .button-wrapper {
    margin-left: 5px;
    border: solid 1px #ccc;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 25px;
    button {
      width: 15px;
      background: #ccc;
      font-weight: bold;
      border: 0;
    }
    .values {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: center;
    }
  }
`;
const OutsideDivRefWrapper = styled.div``;

const CapacityComponent = ({
  roomBox,
  setRoomBox,
  room,
  setRooms,
  roomString,
  setRoomString,
  maxGuestCapacity,
}) => {
  const showRoomBox = () => {
    setRoomBox(!roomBox);
  };

  const increment = (key, value, label) => {
    if (room[key]["adult"] + room[key]["child"] < maxGuestCapacity) {
      room[key][label] = value + 1;
      setRooms((room) => [...room]);
      roomString[0][label] = roomString[0][label] + 1;
      setRoomString([...roomString]);
    } else {
      alert("Not allowed max guest");
    }
  };

  const decrement = (key, value, label) => {
    if (room[key][label] > 0) {
      room[key][label] = value - 1;
      setRooms((room) => [...room]);
      roomString[0][label] = roomString[0][label] - 1;
      setRoomString([...roomString]);
    }
  };

  const createRoom = () => {
    room.push({ adult: 1, child: 0 });
    setRooms((room) => [...room]);
    roomString[0]["adult"] = roomString[0]["adult"] + 1;
    setRoomString([...roomString]);
  };

  const removeRoom = (index) => {
    roomString[0]["adult"] -= room[index]["adult"];
    roomString[0]["child"] -= room[index]["child"];
    room.splice(index, 1);
    setRooms((room) => [...room]);
    setRoomString([...roomString]);
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setRoomBox(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const roomItemContainer = (item, index) => {
    return (
      <RoomBlock>
        <Row>
          <Col xs={12}>
            <div className="roomHead">
              Room {index + 1}
              {index === 0 ? (
                <span className="float-right" onClick={createRoom}>
                  +
                </span>
              ) : (
                <span className="float-right" onClick={() => removeRoom(index)}>
                  -
                </span>
              )}
            </div>
          </Col>
          <Col xs={6}>
            <RoomBlockInside>
              <label>Adult</label>
              <div className="button-wrapper">
                <button onClick={() => decrement(index, item.adult, "adult")}>
                  -
                </button>
                <span className="values">{item.adult}</span>
                <button onClick={() => increment(index, item.adult, "adult")}>
                  +
                </button>
              </div>
            </RoomBlockInside>
          </Col>
          <Col xs={6}>
            <RoomBlockInside>
              <label>Child</label>
              <div className="button-wrapper">
                <button onClick={() => decrement(index, item.child, "child")}>
                  -
                </button>
                <span className="values">{item.child}</span>
                <button onClick={() => increment(index, item.child, "child")}>
                  +
                </button>
              </div>
            </RoomBlockInside>
          </Col>
        </Row>
      </RoomBlock>
    );
  };

  return (
    <CapacityWrapper>
      <div onClick={showRoomBox} className="roomsWrapperLabel">
        {roomString[0].adult + " Adult "}
        {roomString[0].child + " Child"}
      </div>
      {roomBox && (
        <OutsideDivRefWrapper ref={wrapperRef}>
          <RoomBoxContainer>
            {room.map((item, index) => roomItemContainer(item, index))}
          </RoomBoxContainer>
        </OutsideDivRefWrapper>
      )}
    </CapacityWrapper>
  );
};

export default CapacityComponent;
