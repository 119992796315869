import React from "react";
import styled from "styled-components";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
  bookNowUrl,
  bookRoomUrl,
} from "../../global/constants";
import constant from "./constant";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import media from "../../global/media";

const AccommodationWrapper = styled(Link)`
  height: 100%;
  background: #fff;
  text-decoration: none;
  color: ${constant.bodyColor};
  display: block;

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translate(0%, 0%);

    ${media.md} {
    }
  }
  p {
    white-space: nowrap;
  }
  h3 {
    padding-left: 0;
    font-size: 1.3rem;
    font-weight: 500;
    color: ${constant.primaryColor};
    text-transform: none;
    letter-spacing: 1px;
    line-height: 1.2em;
  }

  .card-desc {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.6px;
  }
`;

const AccommodationImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const LinkWrapper = styled.div`
  /* padding: 5px 10px; */
  color: ${constant.bodyColor};
  position: relative;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.6px;
  span {
    font-size: 15px;
  }
`;
const RoomDetailComponent = ({ value, mainSlugName }) => (
  <AccommodationWrapper
    onClick={() => window.scrollTo(0, 0)}
    to={mainSlugName + value.RoomTypeName.slugName}
  >
    <Row>
      <Col xs={12} sm={5} md={4}>
        <AccommodationImageWrapper>
          {value.RoomTypeImages.Images.length > 0 ? (
            <img
              className="card-img"
              src={sliderImageURL + value.RoomTypeImages.Images[0]}
              alt=""
            />
          ) : (
            <img src={noImageUrl} class="card-img" alt="" />
          )}
        </AccommodationImageWrapper>
      </Col>
      <Col xs={12} sm={7} md={8}>
        <div className="card-body">
          <h3>{value.RoomTypeName.section}</h3>
          <div
            className="card-desc"
            dangerouslySetInnerHTML={{
              __html: decodeContent(value.RoomTypeDescription),
            }}
          ></div>
          <div className="accommodationBtn">
            <LinkWrapper>
              Read More <span>→</span>
            </LinkWrapper>
          </div>
        </div>
      </Col>
    </Row>
  </AccommodationWrapper>
);
export default RoomDetailComponent;
