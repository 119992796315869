/* Grid Breakpoints as per bootstrap 3 */

export const breakpoints = {
  xs: 32,
  sm: 48 /* Small Device  */,
  md: 62 /* Medium Device */,
  lg: 75 /* Large Device */,
};

const media = Object.keys(breakpoints).reduce(
  (sum, breakpoint) =>
    Object.assign(sum, {
      [breakpoint]: `@media screen and (min-width: ${breakpoints[breakpoint]}em)`,
    }),
  {}
);

export default media;
