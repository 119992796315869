import React from "react";
import styled from "styled-components";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
  bookNowUrl,
  bookRoomUrl,
  randomImageGenerator,
} from "../../global/constants";
import constant from "./constant";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import media from "../../global/media";

const AccommodationWrapper = styled(Link)`
  background: #191919;
  text-decoration: none;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
  display: block;
  color: #7e7e7e;
  .card-img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    width: 100%;
    height: auto;
    border: 0;
    transition: all 0.2s cubic-bezier(0.56, 0.32, 0.43, 0.9);
    ${media.md} {
    }
  }
  .card-body {
    padding: 1em 0 1em 0;
    font-weight: 600;
  }
  p {
    white-space: nowrap;
    line-height: 1.8;
    margin-bottom: 0;
    margin-top: 0;
    color: #fff;
    text-align: center;
  }
  h5 {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    font-weight: 900;
    font-size: 1.3rem;
    font-weight: 500;
    color: ${constant.secondaryColor};
    text-transform: none;
    letter-spacing: 1px;
    line-height: 1.2em;
  }

  .card-desc {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`;

const AccommodationImageWrapper = styled.div`
  overflow: hidden;
  /* width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px; */
`;

const LinkWrapper = styled.div`
  /* padding: 5px 10px; */
  color: ${constant.bodyColor};
  position: relative;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.6px;
  span {
    font-size: 15px;
  }
`;
const SubPageCardComponent = ({
  pageId,
  menuSlugName,
  headerImage,
  MenuLinkpageName,
  metaDesc,

  propertyImages,
  mainSlugName,
}) => (
  <AccommodationWrapper
    key={pageId}
    to={mainSlugName ? mainSlugName + menuSlugName : menuSlugName}
    onClick={() => window.scrollTo(0, 0)}
  >
    <AccommodationImageWrapper>
      {headerImage ? (
        <img src={headerImage} className="card-img" alt="" />
      ) : propertyImages.length > 0 ? (
        <img
          src={randomImageGenerator(propertyImages)}
          className="card-img"
          alt=""
        />
      ) : (
        <img src={noImageUrl} class="card-img" alt="" />
      )}
    </AccommodationImageWrapper>
    <div className="card-body">
      {MenuLinkpageName && <p>{MenuLinkpageName}</p>}
      {metaDesc && (
        <>
          <div
            className="card-desc"
            dangerouslySetInnerHTML={{
              __html: decodeContent(metaDesc),
            }}
          ></div>
          <div className="accommodationBtn">
            <LinkWrapper>
              Read More <span>→</span>
            </LinkWrapper>
          </div>
        </>
      )}
    </div>
  </AccommodationWrapper>
);
export default SubPageCardComponent;
