import styled from "styled-components";
import media from "../../global/media";
import constant from "./constant";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/fontawesome-free-solid";

const SectionWrapper = styled.div`
  padding: 3em 0;
  background: ${(prop) => prop.bgColor || "#fff"};

  .minheight {
    min-height: 60vh;
  }
  position: relative;

  .room-types {
    justify-content: center;
  }
  ul.facilities {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    li {
      width: 100%;
      font-size: 12px;
      letter-spacing: 2px;
      margin-bottom: 10px;
      color: ${constant.bodyColor};
      ${media.md} {
        width: 33%;
        font-size: 12px;
      }
      line-height: 1.8;
      svg {
        color: ${constant.bodyColor};
        margin-right: 10px;
      }
    }
  }
  &.margin {
    ${media.md} {
    }
    line-height: 2;
    ul {
    }
  }

  p {
    color: ${constant.bodyColor};
    word-break: normal;
  }
  h2 {
    text-transform: uppercase;
    padding-bottom: 30px;
    font-size: 1.2em;
    position: relative;
    text-align: center;
    font-weight: normal;

    span {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 2px;
      background: ${constant.secondaryColor};
    }
    &.left {
      text-align: left;
    }
  }
  .heading-link {
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.sm} {
      justify-content: flex-end;
    }
  }
  .sub-headline {
    text-align: center;
  }
  .block-heading {
    text-align: center;
    span {
      font-size: 1.5rem;
      color: ${constant.secondaryColor};
      font-weight: 500;
    }
    h2 {
      font-size: 1.5em;
      margin-top: 0px;
    }
    ${media.md} {
      text-align: center;
      span {
        font-size: 2.5rem;
        color: ${constant.secondaryColor};
        font-weight: 500;
      }
      h2 {
        font-size: 2.5em;
      }
    }
  }
  &.noPadding {
    padding: 0;
  }
  .cardCol {
    margin: 20px 0px;
    transition: translateY 5s ease-in;
    .card-body {
      padding: 30px;
      font-weight: normal;
      ul li strong {
        font-weight: normal;
      }
    }
  }
  &.promotion {
    display: block;
    width: 100%;
    ${media.md} {
      display: flex;
    }
  }
  .slick-prev:before {
    content: ${faChevronLeft};
    color: ${constant.primaryColor};
    font-size: 30px;
  }
  .slick-next:before {
    content: ${faChevronRight};
    color: ${constant.primaryColor};
    font-size: 30px;
  }
  .contactBlock {
    border: 1px solid #ccc;
    h2 {
      font-size: 16px;
    }
    .email {
      font-size: 16px;
      font-weight: 600;
      padding: 10px 0px;
    }
    .icon-settings,
    .heading {
      color: ${constant.primaryColor};
    }
  }
  .checked {
    color: #919191;
    width: 20px;
    height: 20px;

    padding: 5px;
  }
  .roomTypeContent {
    strong {
      font-weight: normal;
    }
  }
  .iconColor {
    color: ${constant.primaryColor};
  }
  p {
    line-height: 2em !important;
  }
  ul li {
    color: ${constant.bodyColor};
  }
  .content-desc {
    a {
      color: ${constant.secondaryColor};
    }
  }
`;

export { SectionWrapper };
