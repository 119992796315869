import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";

import { SectionWrapper } from "../Template5Pages/templateConstants";
import { sliderImageURL, noImageUrl } from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHome, faUsers } from "@fortawesome/free-solid-svg-icons";
import { NormalLink } from "../Template5Pages/Links";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { bookNowUrl } from "../../global/constants";
// import media from "../../global/media";
import constant from "../Template6Pages/constant";


const AccommodationImageWrap = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  padding: 0 0 4em 0;
  position: relative;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  ul {
    margin-top: 40px !important;
  }
  a {
    float: right !important;
    margin-top: 30px;
  }
`;

const SliderWrapper = styled.div`
  height: 300px;
  overflow: hidden;
  .slick-slider {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58,49,56, .8);
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before, .slick-next:before{
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    left: 20px;
  }
  .slick-next{
    right: 20px;
  }
`;

const SlideImage = styled.div`
  img {
    width: 100%;
  }
`;
const HeaderImage = styled.div`
  height: 200px;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
`;

const RoomTypeComponent = ({ data, propertyId, templateData }) => {
  const facilities = data.RoomTypeFacilities.RooomFacility;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.RoomTypeName.section}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.headerImage && <HeaderImage bgImage={data.headerImage} />}
      <SectionWrapper className="margin">
        <Grid>
          <Row>
            <Col xs={12}>
              <h2>{data.RoomTypeName.section}</h2>
              <AccommodationImageWrap>
                <Row>
                  <Col xs={12} md={6}>
                    {data.RoomTypeImages.Images.length > 0 ? (
                      <SliderWrapper>
                        <Slider {...settings}>
                          {data.RoomTypeImages.Images.map((item, index) => (
                            <SlideImage>
                              <img src={sliderImageURL + item} alt=''/>
                            </SlideImage>
                          ))}
                        </Slider>
                      </SliderWrapper>
                    ) : (
                      <img src={noImageUrl} alt=''/>
                    )}
                  </Col>
                  <Col xs={12} md={6}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeURIComponent(data.RoomTypeDescription),
                      }} className="roomTypeContent"
                    ></div>
                    <Row>
                          <Col md={12}>
                            <FontAwesomeIcon icon={faHome} className="iconColor" />&nbsp;&nbsp;
                            {data.RoomTypeName.totalRooms} Rooms
                          </Col>
                          <Col md={12}>
                            <FontAwesomeIcon icon={faUsers} className="iconColor" />&nbsp;&nbsp;
                            {data.RoomTypeName.maxGuestCapacity} persons/per room
                          </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <ul className="facilities">
                      {facilities &&
                        facilities.Facility.map((value, index) => (
                          <li>
                            <FontAwesomeIcon icon={faCheck} className="checked"/> {value}{" "}
                          </li>
                        ))}{" "}
                    </ul>

                    <NormalLink
                      to={`${bookNowUrl}${propertyId}`}
                      text="Book Now"
                      className="btn-line"
                    />
                  </Col>
                </Row>
              </AccommodationImageWrap>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </div>
  );
};

export default RoomTypeComponent;
