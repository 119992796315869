import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderImageURL } from "../global/constants";
import constant from "../Template/Template6Pages/constant";
import media from "../global/media";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SliderWrapper = styled.div`
  height: 100vh;
  background-color: rgba(98, 98, 98, 0.4);
  opacity: 1;
  transition: opacity 2000s ease-in-out;
  overflow: hidden;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: ${constant.bgBlack};
    transition:all 0.2s ease-in-out;
    border-radius:50%;
    padding-top: 5px;
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: ${constant.primaryColor};
    }
  }
  .slick-prev {
    top:90%;
    left: 10%;
    
    /* ${media.md}{
      left: 60px;
    } */
  }
  .slick-next {
    top:90%;
    left: 20%;
    
    /* ${media.md}{
      right: 60px;
    }
  } */
  .slick-prev:before{
    font-family: "FontAwesome";
    content: ${faChevronLeft};
  }
  .slick-next:before{
    font-family: "FontAwesome";
    content: ${faChevronRight};
  }
  }
`;
const SlideImage = styled.div`
  background-image: url(${(prop) => prop.bgimage});
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  img {
    width: 100%;
  }
`;
const Caption = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  color: #fff;
  font-size: 2em;
  ${media.md} {
    top: 30%;
  }
`;

const InnerLayer = styled.div`
    background: rgba(15,22,42,.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const HomePageSlider = ({ sliderImages, sliderLabel, sliderDesc }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    
    cssEase: 'linear',
    arrow:false
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {sliderImages.sliderImage.map((item, index) => (
          <SlideImage bgimage={sliderImageURL + item}>
            <InnerLayer>
              {sliderImages.sliderLabel[index] && (
                <Caption>
                  <h2>{sliderImages.sliderLabel[index]}</h2>
                  <p>{sliderImages.sliderDesc[index]}</p>
                </Caption>
              )}
            </InnerLayer>
          </SlideImage>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default HomePageSlider;
