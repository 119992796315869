import React from "react";
import styled from "styled-components";
import BookingWidget1Component from "./BookingWidget1Component";
import BookingWidget2Component from "./BookingWidget2Component";
import data from "../../../hotelData.json";

const BookingWidgetWrapperComponentMain = styled.div``;

const BookingWidgetWrapperComponent = ({
  primaryColor,
  secondaryColor,
  textColor,
  backgroundColor,
  buttonColor,
  borderColor,
  datePickerColor,
  widgetBg,
  roomBgColor,
  roomTextColor,
  roomHeadBg,
  roomHeadText,
  roomContentBg,
  roomContentText,
  buttonBgColor,
}) => {
  return (
    <BookingWidgetWrapperComponentMain>
      {data.widgetID === "1" && (
        <BookingWidget1Component
          propertyName={data.propertyDetails.propertyName}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          buttonColor={buttonColor}
          borderColor={borderColor}
          datePickerColor={datePickerColor}
          widgetBg={widgetBg}
          roomBgColor={roomBgColor}
          roomTextColor={roomTextColor}
          roomHeadBg={roomHeadBg}
          roomHeadText={roomHeadText}
          roomContentBg={roomContentBg}
          roomContentText={roomContentText}
          buttonBgColor={buttonBgColor}
        />
      )}
      {data.widgetID === "2" && (
        <BookingWidget2Component
          propertyName={data.propertyDetails.propertyName}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          buttonColor={buttonColor}
          borderColor={borderColor}
          datePickerColor={datePickerColor}
          widgetBg={widgetBg}
          roomBgColor={roomBgColor}
          roomTextColor={roomTextColor}
          roomHeadBg={roomHeadBg}
          roomHeadText={roomHeadText}
          roomContentBg={roomContentBg}
          roomContentText={roomContentText}
          buttonBgColor={buttonBgColor}
        />
      )}
    </BookingWidgetWrapperComponentMain>
  );
};

export default BookingWidgetWrapperComponent;
