import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Link, NavLink } from "react-router-dom";
import media from "../../global/media";
import constant from "../../Template/Template4Pages/constant";
import { bookNowUrl } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { propertyImageURL } from "../../global/constants";
import {
  faBars,
  faTimes,
  faChevronDown,
  
} from "@fortawesome/free-solid-svg-icons";

const NavLayout5Wrapper = styled.div`
  transition: display 0.5ms ease-in-out;
  display: ${(prop) => prop.navigationToggle || "none"};
  background: ${constant.primaryColor};

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: ${constant.primaryColor};
  }
  ${(prop) =>
    prop.navigationToggle === "block"
      ? `.icon {
    display: none !important;
  } .hidden { display: block !important;}`
      : ``}
  ${media.md} {
    display: flex;
    transition: all 200ms ease-in;
    transform: none;
    height: auto;
    margin-top: 0px;
    width: 100%;
    max-width: 100%;
    &.stick {
      position: fixed;
      top: 0;
      width: 100%;
      background: #fff;
    }
    ${(prop) =>
      prop.navigationToggle === "block" &&
      prop.menuCount > 8 &&
      prop.navHeight > 50
        ? `
      
    `
        : ``}
    ${(prop) =>
      prop.navigationToggle === "block" && prop.menuCount > 8
        ? `
          .icon {
            display: flex !important;  
          }
          
        `
        : ``}
        
  }
  .icon {
    display: flex;
    font-size: 2em;
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      height: 40px;
      width: 1px;
      position: absolute;
      right: -7px;
      top: 0;
      margin-top: -10px;
      background: rgba(255, 255, 255, 0.1);
    }
  }
  ${(prop) =>
    prop.navigationToggle === "block"
      ? `.icon {
   &.fa-times { display: flex; } &.fa-bars { display: none !important; }
  }`
      : `.icon {
        &.fa-times { display: none; } &.fa-bars { display: flex;}
       }`}
`;
const NavBarWrapper = styled.div`
  padding: 15px;
  color: #fff;
  ${media.md} {
    display: flex;
    margin-top: 0;
    align-items: center;
  }
  img{
      height: 80px;
    }
  }
`;
const NavBarUl = styled.ul`
  list-style-type: none;
  margin: 0;
  background: ${constant.primaryColor};
  position: relative;
  display: none;
  ${media.md} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &.desktopPopupMenu {
    display: ${(prop) =>
      prop.navigationToggle === "block" ? "block" : "none"};
    position: absolute;
    z-index: 1;
    width: 100%;
    top: -8px;
    left: 0;
    height: 100vh;
    overflow-y: scroll;
    transition: all 0.5s ease-in-out;
    padding: 40px;
    margin-top: 100px;
    ${media.md} {
      > li {
        width: 100%;
      }
    }
  }
`;
const NavBarLi = styled.li`
  position: relative;
  .faIcon{
    position: absolute;
    right: 10px;
    
  }
  &.hidden {
    display: none;
  }
  ${media.md} {
    display: inline-block;
  }
  .sublinks {
    position: relative;
    &:after{
      position: absolute;
      right: 10px;
      content: ${faChevronDown};
    }
    
  }
  .nav-link {
    display: block;
    padding: 5px 10px 2px 10px;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
`;

const NavBarLinkBook = styled.a`
  padding: 10px 20px;
  color: #fff;
  position: absolute;
  top: 20px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  &:hover {
    background: ${constant.secondaryColor};
    border-color: ${constant.secondaryColor};
  }
  ${media.md} {
    margin-bottom: 0px;
    margin-left: 10%;
    position: relative;
    width: 18%;
    padding: 15px 20px;
    left: unset;
    top: unset;
  }
`;

const NavBarLiUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
  background: ${constant.primaryColor};
  width: 100%;
  display: none;
  &[data-index="${(prop) => prop.subMenuIndex}"] {
    display: block;
  } 
  bottom: auto !important;
  z-index: 1;
  position: relative;
`;
const NavBarLiUlLi = styled.li`
  padding: 0;
  ${media.md} {
    border-bottom: 1px solid ${constant.secondaryColor};
  }
  &:last-child {
    border-bottom: none;
  }
  .nav-link {
    padding: 10px;
  }
`;

const NavLayout4 = ({
  navigationSticky,
  navigationToggle,
  menuLinks,
  onNavClick,
  propertyId,
  propertyDetails,
}) => {
  let selectedMenuItem = "";
  menuLinks.map(
    (item) =>
      item.subLinks &&
      item.subLinks.map((item1) => {
        if (window.location.pathname === item1.menuSlugName) {
          selectedMenuItem = item.menuSlugName;
        }
      })
  );
  let navHeight = 0;
  useEffect(() => {
    function handleHeight(event) {
      navHeight = document.getElementById("navigation").offsetHeight;
    }
    document.addEventListener("load", handleHeight);
  });

  const [subMenuIndex, setSubMenuIndex] = useState(0);
  const subNav = (index) => {
    setSubMenuIndex(index);
  };
  console.log(navigationToggle);
  return (
    <NavLayout5Wrapper
      id="navigation"
      navigationToggle={navigationToggle}
      menuCount={menuLinks.length}
      navHeight={navHeight}
    >
      <Grid>
        <Row>
          <Col xs={12} md={12}>
            <NavBarWrapper className={navigationSticky ? "sticky" : ""}>
              <Link to="/">
                <img
                  src={propertyDetails.propertyLogo}
                  alt={propertyDetails.propertyName}
                />
              </Link>
              <NavBarUl navigationToggle={navigationToggle}>
                {menuLinks.slice(0, 6).map((item, index) => (
                  <NavBarLi key={index}>
                    <NavLink
                      activeClassName="active-link"
                      className={`nav-link ${
                        selectedMenuItem === item.websiteSlugName
                          ? "active-link"
                          : ""
                      }`}
                      to={item.menuSlugName}
                      exact
                    >
                      {item.menuLinkName}
                    </NavLink>
                  </NavBarLi>
                ))}
              </NavBarUl>
              <NavBarUl
                navigationToggle={navigationToggle}
                className= "desktopPopupMenu"
              >
                {menuLinks.map((item, index) => (
                  <NavBarLi key={index} className={index >= 6 ? "hidden" : ""}>
                    {item.subLinks ? (
                      <div class="sublinks">
                        <NavLink
                          onClick={() => subNav(index)}
                          activeClassName="active-link"
                          className={`nav-link parent-nav ${
                            selectedMenuItem === item.menuSlugName
                              ? "active-link"
                              : ""
                          }`}
                          to={item.menuSlugName}
                          exact
                        >
                          {item.menuLinkName}
                          {item.subLinks.length > 0 && (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className="faIcon"
                            />
                          )}
                          <NavBarLiUl
                            subMenuIndex={subMenuIndex}
                            data-index={index}
                          >
                            {item.subLinks.map((item1, index1) => (
                              <NavBarLiUlLi key={index1}>
                                <NavLink
                                  activeClassName="active-link"
                                  className="nav-link mobile-display"
                                  to={item1.websiteSlugName}
                                  exact
                                >
                                  {item1.websitetitle}
                                </NavLink>
                              </NavBarLiUlLi>
                            ))}
                          </NavBarLiUl>
                        </NavLink>
                      </div>
                    ) : (
                      <NavLink
                        activeClassName="active-link"
                        className={`nav-link ${
                          selectedMenuItem === item.websiteSlugName
                            ? "active-link"
                            : ""
                        }`}
                        to={item.menuSlugName}
                        exact
                      >
                        {item.menuLinkName}
                      </NavLink>
                    )}
                  </NavBarLi>
                ))}
              </NavBarUl>
              {menuLinks.length > 6 && (
                <NavBarWrapper onClick={() => onNavClick(false)}>
                  <FontAwesomeIcon icon={faBars} className="icon" />
                  <FontAwesomeIcon icon={faTimes} className="icon" />
                </NavBarWrapper>
              )}
              <NavBarLinkBook
                href={`${bookNowUrl}${propertyId}`}
                id="active-link"
              >
                Book Now
              </NavBarLinkBook>
            </NavBarWrapper>
          </Col>
        </Row>
      </Grid>
    </NavLayout5Wrapper>
  );
};

export default NavLayout4;
