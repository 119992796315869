import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from "react-router";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import PagesComponent from "./Template5Pages/PagesComponent";
import RoomTypeComponent from "./Template5Pages/RoomTypeComponent";
import SubItemPagesComponent from "./Template5Pages/SubItemPagesComponent";
import media from "../global/media";
import WatchMyRateWidget from "../Widgets/WatchMyRateWidget";

const HeaderWrapper = styled.div`
  position: relative;
  ${media.md} {
  }
  width: 100%;
  z-index: 100;
`;

const Template = ({ data }) => {
  const [navigationToggle, setNavigationToggle] = useState("none");
  const [navigationSticky, setNavigationSticky] = useState(false);
  const onNavClick = (value) => {
    setNavigationToggle(
      value ? "none" : navigationToggle === "none" ? "block" : "none"
    );
  };

  function handleScroll(event) {
    if (
      document.body.offsetWidth >= 992 ||
      document.documentElement.offsetWidth >= 992
    ) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("navigation").classList.add("sticky");
        document.getElementById("active-link").classList.add("displayBtn");
      } else {
        document.getElementById("navigation").classList.remove("sticky");
        document.getElementById("active-link").classList.remove("displayBtn");
      }
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    handleScroll();
  }, [navigationToggle]);
  //console.log(navigationToggle);
  return (
    <div>
      {data.watchMyRateWidget === "1" && (
        <WatchMyRateWidget propertyId={data.propertyDetails.propertyID} />
      )}
      <HeaderWrapper>
        <Header
          templateId={data.templateId}
          propertyDetails={data.propertyDetails}
          onNavClick={onNavClick}
        />
        <Navigation
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          templateId={data.templateId}
          navigationToggle={navigationToggle}
          menuLinks={data.menuLinks.menuLink}
          propertyId={data.propertyDetails.propertyID}
          propertyDetails={data.propertyDetails}
        />
      </HeaderWrapper>
      {data.menuLinks.menuLink.map((item, index) => {
        return (
          <Route
            exact
            key={index}
            path={item.menuSlugName}
            component={() => (
              <PagesComponent
                propertyName={data.propertyDetails.propertyName}
                data={item}
                templateData={data}
              />
            )}
          />
        );
      })}
      {data.roomTypes.roomType.map((item, index) => {
        const slug = item.RoomTypeName;
        return (
          <Route
            exact
            key={index}
            path={slug.slugName}
            component={() => (
              <RoomTypeComponent
                data={item}
                propertyId={data.propertyDetails.propertyID}
                templateData={data}
              />
            )}
          />
        );
      })}

      {data.multiPagesMain.mainPage &&
        data.multiPagesMain.mainPage.map((item1, index) => {
          return (
            item1.externalPages === "1" &&
            item1.multiPagesSub &&
            item1.multiPagesSub.subPage &&
            item1.multiPagesSub.subPage.map((item, subIndex) => {
              return (
                <Route
                  exact
                  key={subIndex}
                  path={item.websiteSlugName}
                  component={() => (
                    <SubItemPagesComponent data={item} templateData={data} />
                  )}
                />
              );
            })
          );
        })}

      <Footer
        templateId={data.templateId}
        contactInfo={data.propertyDetails.contactInfo}
        socialLinks={data.propertyDetails.propertySociallinks}
        menuLinks={data.menuLinks.menuLink}
        propertyDetails={data.propertyDetails}
        propertyImages={data.propertyImages}
      />
    </div>
  );
};

const TemplateWrapper = withRouter(Template);

const Website5Template = (props) => {
  return (
    <Router>
      <TemplateWrapper {...props} />
    </Router>
  );
};

export default Website5Template;
