import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constant from "../../Template/Template2Pages/constant";
import {  NavLink } from "react-router-dom";

library.add(fab);

const FooterLayout2Wrapper = styled.div`
  width: 100%;
  color: ${constant.primaryColor};
  padding: 2em 0;
  word-break: break-all;
  line-height: 1.8;
  h3 {
    margin-bottom: 20px;
  }
  a {
    color: ${constant.primaryColor};
    text-decoration: none;
    font-size:15px;
    transition:all 0.3s ease-in-out;
    padding:5px;

    &:hover{
      color: #c4c7cc;
      background-color:${constant.primaryColor};
      
    }
  }
`;

const SocialLink = styled.a`
  color: ${constant.primaryColor};
  border-radius: 5px;
  padding: 8px 12px;
  margin-right: 20px;
  border: solid 1px ${constant.primaryColor};
  &:hover{
    color: #c4c7cc;
    border: solid 1px #c4c7cc;
    
  }
`;

const NavBar = styled.div`
  display: block;
  padding-top: 30px;
  font-size: 12px;
  word-break: break-word;
  a{
    margin-left: 0.75rem;
    &:first-child{
      margin-left: 0;
    }
    &:after{
      content: "|";
      margin-left: .75rem;
    }
    &:last-child{
      &:after{
        content: "";
      }
    }
  }
`;

const FooterLayout2 = ({ contactInfo, socialLinks, menuLinks }) => (
  <FooterLayout2Wrapper>
    <Grid>
      <Row>
        <Col xs={12} md={5}>
          {socialLinks.facebook && (
            <SocialLink href={socialLinks.facebook}>
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </SocialLink>
          )}
          {socialLinks.linkedIn && (
            <SocialLink href={socialLinks.linkedIn}>
              <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
            </SocialLink>
          )}
          {socialLinks.tripadvisor && (
            <SocialLink href={socialLinks.tripadvisor}>
              <FontAwesomeIcon icon={["fab", "tripadvisor"]} />
            </SocialLink>
          )}
          <NavBar>
            {menuLinks.map((item, index) => (
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to={item.menuSlugName}
                exact
                >
                {item.menuLinkName}
              </NavLink>
            ))}
          </NavBar>
        </Col>
        <Col xs={12} md={5}>
          <h3>Contact us</h3>
          <p>
            {contactInfo.address.location}
            <br />
            {contactInfo.address.city}, {contactInfo.address.country}
          </p>
          <p>
            Telephone:
            <a href={`tel://${contactInfo.telephone.phone.replace(/\s/g, "")}`}>
              {contactInfo.telephone.phone}
            </a>
          </p>
          <p>
            Email:
            <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
          </p>
          <p>
            Web:
            <a href={contactInfo.website}>{contactInfo.website}</a>
          </p>
        </Col>
      </Row>
    </Grid>
  </FooterLayout2Wrapper>
);

export default FooterLayout2;
