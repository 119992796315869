import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import { sliderImageURL, noImageUrl } from "../../global/constants";
import constant from "./constant";
import { bookNowUrl } from "../../global/constants";
import { Link } from "react-router-dom";

const AccommodationImageWrap = styled.div`
  height: 100%;
  border: 1px solid #ccc;
  .card-img-top{
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  h3{
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
  }
  .accommodationBtn{
    width: 100%;
    text-align: center;
    margin-top: -30px;
  }
`;

const LinkWrapper = styled(Link)`
  padding: 15px 25px;
  margin: 10px 10px;
  color: #fff;
  position: relative;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border: 2px solid ${constant.primaryColor};
  border-radius: 50px;
  font-size: 16px;
  background: ${constant.primaryColor};
`;

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  return (
    <SectionWrapper className="margin">
      <h2>Accommodation</h2>
      <Grid>
        <Row>
          {roomTypes.map((value, index) => {
            return (
              <Col md={6} xs={12} className="cardCol">
                <AccommodationImageWrap>
                  {value.RoomTypeImages.Images.length > 0 ? (
                    <img src={sliderImageURL + value.RoomTypeImages.Images[0]}
                    class="card-img-top" alt=""/>
                  ) : (
                    <img src={noImageUrl} class="card-img-top" alt=""/>
                  )}
                  <div className="accommodationBtn">
                    <LinkWrapper to={value.RoomTypeName.slugName}>
                        Read More
                    </LinkWrapper>
                    <LinkWrapper to={`${bookNowUrl}${templateData.propertyDetails.propertyID}`}>
                        Book Now
                    </LinkWrapper>
                  </div>
                  <div className="card-body">
                    <h3>{value.RoomTypeName.section}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeURIComponent(
                        value.RoomTypeDescription
                        ),
                      }}
                    ></div>
                  </div>
                </AccommodationImageWrap>
              </Col>
              );
          })}{" "}
        </Row>
      </Grid>
    </SectionWrapper>
  );
};

export default AccommodationComponent;
