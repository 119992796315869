import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import constant from "./constant";

const LinkWrapper = styled.a`
  padding: 20px;
  margin: 15px 0;
  color: #fff;
  position: relative;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  display: block;
  background: ${constant.primaryColor};
`;

const AvailabilityWrapper = styled.div`
  display: inline-block;
  padding: 10px 8px;
  text-decoration: none;
  text-decoration: none;
  color: #0e3360;
  position: relative;
  transition: all 1s ease 0s;
  overflow: hidden;
  line-height: 1;
  background-color: #fff;
`;

const NormalLink = ({ to, text }) => (
  <LinkWrapper href={to} target="_blank">{text}</LinkWrapper>
);

const AvailabilityBtn = ({ to, text }) => (
  <AvailabilityWrapper to={to}>{text}</AvailabilityWrapper>
);

export { NormalLink, AvailabilityBtn };
