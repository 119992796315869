import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import { usePopper } from "react-popper";
// import constant from "../Template/Template8Pages/constant";

const CapacityWrapper = styled.div`
  position: relative;
  cursor: pointer;
  .roomsWrapperLabel {
    margin-top: 8px;
    color: #000;
  }
`;

const RoomBoxContainer = styled.div`
  font-weight: normal;

  .roomHead {
    background: #999;
    padding: 7px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    color: #fff;
    border-radius: 5px 5px 0 0;
    text-align: left;
    .float-right {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
    }
  }
  .inline {
    display: inline-block;
    width: 100%;
  }
  .padding {
    padding: 5px;
  }
  .btn-counter {
    border: 1px solid transparent;
    width: 25%;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
  .half-width {
    width: 50%;
  }
`;
const RoomBlock = styled.div`
  /* border: solid 1px #ccc; */
  width: 100%;
  padding: 7px;
`;

const RoomInnerBlock = styled.div`
  background: #eee;
  border-radius: 5px;
`;
const RoomBlockInside = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0 0 0;
  flex-wrap: wrap;
  margin: 0 7px 7px;
  .button-wrapper {
    margin-top: 5px;
    border: solid 1px #ccc;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 25px;
    border-radius: 5px;
    button {
      width: 22px;
      background: #999;
      font-weight: bold;
      border: 0;
      color: #fff;
      height: 25px;
      outline: none;
    }
    .btn-left {
      border-radius: 5px 0 0 5px;
    }
    .btn-right {
      border-radius: 0 5px 5px 0;
    }
    .values {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: center;
    }
  }
`;
const OutsideDivRefWrapper = styled.div``;

const PopperContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  text-align: center;
  z-index: 10;
  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -5px;
    &:after {
      content: " ";
      position: absolute;

      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^="top"] > .arrow {
    bottom: -5px;
    top: auto;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const CapacityComponent = ({
  roomBox,
  setRoomBox,
  room,
  setRooms,
  roomString,
  setRoomString,
  maxChild,
  maxAdult,
  AdultChildPermission,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const showRoomBox = () => {
    setRoomBox(!roomBox);
  };

  const increment = (key, value, label) => {
    if (
      room[key]["adult"] + room[key]["child"] <
      parseInt(maxChild) + parseInt(maxAdult)
    ) {
      room[key][label] = value + 1;
      setRooms((room) => [...room]);
      roomString[0][label] = roomString[0][label] + 1;
      setRoomString([...roomString]);

      // }elseif(room[key]["adult"] <= parseInt(maxAdult)) {
      //   room[key][label] = value + 1;
      //   setRooms((room) => [...room]);
      //   roomString[0][label] = roomString[0][label] + 1;
      //   setRoomString([...roomString]);
      // }elseif (room[key]["child"] <= parseInt(maxChild)){
      //   room[key][label] = value + 1;
      //   setRooms((room) => [...room]);
      //   roomString[0][label] = roomString[0][label] + 1;
      //   setRoomString([...roomString]);
    } else {
      alert("Not allowed max guest");
    }
  };

  const decrement = (key, value, label) => {
    if (room[key][label] > 0) {
      room[key][label] = value - 1;
      setRooms((room) => [...room]);
      roomString[0][label] = roomString[0][label] - 1;
      setRoomString([...roomString]);
    }
  };

  const createRoom = () => {
    room.push({ adult: 1, child: 0 });
    setRooms((room) => [...room]);
    roomString[0]["adult"] = roomString[0]["adult"] + 1;
    setRoomString([...roomString]);
  };

  const removeRoom = (index) => {
    roomString[0]["adult"] -= room[index]["adult"];
    roomString[0]["child"] -= room[index]["child"];
    room.splice(index, 1);
    setRooms((room) => [...room]);
    setRoomString([...roomString]);
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setRoomBox(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const roomItemContainer = (item, index) => {
    return (
      <RoomBlock>
        <RoomInnerBlock className="roomInnerBlock">
          <div className="roomHead">
            Room {index + 1}
            {index === 0 ? (
              <span className="float-right" onClick={createRoom}>
                +
              </span>
            ) : (
              <span className="float-right" onClick={() => removeRoom(index)}>
                -
              </span>
            )}
          </div>
          <div className="roomContent">
            <Row>
              <Col xs={6}>
                <RoomBlockInside>
                  <label>Adult</label>
                  <div className="button-wrapper">
                    <button
                      className="btn-left"
                      onClick={() => decrement(index, item.adult, "adult")}
                    >
                      -
                    </button>
                    <span className="values">{item.adult}</span>
                    <button
                      className="btn-right"
                      onClick={() => increment(index, item.adult, "adult")}
                    >
                      +
                    </button>
                  </div>
                </RoomBlockInside>
              </Col>
              <Col xs={6}>
                <RoomBlockInside>
                  <label>Child</label>
                  <div className="button-wrapper">
                    <button
                      className="btn-left"
                      onClick={() => decrement(index, item.child, "child")}
                    >
                      -
                    </button>
                    <span className="values">{item.child}</span>
                    <button
                      className="btn-right"
                      onClick={() => increment(index, item.child, "child")}
                    >
                      +
                    </button>
                  </div>
                </RoomBlockInside>
              </Col>
            </Row>
          </div>
          
        </RoomInnerBlock>
      </RoomBlock>
    );
  };

  return (
    <CapacityWrapper>
      <div
        onClick={showRoomBox}
        className="roomsWrapperLabel"
        ref={setReferenceElement}
      >
        {roomString[0].adult + " Adult "}
        {roomString[0].child + " Child"}
      </div>
      {roomBox && (
        <OutsideDivRefWrapper ref={wrapperRef}>
          <PopperContainer
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="popperContainer"
          >
            <RoomBoxContainer>
              {room.map((item, index) => roomItemContainer(item, index))}
            </RoomBoxContainer>

            <div ref={setArrowElement} style={styles.arrow} className="arrow" />
          </PopperContainer>
        </OutsideDivRefWrapper>
      )}
    </CapacityWrapper>
  );
};

export default CapacityComponent;
