import React, { useState } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "styled-components";
import { decodeContent } from "../../global/constants";

import media from "../../global/media";
import constant from "../Template7Pages/constant";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SectionWrapper } from "./templateConstants";
import { NavLink } from "react-router-dom";

const HomePageWelcomeComponentWrapper = styled.div`
  padding: 4em 0;
  text-align: center;

  p {
    font-size: 12px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: ${constant.bodyColor};
  }
  ${media.md} {
    padding: 4em 0;
  }
`;

const LinkWrapper = styled(NavLink)`
  padding: 5px 10px;
  color: #fff;
  position: relative;
  text-align: center;
  text-decoration: none;
  border: 2px solid ${constant.primaryColor};

  font-size: 13px;
  background: ${constant.primaryColor};
  &:first-child {
    background: none;
    color: ${constant.secondaryColor};
    border: 0;
    padding: 0;
    font-style: italic;
  }
`;

const contentLimit = 400;

const HomePageWelcomeComponent = ({
  propertyName,
  content,
  aboutUs,
}) => (
  <div>

    <HomePageWelcomeComponentWrapper>
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} md={12}>
              <h2>
                {propertyName}
                <span></span>
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    aboutUs === "1"
                      ? decodeContent(content.substring(0, contentLimit)) +
                        "..."
                      : decodeContent(content),
                }}
              ></p>
              <br />
              {aboutUs === "1" && (
                <LinkWrapper to={`/about-us/`}>Read more</LinkWrapper>
              )}
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </HomePageWelcomeComponentWrapper>
  </div>
);

export default HomePageWelcomeComponent;
