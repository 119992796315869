import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";

const CommonComponent = ({ children, bgColor, textColor }) => (
  <SectionWrapper className="margin " bgColor={bgColor} textColor={textColor}>
    <Grid>
      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </Grid>
  </SectionWrapper>
);
export default CommonComponent;
