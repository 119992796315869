import React, { useContext } from "react";
import styled from "styled-components";
// import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import media from "../../global/media";
import { propertyImageURL } from "../../global/constants";
import { WidgetOpen } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import constant from "../../Template/Template7Pages/constant";
import LanguageTranslatComponent from "../../global/LanguageTranslatComponent";

const HeaderLayout7Wrapper = styled.div`
  width: 100%;
  color: #eee;
  display: flex;
  justify-content: space-between;
  background: transparent;
  position: fixed;
  transition: 0.5s all ease-in-out;
  padding: 25px 15px;
  align-items: start;
  .inside-wrapper {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .logo {
    position: absolute;
    width: ${(prop) => (prop.width ? prop.width / 2 + "px" : "auto")};
    height: ${(prop) => (prop.height ? prop.height / 2 + "px" : "auto")};
    ${media.sm} {
      width: ${(prop) => (prop.width ? prop.width + "px" : "auto")};
      height: ${(prop) => (prop.height ? prop.height + "px" : "auto")};
    }
  }

  &.sticky {
    background: #ffffff;
  }
  &.shiftHeader {
    width: calc(100% - 250px);
  }
`;

const NavbarLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70%;
  align-items: center;
`;

const NavBarLinkBook = styled.button`
  position: relative;
  background: transparent;
  margin: 0 10px;
  padding: 11px 25px;
  display: block;
  text-align: center;
  border: solid 1px #000;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  background: #000;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: #fff;
    border-color: #fff;
    color: #919191;
  }
`;
const NavBarWrapper = styled.div`
  font-size: 1.1em;
  background: #000;
  padding: 12px;
  cursor: pointer;
  display: inline-block;
  transition: all 0.5ms ease-in;
  color: #fff;
  display: flex;
  align-items: center;
  ${media.sm} {
    padding: 12px;
  }
  .icon {
    color: ${constant.secondaryColor};
    font-weight: normal;

    &.faBars {
      display: ${(prop) =>
        prop.navigationToggle === "none" ? "block" : "none"};
    }
    &.faTimes {
      display: ${(prop) =>
        prop.navigationToggle === "block" ? "block" : "none"};
    }
  }
`;

const HeaderLayout7 = ({
  propertyDetails,
  colorConfiguration,
  onNavClick,
  navigationToggle,
}) => {
  return (
    <HeaderLayout7Wrapper
      id="headerWrapper"
      headerColor={colorConfiguration.headercolor}
      width={propertyDetails.propertyLogoWidth || ""}
      height={propertyDetails.propertyLogoHeight || ""}
    >
      <Link to="/" onClick={() => window.scrollTo(0, 0)}>
        <img
          className="logo"
          src={propertyImageURL + propertyDetails.propertyLogo}
          alt={propertyDetails.propertyName}
        />
      </Link>
      <div className="inside-wrapper">
        <NavbarLinks>
          <NavBarLinkBook id="active-link" onClick={() => WidgetOpen(true)}>
            Book Now
          </NavBarLinkBook>
          <div className="langDbBtn-7">
            <LanguageTranslatComponent></LanguageTranslatComponent>
          </div>

          <NavBarWrapper
            onClick={() => onNavClick(false, false)}
            navigationToggle={navigationToggle}
          >
            <FontAwesomeIcon icon={faBars} className="icon faBars" />
            <FontAwesomeIcon icon={faTimes} className="icon faTimes" />
          </NavBarWrapper>
        </NavbarLinks>
      </div>
    </HeaderLayout7Wrapper>
  );
};

export default HeaderLayout7;
