import styled from "styled-components";
import media from "../../global/media";
import constant from "./constant";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/fontawesome-free-solid";

const SectionWrapper = styled.div`
  background: ${(prop) => prop.bgColor || "#191919"};
  padding: ${(prop) => prop.mobilePadding || "4em 0"};
  ${(prop) =>
    prop.bgImage
      ? `background: url(${prop.bgImage}); background-size: cover; background-position: center center;`
      : ``}
  position: relative;
  scroll-snap-type: y mandatory;
  color: ${(prop) => prop.textColor || constant.bodyColor};
  .minheight {
    min-height: 60vh;
  }
  &.noTopPadding {
    padding-top: 0;
  }
  ${media.sm} {
    padding: ${(prop) => prop.padding || "8em 0"};
  }
  /* .section-padding {
    padding: 3em 1.5em;
    ${media.sm} {
      padding: 4em 8em;
    }
    ${media.md} {
      padding: 4em 10em;
    }
  } */
  a {
    text-decoration: none;
    cursor: pointer;
    color: ${constant.primaryColor};
    font-weight: normal;
    transition: all 0.5s ease-in-out;
    text-decoration: underline;
    &:hover {
      color: ${constant.primaryColor};
      text-decoration: none;
    }
  }

  ul.facilities {
    padding: 0;
    display: flex;
    list-style-type: none;
    width: 100%;
    flex-wrap: wrap;
    li {
      width: 100%;
      font-size: 16px;
      color: ${(prop) => prop.textColor || constant.bodyColor};
      ${media.sm} {
        width: 50%;
      }
      ${media.md} {
        width: 33%;
      }
      svg {
        color: ${(prop) => prop.textColor || constant.bodyColor};
        margin-right: 10px;
      }
    }

    &.room-specification {
      padding: 0;
      ${media.md} {
        padding-left: 40px;
      }
      li {
        width: 100%;
        ${media.md} {
          width: 100%;
        }
      }
    }
  }
  ul.inside-roomType {
    li {
      width: 100%;
    }
  }
  &.margin {
    ${media.md} {
    }
    line-height: 2;
    ul {
    }
  }

  h2 {
    text-align: left;
    font-size: 2em;
    line-height: 1;
    margin-top: 0;
    font-weight: 300;
    /* padding-top: 20px; */
    color: ${(prop) => prop.textColor || constant.bodyColor};
    margin-bottom: 1rem;
    ${media.sm} {
      font-size: 3em;
    }
  }
  p {
    font-size: 15px;
    text-align: left;
    line-height: 1.8;
    letter-spacing: 0.1px;
    color: ${(prop) => prop.textColor || constant.bodyColor};
    /* padding: 10px 20px; */
    font-weight: 300;
    margin-bottom: 1rem;
  }
  h3 {
    /* margin-top:10px; */
    color: ${(prop) => prop.textColor || constant.bodyColor};

    margin-bottom: 10px;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 1.4em;
    text-transform: uppercase;
    /* padding:5px 10px; */
    ${media.sm} {
      line-height: 1.6em;
      font-size: 18px;
      /* padding:0 10px; */
    }
  }
  .heading-link {
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.sm} {
      justify-content: flex-end;
    }
  }
  .sub-headline {
    text-align: center;
  }
  .block-heading {
    text-align: center;
    span {
      font-size: 1.5rem;
      color: ${constant.secondaryColor};
      font-weight: 500;
    }
    h2 {
      font-size: 1.5em;
      margin-top: 0px;
    }
    ${media.md} {
      text-align: center;
      span {
        font-size: 2.5rem;
        color: ${constant.secondaryColor};
        font-weight: 500;
      }
      h2 {
        font-size: 2em;
      }
    }
  }
  .image-fullWidth {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &.noPadding {
    padding: 0;
  }
  .cardCol {
    margin: 20px 0px;
    transition: translateY 5s ease-in;
  }
  &.promotion {
    display: block;
    width: 100%;
    ${media.md} {
      display: flex;
    }
  }

  .contactBlock {
    border: 1px solid #ccc;
    h2 {
      font-size: 16px;
    }
    .email {
      font-size: 16px;
      font-weight: 600;
      padding: 10px 0px;
    }
    .icon-settings,
    .heading {
      color: ${constant.primaryColor};
    }
  }
  .checked {
    color: #919191;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
  .roomTypeContent {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 2;
    text-transform: none;
    margin-bottom: 15px;
    margin-top: 20px;
    /* padding:0 10px; */
    ${media.sm} {
      margin-top: 0;
    }
    strong {
      font-weight: normal;
    }
    h4 {
      text-transform: uppercase;
      font-weight: 500;
      padding-bottom: 10px;
      letter-spacing: 0.15em;
      line-height: 1.6em;
    }
  }
  .LeftMargin {
    margin-left: 0;
    ${media.sm} {
      margin-left: calc(10%);
    }
  }
  .marginTop {
    margin-top: 30px;
  }
  .iconColor {
    color: #646464;
  }
  ul {
    color: ${constant.bodyColor};
    padding-left: 20px;
    /* list-style-type: none; */
  }

  li {
    color: ${constant.primaryColor};
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .content-desc {
    a {
      color: ${constant.primaryColor};
    }
  }
`;

export { SectionWrapper };
