import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderImageURL } from "../global/constants";
// import constant from "../Template/Template4Pages/constant";
import media from "../global/media";

const SliderWrapper = styled.div`
  height: 80vh;
  background-color: rgba(98, 98, 98, 0.4);
  opacity: 1;
  transition: opacity 2000s ease-in-out;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    display: none !important;
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58,49,56, .8);
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: rgb(135,27,42, .8);
    }
  }
  .slick-prev {
    left: 60px;
  }
  .slick-next {
    right: 60px;
  }
  &:hover{
    .slick-arrow{
      display: block !important;
      animation: fadein 1s;
      @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
      }
    }
  }
  .slick-prev:before, .slick-next:before{
    font-family: none;
  }
`;
const SlideImage = styled.div`
  background-image: url(${(prop) => prop.bgimage});
  background-size: cover;
  background-position: center;
  height: 80vh;
  position: relative;
  img {
    width: 100%;
  }
`;
const Caption = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  color: #fff;
  font-size: 2em;
  ${media.md} {
    top: 30%;
  }
`;

const InnerLayer = styled.div`
    background: rgba(15,22,42,.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const HomePageSlider4 = ({ sliderImages, sliderLabel, sliderDesc }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    speed: 2000,
    cssEase: 'linear'
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {sliderImages.sliderImage.map((item, index) => (
          <SlideImage bgimage={sliderImageURL + item}>
            <InnerLayer>
              {sliderImages.sliderLabel[index] && (
                <Caption>
                  <h2>ABC{sliderImages.sliderLabel[index]}</h2>
                  <p>NZS{sliderImages.sliderDesc[index]}</p>
                </Caption>
              )}
            </InnerLayer>
          </SlideImage>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default HomePageSlider4;
