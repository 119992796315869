import React, {  useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Link, NavLink } from "react-router-dom";
import media from "../../global/media";
import constant from "../../Template/Template1Pages/constant";
import { bookNowUrl } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const NavLayout1Wrapper = styled.div`
  transition: display 0.5ms ease-in-out;
  display: ${(prop) => prop.navigationToggle || "none"};
  background: #fff;
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
  }
  ${(prop) =>
    prop.navigationToggle === "block"
      ? `.icon {
    display: none !important;
  } .hidden { display: inline-block !important;}`
      : ``}
  ${media.md} {
    display: flex;
    transition: all 200ms ease-in;
    transform: none;
    &.stick {
      position: fixed;
      top: 0;
      width: 100%;
      background: #fff;
    }
    ${(prop) =>
      prop.navigationToggle === "block" &&
      prop.menuCount > 8 &&
      prop.navHeight > 50
        ? `
    
      ul {
        bottom: -50px;
      }
    `
        : ``}
    ${(prop) =>
      prop.navigationToggle === "block" && prop.menuCount > 8
        ? `
          .icon {
            display: flex !important;  
          }
          
        `
        : ``}
  }
  .icon {
    display: flex;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
  }
`;
const NavBarWrapper = styled.div`
  ${media.md} {
    display: flex;
    margin-top: 0;

    height: 50px;

    align-items: flex-end;
  }
`;
const NavBarUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: #fff;

  position: relative;
  ${media.md} {
    width: 100%;
  }
  ${media.md} {
  }
`;
const NavBarLi = styled(Link)`
  position: relative;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  &:hover {
    > ul {
      display: block;
    }
  }
  &.hidden {
    display: none;
  }
  ${media.md} {
    display: inline-block;
  }
  .sublinks {
    position: relative;
    &:hover {
      > .nav-link {
        background: #616161;
        color: #fff;
      }
      ul {
        display: block;
      }
    }
  }
  .nav-link {
    display: block;
    padding: 15px 10px;
    text-decoration: none;
    color: #666;
    font-size: 14px;
    &:hover,
    &.active-link {
      background: ${constant.primaryColor};
      color: #fff;
    }
  }
`;
const NavBarLinkBook = styled.a`
  display: none;
  ${media.md} {
    display: inline-block;
  }
  padding: 15px 20px;
  background: #f08c36;
  color: #fff;
  position: relative;
  text-align: center;
  width: 18%;
  text-decoration: none;
  margin-left: 3%;
`;

const NavBarLiUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  background: #eee;
  width: 200px;
  display: none;
  bottom: auto !important;
  z-index: 1;
`;
const NavBarLiUlLi = styled.li`
  padding: 0;

  .nav-link {
    padding: 10px;
    &:hover {
      background: #f08c36;
    }
    &.active-link {
      @include parent-append("a :hover", 3) {
        background: #000;
        color: #fff;
      }
    }
  }
`;

const NavLayout1 = ({
  navigationSticky,
  navigationToggle,
  menuLinks,
  onNavClick,
  propertyId,
}) => {
  let selectedMenuItem = "";
  menuLinks.map(
    (item) =>
      item.subLinks &&
      item.subLinks.map((item1) => {
        if (window.location.pathname === item1.menuSlugName) {
          selectedMenuItem = item.menuSlugName;
        }
      })
  );
  let navHeight = 0;
  useEffect(() => {
    function handleHeight(event) {
      navHeight = document.getElementById("navigation").offsetHeight;
    }
    document.addEventListener("load", handleHeight);
  });

  return (
    <NavLayout1Wrapper
      id="navigation"
      navigationToggle={navigationToggle}
      menuCount={menuLinks.length}
      navHeight={navHeight}
    >
      <Grid>
        <Row>
          <Col xs={12} md={12}>
            <NavBarWrapper className={navigationSticky ? "stick" : ""}>
              <NavBarUl>
                {menuLinks.map((item, index) => (
                  <NavBarLi key={index} className={index >= 8 ? "hidden" : ""}>
                    {item.subLinks ? (
                      <div class="sublinks">
                        <NavLink
                          onClick={() => onNavClick(true)}
                          activeClassName="active-link"
                          className={`nav-link ${
                            selectedMenuItem === item.menuSlugName
                              ? "active-link"
                              : ""
                          }`}
                          to={item.menuSlugName}
                          exact
                        >
                          {item.menuLinkName}
                        </NavLink>
                        <NavBarLiUl>
                          {item.subLinks.map((item1, index1) => (
                            <NavBarLiUlLi key={index1}>
                              <NavLink
                                onClick={() => onNavClick(true)}
                                activeClassName="active-link"
                                className="nav-link"
                                to={item1.websiteSlugName}
                                exact
                              >
                                {item1.websitetitle}
                              </NavLink>
                            </NavBarLiUlLi>
                          ))}
                        </NavBarLiUl>
                      </div>
                    ) : (
                      <NavLink
                        onClick={() => onNavClick(true)}
                        activeClassName="active-link"
                        className={`nav-link ${
                          selectedMenuItem === item.websiteSlugName
                            ? "active-link"
                            : ""
                        }`}
                        to={item.menuSlugName}
                        exact
                      >
                        {item.menuLinkName}
                      </NavLink>
                    )}
                  </NavBarLi>
                ))}
              </NavBarUl>
              {menuLinks.length > 8 && (
                <NavBarWrapper onClick={() => onNavClick(false)}>
                  <FontAwesomeIcon icon={faBars} className="icon" />
                </NavBarWrapper>
              )}
              <NavBarLinkBook href={`${bookNowUrl}${propertyId}`}>
                Book Now
              </NavBarLinkBook>
            </NavBarWrapper>
          </Col>
        </Row>
      </Grid>
    </NavLayout1Wrapper>
  );
};

export default NavLayout1;
