import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = ({ metaTitle, metaDesc, metaKeywords, propertyFavicon }) => (
  <Helmet>
    {metaTitle && <title>{`${metaTitle}`}</title>}
    {metaDesc && <meta name="description" content={metaDesc} />}
    {metaKeywords && <meta name="keywords" content={metaKeywords} />}
    <link rel="icon" type="image/png" href={propertyFavicon}></link>
  </Helmet>
);

export default MetaTags;
