import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import HomePageWelcomeComponent from "./HomePageWelcomeComponent";
import { propertyImageURL, noImageUrl } from "../../global/constants";
import { SectionWrapper } from "./templateConstants";
import media from "../../global/media";
import { fetchReviews } from "../../providers/api";
import constant from "./constant";
import { NormalLink } from "./Links";
import { formatDate1, calculateRating } from "../../global/functions";

const AccommodationImageWrap = styled.a`
  height: ${(prop) => prop.mobileHeight || prop.height};
  ${media.sm} {
    height: ${(prop) => prop.height};
  }
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  position: relative;
  display: block;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  h3 {
    position: absolute;
    bottom: 0;
    font-size: 1em;
    text-transform: uppercase;
    padding: 20px 0;
    background: rgba(255,255,255,0.8);
    color: #000;
    width: 100%;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
  &:hover {
  }
  .reviewLink
`;

const ReviewBlock = styled.div`
  display: block;
  background: #f1f1f1;
  margin: 10px 0;
  padding: 20px;
  border-bottom: solid 1px #999;
  border-radius: 5px;
  text-align: center;
  position: relative;
`;
const ReviewTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;
const ReviewLabel = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
`;
const Rating = styled.div`
  margin-bottom: 20px;
`;
const ReviewName = styled.div`
  margin-bottom: 20px;
  font-style: italic;
`;
const ChannelName = styled.div``;

const ReviewWrapper = styled.div``;

const HomePageComponent = ({ templateData, maxGuestCapacity }) => {
  const roomTypes = templateData.roomTypes.roomType;

  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState([]);

  function getReviews(propertyID) {
    fetchReviews(propertyID).then((data) => {
      setResultData(data && data.resultValue ? data.resultValue : []);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (templateData.reviewMinderWidget === "1") {
      getReviews(templateData.propertyDetails.propertyID);
    }
  });

  return (
    <div>
      <HomePageWelcomeComponent
        propertyName={templateData.propertyDetails.propertyName}
        content={templateData.propertyDetails.propertyDescription}
        image={templateData.propertyImages.image[0]}
        maxGuestCapacity={maxGuestCapacity}
        propertyId={templateData.propertyDetails.propertyID}
      />
      <SectionWrapper bgColor={constant.greyColor}>
        <Grid>
          <Row>
            <Col xs={12} sm={6}>
              <h2 className="left">Accommodation</h2>
            </Col>
            <Col xs={12} sm={6} className="heading-link">
              <NormalLink to="/accommodation/" text="View all rooms" />
            </Col>
          </Row>
          {roomTypes.length === 3 ? (
            <Row top="xs">
              <Col xs={12} md={6}>
                <AccommodationImageWrap height="620px" mobileHeight="300px">
                  {roomTypes[0].RoomTypeImages.Images.length > 0 ? (
                    <img
                      src={
                        propertyImageURL + roomTypes[1].RoomTypeImages.Images[0]
                      } alt=""
                    />
                  ) : (
                    <img src={noImageUrl} alt=""/>
                  )}
                  <h3>{roomTypes[0].RoomTypeName.section}</h3>
                </AccommodationImageWrap>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12}>
                    <AccommodationImageWrap height="300px">
                      {roomTypes[1].RoomTypeImages.Images.length > 0 ? (
                        <img
                          src={
                            propertyImageURL +
                            roomTypes[1].RoomTypeImages.Images[0]
                          }
                          alt=""
                        />
                      ) : (
                        <img src={noImageUrl} alt=""/>
                      )}
                      <h3>{roomTypes[1].RoomTypeName.section}</h3>
                    </AccommodationImageWrap>
                  </Col>
                  <Col xs={12}>
                    <AccommodationImageWrap height="300px">
                      {roomTypes[2].RoomTypeImages.Images.length > 0 ? (
                        <img
                          src={
                            propertyImageURL +
                            roomTypes[2].RoomTypeImages.Images[0]
                          }
                          alt=""
                        />
                      ) : (
                        <img src={noImageUrl} alt=""/>
                      )}

                      <h3>{roomTypes[2].RoomTypeName.section}</h3>
                    </AccommodationImageWrap>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row top="xs">
              {roomTypes.map((item, index) => (
                <Col xs={12} md={6} key={index}>
                  <AccommodationImageWrap
                    href={item.RoomTypeName.slugName}
                    height="300px"
                  >
                    {item.RoomTypeImages.Images.length > 0 ? (
                      <img src={item.RoomTypeImages.Images[0]} alt=""/>
                    ) : (
                      <img src={noImageUrl} alt=""/>
                    )}
                    <h3>{item.RoomTypeName.section}</h3>
                  </AccommodationImageWrap>
                </Col>
              ))}
            </Row>
          )}
        </Grid>
      </SectionWrapper>
      {templateData.reviewMinderWidget === "1" && (
        <SectionWrapper>
          <Grid>
            <Row>
              <Col xs={12} sm={6}>
                <h2 className="left">OUR GUEST LOVE US</h2>
              </Col>
              <Col xs={12} sm={6} className="heading-link">
                <NormalLink to="/review/" text="View all reviews" />
              </Col>
            </Row>
            <ReviewWrapper>
              <Row>
                {!loading ? (
                  resultData &&
                  resultData.reviewlist &&
                  resultData.reviewlist.slice(0, 4).map((item, index) => (
                    <Col xs={12} md={6} key={index}>
                      <ReviewBlock key={index}>
                        <ReviewTitle>{item.review_title}</ReviewTitle>
                        <ReviewLabel
                          dangerouslySetInnerHTML={{
                            __html: item.your_view,
                          }}
                        ></ReviewLabel>
                        <Rating>
                          {calculateRating(
                            item.rating_overall,
                            constant.primaryColor
                          )}
                        </Rating>
                        <ReviewName>
                          {item.guest_name} ({formatDate1(item.review_time)})
                        </ReviewName>
                        <ChannelName>
                          Review from: {item.channel_name}
                        </ChannelName>
                      </ReviewBlock>
                    </Col>
                  ))
                ) : (
                  <Col xs={12}>Loading...</Col>
                )}
              </Row>
            </ReviewWrapper>
          </Grid>
        </SectionWrapper>
      )}
    </div>
  );
};

export default HomePageComponent;
