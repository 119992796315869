import React, { useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderImageURL } from "../global/constants";
import constant from "../Template/Template7Pages/constant";
import media from "../global/media";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const SliderWrapper = styled.div`
  background-image: url("${(prop) => prop.bgimage}");
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
  /* background-color: rgba(98, 98, 98, 0.4); */
  opacity: 1;
  transition: opacity 2000s ease-in-out;
  overflow: hidden;
  #sliderWrapper {
    visibility: hidden;
    opacity: 0;
    &.slider-show {
      transition: visibility 1s ease-in;
      visibility: visible;
      opacity: 1;
    }
  }

  ${media.sm} {
    height: 70vh;
  }
  ${media.md} {
    height: 100vh;
  }
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    opacity: 1 !important;
    transition: 0.5s all ease-in-out;
  }
  .slick-prev {
    top: 52%;
    left: 10%;

    /* ${media.md}{
    top:50%;

    } */
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      background: #000;
      border-radius: 50%;
      box-shadow: 0 0 10px #000;
      opacity: 1;
      font-size: 36px;
    }
  }
  .slick-next {
    top: 50%;
    right: 10%;

    /* ${media.md}{
    top:50%;
    } */

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      background: #000;
      border-radius: 50%;
      box-shadow: 0 0 10px #000;
      opacity: 1;
      font-size: 36px;
    }
  }
`;
const SlideImage = styled.div`
  background-image: url("${(prop) => prop.bgimage}");
  background-size: cover;
  background-position: center;
  height: 50vh;
  position: relative;
  ${media.sm} {
    height: 70vh;
  }
  ${media.md} {
    height: 100vh;
  }
  img {
    width: 100%;
  }
`;
const Caption = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1.4em;
  h2 {
    font-weight: normal;
  }
`;

const InnerLayer = styled.div`
  background: rgba(15, 22, 42, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HomePageSlider = ({ sliderImages, sliderLabel, sliderDesc }) => {
  const settings = {
    dots: false,
    infinite: true,

    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    speed: 500,
    cssEase: "linear",
    arrow: false,
  };

  return (
    <SliderWrapper bgimage={sliderImages.sliderImage[0]}>
      <div id="sliderWrapper">
        <Slider {...settings}>
          {sliderImages.sliderImage.map((item, index) => (
            <SlideImage bgimage={sliderImageURL + item} key={index}>
              <InnerLayer>
                <Caption>
                  {sliderImages.sliderLabel[index] && (
                    <h2>{sliderImages.sliderLabel[index]}</h2>
                  )}
                  {sliderImages.sliderDesc[index] && (
                    <p>{sliderImages.sliderDesc[index]}</p>
                  )}
                </Caption>
              </InnerLayer>
            </SlideImage>
          ))}
        </Slider>
      </div>
    </SliderWrapper>
  );
};

export default HomePageSlider;
