import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "../Template7Pages/templateConstants";
import { noImageUrl } from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import constant from "../Template6Pages/constant";
import { decodeContent } from "../../global/constants";
import "slick-carousel/slick/slick-theme.css";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

const SubItemPagesComponentWrapper = styled.div`
  padding: 3em 0;
`;

const SliderWrapper = styled.div`
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58, 49, 56, 0.8);
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    left: 20px;
    top: 40%;
  }
  .slick-next {
    right: 20px;
    top: 40%;
  }
`;

const SlideImage = styled.div`
  height: 300px;
  img {
    width: 100%;
  }
`;

const HeaderImage = styled.div`
  height: 200px;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
`;
const NoHeaderImage = styled.div`
  height: 100px;
`;
const SubItemPagesComponent = ({ data, templateData, menuFound }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <SubItemPagesComponentWrapper>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.websitetitle}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.headerImage ? (
        <HeaderImage bgImage={data.headerImage} />
      ) : (
        <NoHeaderImage />
      )}
      <BreadCrumbComponent  
        currentUrl = {data.websitetitle}
        previousUrl = {menuFound.menuSlugName}
        previousName = {menuFound.menuLinkName}
      />
      <SectionWrapper className="margin">
        <Grid>
          <Row>
            {data.websitepageImagesSub &&
              data.websitepageImagesSub.MPImagesSub.length > 0 && (
                <Col xs={12} md={6}>
                  <SliderWrapper>
                    <Slider {...settings}>
                      {data.websitepageImagesSub.MPImagesSub.map(
                        (item, index) => (
                          <SlideImage>
                            <img src={item} alt="" />
                          </SlideImage>
                        )
                      )}
                    </Slider>
                  </SliderWrapper>
                </Col>
              )}

            <Col
              xs={12}
              md={
                data.websitepageImagesSub &&
                data.websitepageImagesSub.MPImagesSub.length > 0
                  ? 6
                  : 12
              }
            >
              <h2>
                {data.websitetitle}

                <span></span>
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeContent(data.websitepagecontent),
                }}
              ></div>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </SubItemPagesComponentWrapper>
  );
};

export default SubItemPagesComponent;
