import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import media from "../../global/media";
import constant from "../Template9Pages/constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
  bookNowUrl,
  bookRoomUrl,
} from "../../global/constants";
import {SliderButtonWrapper, NextButton, PrevButton} from "./Links";
const SliderWrapperMain = styled.section`
  position: relative;
  background: #191919;
  ${media.md} {
    padding: 8em 0;
  }
  .slick-slide {
    top: 0 !important;
  }
  .slick-dots {
    top: 58%;
    right: 50%;
    width: fit-content;
    height: fit-content;
    display: none !important;
    /* transform: translate(-50%,-50%); */
    ${media.sm} {
    display: flex !important;

      top: 55%;
      right: 50%;
    }
    ${media.md} {
      top: 5%;
      right: 45%;
    }
  }
  .slick-dots li button:before {
    height: 8px;
    width: 8px;
    margin: 8px 8px;
    float: left;
    border-radius: 50%;
    border: 1px solid #b1b1b1;
    transition: all 0.2s ease-in-out;
    color: transparent;
    opacity: 1;
  }
  .slick-dots .slick-active button:before {
    height: 16px;
    width: 16px;
    margin: 4px;
    border-radius: 50%;
    border: 1px solid #fff;
  }
  .slick-arrow{
    display:none;
  }
  @keyframes openClose {
    0% {
      transform: scaleX(1);
    }

    100% {
      transform: scaleX(0);
    }
  }
  @keyframes bottomTop {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  .slick-active .afterLeftChange {
    transform-origin: center right;
    animation: openClose 1s ease-in-out;
    animation-fill-mode: forwards;
  }
  .slick-active .afterRightChange {
    transform-origin: center left;
    animation: openClose 1s ease-in-out;
    animation-fill-mode: forwards;
  }
  .slick-active .afterContentChange {
    animation: bottomTop 1s ease-in-out;
    animation-fill-mode: forwards;
  }
  .slick-active .sliderWrapper {
    display: flex;
  }
 
`;
const SliderWrapper = styled.div`
  flex-flow: row wrap;
  position: relative;
  padding: 0 5px;
  display: none;
  width: 100%;

`;

const Background = styled.div`
  position: absolute;
  background: #000;
  height: 80%;
  width: 100%;
  top: 0;
  left: 0%;
  z-index: 0;
  display: block;
  ${media.md} {
    display: none;
  }
`;
const InsideSliderWrapper = styled.div`
  width: 100%;
  flex-flow: wrap;
  position: relative;
  display: flex;
`;

const ContentBlock = styled.div`
  position: relative;
  width: 100%;
  word-break: normal;
  margin-bottom: 2em;
  padding: 0 1.5em;
  padding-top: 4em;
  ${media.sm} {
    margin-bottom: 4em;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
  }
  ${media.md} {
    padding-left: 3em;
    padding-right: 3em;
    width: 33.33%;
  }
  &:after {
    content: "";
    position: absolute;
    background: #000;
    z-index: -5;
    height: 130%;
    width: calc(130%);
    top: -2em;
    left: -15%;

    ${media.md} {
      z-index: -1;
      top: -4em;
      left: 0;
      height: 130%;
      width: 200%;
    }
  }
`;
const InnerContentWrapper = styled.div`
  position: relative;
  color: #fff;
  opacity: 1;
  padding-top: 10px;
  ${media.md} {
    padding-top: 20px;
  }
`;

const HeadingWrapper = styled.div`
  h4 {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.4;
    color: #fff;
    font-size: 1.2rem;
  }
  h2 {
    text-align: left;
    font-size: 35px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 1rem;
    ${media.sm} {
      font-size: 30px;
    }
  }
`;
const ParagraphWrapper = styled.div`
  p {
    margin-bottom: 1rem;
    font-size: 15px;
    line-height: 1.8;
  }
`;
const BookButton = styled.a`
  background: ${constant.goldColor};
  outline: 0;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  margin: 1rem 0 1rem 0;
  padding: 1em 2em;
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  :hover {
    background: #000;
    color: #fff;
  }
`;

const FirstImageBlock = styled.div`
  position: relative;
  z-index: 1;
  width: 50%;
  min-height: 200px;

  ${media.sm} {
    max-height: 350px;
  }
  ${media.md} {
    width: 33.33%;
  }
`;
const SecondImageBlock = styled.div`
  position: relative;
  width: 50%;
  min-height: 200px;

  ${media.sm} {
    max-height: 350px;
  }
  ${media.md} {
    width: 33.33%;
  }
`;

const InnerFirstBlock = styled.div`
  position: relative;
  float: right;
  width: 100%;
  overflow: hidden;
  img {
    margin-left: 0px;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    border-style: none;
  }
  .emptyLeftBlock {
    margin: 1em;
    height: 25px;
    position: relative;
    float: right;
    ${media.sm} {
      height: 45px;
    }
  }
  .coverLeft {
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    transform: scaleX(0);
    background: #000;
    right: 0;
    top: 0;
    z-index: 2;
  }
`;

const InnerSecondBlock = styled.div`
  position: relative;
  float: right;
  width: 100%;
  overflow: hidden;
  img {
    margin-left: 0px;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    border-style: none;
  }

  .coverRight {
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    transform: scaleX(0);
    background: #000;
    right: 0;
    top: 0;
    z-index: 2;
  }
  .emptyRightBlock {
    height: 25px;
  }
`;

const LinkWrapper = styled.a`
  color: ${constant.greyColor};
  font-size: 15px;
  background: #000;
  text-transform: capitalize;
  text-decoration: none;
  padding: 10px 0;
  &:hover {
    color: #fff;
  }
`;


const SliderCardComponent = ({ roomTypes, mainSlugName }) => {

  const customSlider = React.createRef();
  
  const gotoNext = () => {
    customSlider.current.slickNext()
  }

  const gotoPrev = () => {
    customSlider.current.slickPrev()
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    slidesToScroll: 1,
    fade: true,
    speed: 200,
    cssEase: "linear",
    arrows: false,
    adaptiveHeight: true,
    // adaptiveWidth: true,
    vertical: true,
    verticalSwiping: true,
    
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
   
  };
  return (
    <>
      <SliderWrapperMain>
        <Background></Background>
        <Grid>
          <Slider {...settings} ref={customSlider} id="sliderWrapper">
            {roomTypes &&
              roomTypes.map((value, index) => (
                <>
                  <SliderWrapper className="sliderWrapper">
                    <InsideSliderWrapper>
                      <ContentBlock className="contentBlock ">
                        <InnerContentWrapper className="afterContentChange">
                          <HeadingWrapper>
                            {value.RoomTypeName.section && (
                              <h2>{value.RoomTypeName.section}</h2>
                            )}
                          </HeadingWrapper>
                          <ParagraphWrapper>
                            <p
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: decodeContent(
                                  value.RoomTypeDescription
                                ),
                              }}
                            ></p>
                          </ParagraphWrapper>
                          <LinkWrapper
                            onClick={() => window.scrollTo(0, 0)}
                            href={mainSlugName + value.RoomTypeName.slugName}
                          >
                            Read more &gt;&gt;
                          </LinkWrapper>
                          <div>
                            <BookButton
                              href={`${bookRoomUrl(value.RoomcatgId)}`}
                              target="_blank"
                            >
                              Book Now
                            </BookButton>
                          </div>
                        </InnerContentWrapper>
                      </ContentBlock>
                      <FirstImageBlock className="firstImageBlock">
                        <InnerFirstBlock>
                          
                          {value.RoomTypeImages.Images.length > 0 ? (
                            <>
                              <div className="emptyLeftBlock"></div>
                              <div className="coverLeft  afterLeftChange"></div>
                              <img
                                src={
                                  sliderImageURL + value.RoomTypeImages.Images[0]
                                }
                                alt=""
                                className=""
                              />
                            </>
                          ) : (
                            <img src={noImageUrl} class="" alt="" />
                          )}
                        </InnerFirstBlock>
                      </FirstImageBlock>
                      <SecondImageBlock className="secondImageBlock">
                        <InnerSecondBlock>
                         
                          {value.RoomTypeImages.Images.length > 0 ? (
                            <>
                              <div className="emptyRightBlock"></div>
                              <div className="coverRight afterRightChange"></div>
                              <img
                                src={
                                  sliderImageURL + value.RoomTypeImages.Images[1]
                                }
                                alt=""
                                className=""
                              />
                            </>
                          ) : (
                            <img src={noImageUrl} class="" alt="" />
                          )}
                        </InnerSecondBlock>
                        <SliderButtonWrapper>
                          <PrevButton onClick={()=>gotoPrev()} >
                            <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.353554 5.68188L0 6.03544L0.353554 6.38899L6.03544 12.0709L6.74255 11.3638L2.09634 6.71757H25.8887V5.71757H1.73209L6.74255 0.707107L6.03544 0L0.353554 5.68188Z" fill="white"/>
                            </svg>
                          </PrevButton>
                          <NextButton onClick={()=>gotoNext()} >
                          <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5351 6.38892L25.8887 6.03536L25.5351 5.68181L19.8532 -7.3859e-05L19.1461 0.707033L23.7923 5.35323L-7.68812e-05 5.35324L-7.67938e-05 6.35324L24.1566 6.35323L19.1461 11.3637L19.8532 12.0708L25.5351 6.38892Z" fill="white"/>
                          </svg>
                          </NextButton>
                        </SliderButtonWrapper>
                      </SecondImageBlock>
                    </InsideSliderWrapper>
                  </SliderWrapper>
                </>
              ))}
          </Slider>
        </Grid>
      </SliderWrapperMain>
    </>
  );
};

export default SliderCardComponent;
