import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import { NormalLink } from "./Links";
import { sliderImageURL, noImageUrl } from "../../global/constants";
import constant from "./constant";
import { bookNowUrl } from "../../global/constants";

const AccommodationImageWrap = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  position: relative;
  border-bottom: 1px solid ${constant.blackColor};
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .direction-s {
    flex-direction: row-reverse;
    padding: 30px 0px;
  }
  .direction-r {
    flex-direction: row;
    padding: 30px 0px;
  }
  .room-desc {
    height: 100%;
    h3 {
      line-height: 1.25;
      font-size: 2.25rem;
      font-weight: inherit;
    }
    ul {
      margin-bottom: 1.5rem;
      li {
        font-size: 0.8em;
        color: #333;
      }
    }
    .btn-line {
      border-width: 1px;
      border-color: #000;
      color: #000;
      &.accomodation-btn {
        position: absolute;
        bottom: 0px;
      }
    }
    strong {
      font-weight: 400;
    }
  }
`;

const NormalLinkExtend = styled(NormalLink)`
  margin-right: 10px;
`;

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  return (
    <SectionWrapper className="margin">
      <Grid>
        <Row>
          <Col xs={12}>
            <h2>Accommodation</h2>
            {roomTypes.map((value, index) => {
              return (
                <AccommodationImageWrap>
                  <Row className={"direction-" + (index % 2 ? "s" : "r")}>
                    <Col md={6} xs={12}>
                      {value.RoomTypeImages.Images.length > 0 ? (
                        <img
                          src={sliderImageURL + value.RoomTypeImages.Images[0]}
                          alt=""
                        />
                      ) : (
                        <img src={noImageUrl} alt="" />
                      )}
                    </Col>
                    <Col md={6} xs={12}>
                      <div className="room-desc">
                        <h3>{value.RoomTypeName.section}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodeURIComponent(
                              value.RoomTypeDescription
                            ),
                          }}
                        ></div>

                        <NormalLinkExtend
                          to={value.RoomTypeName.slugName}
                          text="Read more"
                          className="btn-line accomodation-btn"
                        />
                        <NormalLinkExtend
                          to={`${bookNowUrl}${templateData.propertyDetails.propertyID}`}
                          text="Book Now"
                          className="btn-line accomodation-btn"
                        />
                      </div>
                    </Col>
                  </Row>
                </AccommodationImageWrap>
              );
            })}{" "}
          </Col>
        </Row>
      </Grid>
    </SectionWrapper>
  );
};

export default AccommodationComponent;
