import React from "react";

const EmptyStar = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="star"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    class="svg-inline--fa fa-star fa-w-18"
  >
    <path
      fill="currentColor"
      d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
      class=""
    ></path>
  </svg>
);

const ArrowLeft = () => (
  <svg
    width="26"
    height="13"
    viewBox="0 0 26 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.353554 5.68188L0 6.03544L0.353554 6.38899L6.03544 12.0709L6.74255 11.3638L2.09634 6.71757H25.8887V5.71757H1.73209L6.74255 0.707107L6.03544 0L0.353554 5.68188Z"
      fill="white"
    />
  </svg>
);

const ArrowRight = () => (
  <svg
    width="26"
    height="13"
    viewBox="0 0 26 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5351 6.38892L25.8887 6.03536L25.5351 5.68181L19.8532 -7.3859e-05L19.1461 0.707033L23.7923 5.35323L-7.68812e-05 5.35324L-7.67938e-05 6.35324L24.1566 6.35323L19.1461 11.3637L19.8532 12.0708L25.5351 6.38892Z"
      fill="white"
    />
  </svg>
);
export { EmptyStar, ArrowLeft, ArrowRight };
