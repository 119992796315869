import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchReviews, fetchDailyOffer } from "../../providers/api";
import { formatDate1, calculateRating } from "../../global/functions";
import { Grid, Row, Col } from "react-flexbox-grid";
import constant from "./constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';

const ReviewsComponentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  h2{
    text-align: center;
  }
`;
const ReviewBlock = styled.div`
  display: block;
  margin: 10px 0;
  padding: 20px;
  border-bottom: solid 1px #eee;
  border-radius: 5px;
  position: relative;
`;
const ReviewTitle = styled.div`
  font-size: 20px;
  margin-top: 70px;
  color: ${constant.secondaryColor};
  font-weight: 600;
`;
const ReviewLabel = styled.div`
  line-height: 2;
`;
const Rating = styled.div`
  margin-bottom: 20px;
`;
const ReviewName = styled.div`
  margin-bottom: 20px;
  font-style: italic;
`;
const ChannelName = styled.div`
  color: ${constant.secondaryColor};
`;

const ChannelResult = styled.div`
  margin: 20px;
  text-align: center;
  border: solid 1px #ccc;
  img {
    width: 100%;
  }
`;

const TotalRating = styled.div`
  font-size: 1.4em;
  background: #eee;
  padding: 10px;
  span {
    font-size: 0.8em;
    text-transfor: uppercase;
    display: block;
    font-weight: bold;
  }
`;
const TotalReview = styled.div`
  font-size: 1em;
  background: #000;
  padding: 10px;
  color: #fff;
`;

const ImageWrapper = styled.div`
`;

const ReviewWrapper = styled.div`
  text-align: center;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    &:before {
      font-size: 30px;
    }
  }
  .slick-prev {
    left: 60px;
  }
  .slick-next {
    right: 60px;
  }
  .slick-prev:before {
    content: ${faChevronLeft};
    color: ${constant.secondaryColor};
    font-size: 30px;
  }
  .slick-next:before {
    content: ${faChevronRight};
    color: ${constant.secondaryColor};
    font-size: 30px;
  }
`;

const ReviewsComponent = ({ templateData }) => {
  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState([]);

  function getReviews(propertyID) {
    fetchReviews(propertyID).then((data) => {
      setResultData(data && data.resultValue ? data.resultValue : []);
      setLoading(false);
      return false;
    });
  }
  function getDailyOffer(propertyID) {
    fetchDailyOffer(propertyID).then((data) => {
      console.log(data);
    });
  }

  useEffect(() => {
    getReviews(templateData.propertyDetails.propertyID);
    getDailyOffer(templateData.propertyDetails.propertyID);
  });

  // const ratingChannelNames = {
  //   Expedia: "Expedia",
  //   "Booking.com": "Booking.com",
  //   "Hotels.com": "Hotels.com",
  //   Agoda: "Agoda",
  // };

  const reviewChannel = {
    slidesToShow: 6,
    arrow: true,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const reviewSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  // const reviewImgURL = "https://review.staah.net/logo/";
  return (
    <ReviewsComponentWrapper>
      <Grid>
        <Row>
          <Col md={12} xs={12}>
            <h2>Reviews</h2>
            <ReviewWrapper>
              <Slider {...reviewSlider}>
                {!loading ? (
                  resultData &&
                  resultData.reviewlist &&
                  resultData.reviewlist.map((item, index) => (
                    <ReviewBlock key={index}>
                      <ReviewTitle>{item.review_title}</ReviewTitle>
                      <ReviewLabel>{item.your_view}</ReviewLabel>
                      <Rating>
                        {calculateRating(
                          item.rating_overall,
                          constant.primaryColor
                        )}
                      </Rating>
                      <ReviewName>
                        {item.guest_name} ({formatDate1(item.review_time)})
                      </ReviewName>
                      <ChannelName>Source: {item.channel_name}</ChannelName>
                    </ReviewBlock>
                  ))
                ) : (
                  <div>Loading..</div>
                )}
              </Slider>
            </ReviewWrapper>
          </Col>
          <Col md={12} xs={12}>
            <h2>&nbsp;</h2>
            <ImageWrapper>
              <Slider {...reviewChannel}>
                {!loading ? (
                  resultData &&
                  resultData.ratings &&
                  resultData.ratings.map(
                    (item, index) =>
                      item.total_score > 0 && (
                        <ChannelResult>
                          <img src={item.channel_logo} alt=""/>
                          <TotalRating>
                            <span>Rating:</span>
                            {Math.round(item.total_score * 10) / 10} out of 5
                          </TotalRating>
                          <TotalReview>{item.total_reviews} reviews</TotalReview>
                        </ChannelResult>
                      )
                  )
                ) : (
                  <div>Loading...</div>
                )}
              </Slider>
            </ImageWrapper>
          </Col>
        </Row>
      </Grid>
    </ReviewsComponentWrapper>
  );
};

export default ReviewsComponent;
