import React from "react";

import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";

import styled from "styled-components";
import media from "../../global/media";
import RoomDetailHomePageComponent from "./RoomDetailHomePageComponent";
import constant from "./constant";

const LinkWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${media.sm} {
  }

  .card-center {
    display: flex;
    justify-content: center;
  }
  span {
    font-weight: 600;
  }
`;

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const accommodationSlugName = templateData.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  const heading = templateData.menuLinks.menuLink.filter(
    (item) => item.pageId === "2"
  );
  console.log(heading);
  return (
    <>
      <SectionWrapper
        bgColor="#fff"
        textColor="#000"
        padding="8em 0 0 0"
        mobilePadding="4em 0 0 0"
      >
        <Grid>
          <Row>
            <Col xs={12}>
              {heading[0] && (
                <h2 class="left">{heading[0].MenuLinkpageName}</h2>
              )}
            </Col>
          </Row>
        </Grid>
        <CardWrapper>
          {roomTypes.map((item, index) => (
            <RoomDetailHomePageComponent
              value={item}
              mainSlugName={`${process.env.PUBLIC_URL}` + accommodationSlugName[0]["menuSlugName"]}
            />
          ))}
        </CardWrapper>
      </SectionWrapper>
    </>
  );
};

export default AccommodationComponent;
