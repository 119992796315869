import React from "react";
import styled from "styled-components";
import media from "../../global/media";
import { Grid, Row, Col } from "react-flexbox-grid";
import {
  sliderImageURL,
  decodeContent,
  noImageUrl,
  bookRoomUrl,
} from "../../global/constants";
import constant from "./constant";
import {
  NormalLink as Link,
  SliderButtonWrapper,
  PrevButton,
  NextButton,
} from "./Links";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { SectionWrapper } from "../Template9Pages/templateConstants";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent2";

const HeaderWrapper = styled.div`
  background: ${(prop) => prop.bgColor || "#fff"};
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0;
  ${media.md} {
    grid-template-columns: 55% 45%;
  }
`;
const RightWrapper = styled.div`
  &.insideRightHeader {
    z-index: 98;
    order: 2;
    ${media.md} {
      order: 0;
    }
    ${media.sm} {
      margin-top: 130px;
    }
  }
`;

const RoomFacilities = styled.div`
  background: #191919;
  margin: 0;
  padding-left: 1rem;
`;
const HeroContentWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.roomType-hero {
    margin-top: calc(${(prop) => prop.width + "px" || "150px"});

    ${media.sm} {
      margin-top: calc(${(prop) => prop.width + "px" || "150px"});
    }
    align-items: flex-start;
  }
`;
const ContentWrapper = styled.div`
  margin-right: 0;
  margin-left: 0;
  padding-right: 1rem;
  padding-left: 1rem;
`;
const Header = styled.div`
  padding-bottom: 45px;
  ${media.md} {
    padding-bottom: 80px;
  }
  &.insideHeader {
    padding-top: 45px;
    animation: openLoad 0.8s ease-in;
    ${media.sm} {
      padding-top: 100px;
    }
  }
  h1 {
    text-align: left;
    font-size: 3em;
    line-height: 1;
    margin-top: 0;
    font-weight: 300;
    color: ${constant.bodyColor};
    margin-bottom: 1rem;
    ${media.sm} {
      font-size: 4em;
    }
  }
  p {
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: 0.1px;
    color: ${constant.bodyColor};
    /* padding: 10px 20px; */
    font-weight: 300;
    margin-bottom: 1rem;
  }
`;
const HeaderInnerWrapper = styled.div`
  padding-top: 40px;
`;

const LeftWrapper = styled.div``;

const HeroImageWrapper = styled.div`
  position: relative;
  margin-bottom: 5rem;
  display: flex;
`;

const SmallImageWrapper = styled.div`
  &.small-headerImage {
    z-index: 2;
    position: absolute;
    right: 0;
    /* top: 50%; */
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    top: 50%;
    margin-top: 2em;
    width: 63.66667%;
    ${media.md} {
      left: 25%;
      top: auto;
      bottom: 0;
    }
  }

  &.smallImage-roomType {
    position: relative;
    transform: none;
    width: 100%;
    padding: 0 2%;

    ${media.md} {
      z-index: 2;
      position: absolute;
      right: 0;
      /* top: 50%; */
      transform: translateY(-50%);
      left: 50%;
      transform: translateX(-50%);
      bottom: auto;
      top: 50%;
      margin-top: 2em;
      width: 85%;
      top: 60%;
      padding: 0;
    }
    ${media.lg} {
      top: 50%;
    }
  }
`;
const SliderWrapper = styled.div`
  .slick-slider {
    height: 100%;
  }
  .slick-slide {
    max-height: 400px;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: ${constant.primaryColor};
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
  img {
    width: 100%;
    animation: openLoad 0.8s ease-in;
    min-height: 100%;
  }
`;
const SliderInsideWrapper = styled.div`
  position: relative;

  .slider-prev {
    position: absolute;
    top: 40%;
    left: 20px;
  }
  .slider-next {
    position: absolute;
    top: 40%;
    right: 20px;
  }
`;
const SmallImageDivWrapper = styled.div`
  padding-top: 60%;
  overflow: hidden;
  position: relative;
  background: #0a0a0a;
  transform: translate(0px, 0px);
  z-index: 98;
  ${media.md} {
    padding-top: 105%;
  }
  &.roomType-slider {
    padding: 0;
  }
`;

const SmallImageDiv = styled.div`
  background-image: url(${(prop) => prop.bgImageOne || "#fff"});
  height: 433px;
  transform: translate(0px, 0px);
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: openLoad 0.8s ease-in;
  @keyframes openLoad {
    0% {
      transform: translateY(20%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
`;

const LargeImageWrapper = styled.div`
  margin-bottom: 4em;
  width: 100%;
  ${media.md} {
    margin-left: 16.66667%;
    width: 83.33333%;
  }
  &.largeImageWrapper {
    display: none;
    ${media.sm} {
      display: block;
    }
  }
`;

const LargeImageDivWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background: transparent;
  padding-top: 50%;
  transform: translate(0px, 0px);
  ${media.md} {
    padding-top: 105%;
  }
`;

const LargeImageDiv = styled.div`
  background: ${(prop) => (prop.bgImageTwo ? prop.bgImageTwo : "#000")};
  height: 611px;
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: openLoad 0.8s ease-in;
`;

const SecondImageBox = styled.div`
  position: absolute;
  background: ${(prop) => (prop.bgImageTwo ? prop.bgImageTwo : "#000")};
  position: absolute;
  width: 36%;
  min-height: 47%;
  top: 0;
  right: 0px;
  z-index: 1;
  display: none;

  ${media.md} {
    display: flex;
  }
`;

const InsidePageHeaderComponent = ({
  heading,
  content,
  bgImageOne,
  bgImageTwo,
  href,
  roomType,
  data,
  facilities,
  previousUrl,
  currentUrl,
  previousName,
  LogoWidth,
}) => {
  const customSlider2 = React.createRef();

  const gotoNext = () => {
    customSlider2.current.slickNext();
  };

  const gotoPrev = () => {
    customSlider2.current.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return roomType === true ? (
    <SectionWrapper className="noPadding"></SectionWrapper>
  ) : (
    <>
      <HeaderWrapper bgColor={constant.primaryColor}>test</HeaderWrapper>
    </>
  );
};

export default InsidePageHeaderComponent;
