import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { library } from "@fortawesome/fontawesome-svg-core";
import { faChrome } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constant from "../../Template/Template8Pages/constant";
import { NavLink } from "react-router-dom";
import media from "../../global/media";
import { propertyImageURL, decodeContent } from "../../global/constants";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
  faFax,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import JoinOurNewsLetter from "./JoinOurNewsLetterWidget";
// import Lightbox from "react-image-lightbox";

const FooterLayout8Wrapper = styled.div`
  width: 100%;
  color: #000;
  padding: 50px;
  word-break: break-all;
  line-height: 1.8;
  background-blend-mode: multiply;
  background: #fafafa;
  background-position: center;
  background-size: cover;
  min-height: 70vh;
  text-align: center;
  ${media.sm} {
    text-align: left;
  }
  /* url(${(prop) => prop.bgImage}) */
  .icon-span {
    width: 25px;
    margin-right: 10px;
  }
  .skyhi {
    font-size: 12px;
    margin-top: 40px;
    text-align: center;
  }
  .contact {
    list-style: none;
    flex-wrap: wrap;
    text-transform: uppercase;
    letter-spacing: 1.4;
    color: ${constant.blackColor};
    font-size: 14px;
    position: relative;
    li {
      margin-bottom: 18px;
      .icon-settings {
        font-size: 18px;
        color: #212121;
        margin-right: 10px;
      }
      .contact-label {
        color: ${constant.blackColor};
        padding-right: 10px;
      }
      &.contact-email {
        text-transform: none;
      }
    }
    a {
      border-bottom: 1px solid #eee;
    }
    a,
    p {
      display: flex;
      align-items: center;
    }
  }
  h2 {
    margin-bottom: 10px;
    font-size: 24px;
    position: relative;
    font-weight: lighter;
    padding: 5px 0 5px 0;
  }
  p,
  a {
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 1.7em;
    text-transform: none;
    color: ${constant.blackColor};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    position: relative;

    &:hover {
      color: rgb(135, 28, 60);
    }
  }
  h3 {
    margin-bottom: 20px;
    color: ${constant.blackColor};
  }

  .center {
    padding: 0 30%;
  }
  p {
    a {
      font-size: 12px;
    }
  }
`;
const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
  ${media.sm} {
    justify-content: flex-end;
  }
  h3 {
    text-align: center;
    color: ${constant.blackColor};
    width: 100%;
    margin-bottom: 5px;
    ${media.sm} {
      text-align: right;
    }
  }
`;

const SocialLink = styled.a`
  color: ${constant.blackColor};
  margin-right: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    color: ${constant.blackColor};
  }
  &:hover {
    color: ${constant.whiteColor};
    background: rgb(135, 28, 60);
    svg {
      color: #fff;
    };
    i{
      color: #fff;
    }
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${media.sm} {
    justify-content: flex-end;
  }
  img {
    max-width: 200px;
    &.logo {
      width: ${(prop) => (prop.width ? prop.width / 2 + "px" : "auto")};
      height: ${(prop) => (prop.height ? prop.height / 2 + "px" : "auto")};
      ${media.sm} {
        width: ${(prop) => (prop.width ? prop.width + "px" : "auto")};
        height: ${(prop) => (prop.height ? prop.height + "px" : "auto")};
      }
    }
  }
`;

const NavBar = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 20px;
  .nav-link {
    color: ${constant.blackColor};
    padding: 0 15px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const FooterLayout8 = ({
  contactInfo,
  socialLinks,
  propertyDetails,
  propertyImages,
  menuLinks,
  data,
  footerTop,
  // onNavClick,
  reviewMinderWidget,
}) => {
  // const [open, setOpen] = useState(false);
  // const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];
  propertyImages.image.map((value) => images.push(propertyImageURL + value));

  const onNavExternalClick = (externalLink) => {
    window.open(externalLink, "_blank");
  };
  return (
    <FooterLayout8Wrapper bgImage={propertyImages.image[2]}>
      <Grid>
        <Row>
          <Col xs={12} sm={5}>
            <h2>Contact Us</h2>
            <ul className="contact">
              {contactInfo.telephone.phone && (
                <li>
                  <a
                    href={
                      "tel:" +
                      decodeURIComponent(contactInfo.telephone.phone).replace(
                        /\s/g,
                        ""
                      )
                    }
                  >
                    <span className="icon-span">
                      <FontAwesomeIcon
                        icon={faPhoneAlt}
                        className="icon-settings"
                      />
                    </span>
                    {decodeURIComponent(contactInfo.telephone.phone)}
                  </a>
                </li>
              )}
              {contactInfo.telephone.fax && (
                <li>
                  <p>
                    <span className="icon-span">
                      <FontAwesomeIcon icon={faFax} className="icon-settings" />
                    </span>
                    {decodeURIComponent(contactInfo.telephone.fax)}
                  </p>
                </li>
              )}
              {contactInfo.freephone && (
                <li>
                  <p>
                    <span className="icon-span">
                      <FontAwesomeIcon
                        icon={faMobileAlt}
                        className="icon-settings"
                      />
                    </span>
                    {decodeURIComponent(contactInfo.freephone)}
                  </p>
                </li>
              )}
              {contactInfo.email && (
                <li>
                  <a href={"mailto:" + decodeURIComponent(contactInfo.email)}>
                    <span className="icon-span">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="icon-settings"
                      />
                    </span>
                    {decodeURIComponent(contactInfo.email)}
                  </a>
                </li>
              )}
              {contactInfo.website && (
                <li>
                  <a href={"http://" + contactInfo.website} target="_blank">
                    <span className="icon-span">
                      <FontAwesomeIcon
                        icon={faChrome}
                        className="icon-settings"
                      />
                    </span>
                    {decodeURIComponent(contactInfo.website)}
                  </a>
                </li>
              )}
              {contactInfo.address && (
                <li>
                  <a
                    href={
                      "http://maps.google.com/?ie=UTF8&hq=&ll=" +
                      propertyDetails.propertyLatitude +
                      "," +
                      propertyDetails.propertyLongitude +
                      "&z=13"
                    }
                    target="_blank"
                  >
                    {decodeURIComponent(contactInfo.address.location)}{" "}
                    {contactInfo.address.city}, {contactInfo.address.country}
                  </a>
                </li>
              )}
            </ul>
          </Col>

          <Col xs={12} sm={7}>
            <Row>
              <Col xs={12}>
                <SocialWrapper>
                  {
                    data.is_newsletter === "1"
                      ?
                      <>
                        <JoinOurNewsLetter data={data}/>
                      </>
                      :
                      <>
                      </>

                  }
                  <h3>JOIN THE CONVERSATION</h3>

                  {socialLinks.facebook && (
                    <SocialLink target="_blank" href={socialLinks.facebook}>
                      <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                    </SocialLink>
                  )}
                  {socialLinks.twitter && (
                    <SocialLink target="_blank" href={socialLinks.twitter}>
                      <FontAwesomeIcon icon={["fab", "twitter"]} />
                    </SocialLink>
                  )}
                  {socialLinks.linkedIn && (
                    <SocialLink target="_blank" href={socialLinks.linkedIn}>
                      <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                    </SocialLink>
                  )}
                  {socialLinks.googelepluse && (
                    <SocialLink target="_blank" href={socialLinks.googelepluse}>
                      <FontAwesomeIcon icon={["fab", "google-plus-g"]} />
                    </SocialLink>
                  )}
                  {socialLinks.tripadvisor && (
                    <SocialLink target="_blank" href={socialLinks.tripadvisor}>
                      {/* <FontAwesomeIcon icon={["fab", "tripadvisor"]} /> */}
                      <i class="fa fa-tripadvisor"></i>
                    </SocialLink>
                  )}
                  {socialLinks.instagram && (
                    <SocialLink target="_blank" href={socialLinks.instagram}>
                      <FontAwesomeIcon icon={["fab", "instagram"]} />
                    </SocialLink>
                  )}
                </SocialWrapper>
              </Col>
              <Col xs={12}>
                <ImgWrapper
                  width={propertyDetails.propertyLogoWidth || "auto"}
                  height={propertyDetails.propertyLogoHeight || "auto"}
                >
                  <img
                    className="logo"
                    src={propertyDetails.propertyLogo}
                    alt={propertyDetails.propertyName}
                  />
                </ImgWrapper>
              </Col>
            </Row>
          </Col>

          <Col xs={12}>
            <NavBar>
              {menuLinks.map((item, index) =>
                item.menuSlugName === "/reviews/" ||
                  item.menuSlugName === "/review/" ? (
                  reviewMinderWidget === "1" && (
                    <NavLink
                      key={index}
                      activeClassName="active-link"
                      onClick={footerTop}
                      className="nav-link"
                      to={item.menuSlugName}
                      exact
                    >
                      {item.menuLinkName}
                    </NavLink>
                  )
                ) : item.menuExternalLink ? (
                  <NavLink
                    key={index}
                    activeClassName="active-link"
                    className="nav-link"
                    onClick={() => onNavExternalClick(item.menuExternalLink)}
                    to="#"
                  >
                    {item.menuLinkName}
                  </NavLink>
                ) : (
                  <NavLink
                    key={index}
                    activeClassName="active-link"
                    onClick={footerTop}
                    className="nav-link"
                    to={item.menuSlugName}
                    exact
                  >
                    {item.menuLinkName}
                  </NavLink>
                )
              )}
            </NavBar>
          </Col>
          <Col xs={12}>
            <p className="skyhi">
              &copy; 2021 {propertyDetails.propertyName} Designed by{" "}
              <a href="https://www.staah.com" target="_blank">
                STAAH
              </a>
            </p>
          </Col>
        </Row>
      </Grid>
    </FooterLayout8Wrapper>
  );
};

export default FooterLayout8;
