import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "styled-components";
// import { propertyImageURL } from "../../global/constants";

import BookingWidgetComponent from "../Template6Pages/BookingWidgetComponent";
import media from "../../global/media";
import constant from "../Template6Pages/constant";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHome, faUsers } from '@fortawesome/fontawesome-free-solid';

const HomePageWelcomeComponentWrapper = styled.div`
  padding: 4em 0;
  text-align: center;
  .heading-h2 {
    margin-bottom: 40px;
    position: relative;
    font-size: 2.5em;
    text-transform:uppercase;
    transform:skewX(-20deg);
    color: #666666;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(0,0,0,0.4);
  }
  p {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.3px;
    word-break: break-all;
  }
  ${media.md}{
    padding: 4em 0;
  }
`;

const BookingEngineWidgetComponentWrapper = styled.div`
  padding: 15px 0;
  z-index: 2;
  background-color: rgba(0,0,0,  0.7);
  width: 100%;
  color: #fff;
  
  display: flex;
  align-items: center;
  position:relative;
  height:90vh;
  padding-top:50px;
  padding-bottom:50px;
  .heading{
      display:none;
    }
  ${media.sm}{
  height:45vh;
  }
  ${media.lg}{
    height:100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom:0;
    width:35%;
    .heading{
      display:block;
    }
}

  h2 {
    text-align: center;
  }

  .bookingWidget {
    padding: 30px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    flex-wrap: wrap;
    //justify-content: ;
    align-items: flex-end;
    .heading {
      font-size: 20px;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
      height:100%;
    }
    .checkInWrapper,
    .checkOutWrapper {
      width: 100%;
      margin-bottom: 20px;
      /* ${media.sm} {
        width: 46%;
        margin-right: 20px;
      }
      ${media.md} {
        width: 20%;
        margin-right: 0;
      } */
      .react-datepicker-wrapper {
        width: 100%;
        margin-top: 10px;
        .datepicker-input {
          border-radius: 15px;
          padding: 5px;
          font-size: 12px;
          border: 1px solid #ccc;
        }
      }
    }
    input{
      border: 1px solid lightgrey;
      font-size: 14px;
      font-weight: 500;
      height: 65px;
      &:focus{
        border-color: none;
      }
    }
    .roomsWrapper {
      width: 100%;
      margin-bottom: 20px;

      /* ${media.sm} {
        width: 46%;
        margin-right: 20px;
      }
      ${media.md} {
        width: 20%;
        margin-right: 0;
      } */

      .roomsWrapperLabel {
        background: #fff;
        width: 100%;
        padding: 10px;
        border: 1px solid lightgrey;
        font-size: 16px;
        font-weight: 500;
        height: 65px;
        margin-top: 10px;
      }
      
    }
    .flexibleWrapper {
      width: 80%;
      margin-bottom: 20px;
      /* ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 18%;
      } */
      input {
        margin-top: 10px;
        padding: 10px;
        border: solid 1px #ccc;
        font-size: 12px;
        width: 100%;
        color:#eee;
      }
    }
    .buttonWrapper {
      max-width: 40%;
      margin-bottom: 20px;
      margin:0 auto;
      /* ${media.md} {
        width: 18%;
      } */
      button {
        font-size:16px;
        padding: 15px;
        width: 100%;
        background: ${constant.secondaryColor};
        color: #fff;
        height: 65px;
        margin-top: 10px;
        border: none;
        border-radius:15px;
      }
    }
  }
`;

const contentLimit = 400;

const HomePageWelcomeComponent = ({
  propertyName,
  content,
  maxGuestCapacity,
  propertyId,
  roomTypes,
}) => (
  <div>
    <BookingEngineWidgetComponentWrapper>
      <Grid>
        <BookingWidgetComponent
          maxGuestCapacity={maxGuestCapacity}
          propertyId={propertyId}
        />
      </Grid>
    </BookingEngineWidgetComponentWrapper>
    <HomePageWelcomeComponentWrapper>
      <Grid>
        <Row>
          <Col xs={12} md={12}>
            <h2 className="heading-h2">{propertyName}</h2>
            <p>
              {decodeURIComponent(content.substring(0, contentLimit)) + "..."}
            </p>
            <br />
          </Col>
        </Row>
      </Grid>
    </HomePageWelcomeComponentWrapper>
  </div>
);

export default HomePageWelcomeComponent;
