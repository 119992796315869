import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderImageURL } from "../global/constants";
import media from "../global/media";

const SliderWrapper = styled.div`
  height: 90vh;
  background-color: rgba(98, 98, 98, 0.4);
  opacity: 1;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    &:before {
      font-size: 30px;
    }
  }
  .slick-prev {
    left: 60px;
  }
  .slick-next {
    right: 60px;
  }
`;
const SlideImage = styled.div`
  background-image: url(${(prop) => prop.bgimage});
  background-size: cover;
  background-position: center;
  height: 90vh;
  position: relative;
  img {
    width: 100%;
  }
`;
const Caption = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  color: #fff;
  font-size: 2em;
  ${media.md} {
    top: 30%;
  }
`;

const InnerLayer = styled.div`
    background: rgba(15,22,42,.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const HomePageSlider3 = ({ sliderImages, sliderLabel, sliderDesc }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {sliderImages.sliderImage.map((item, index) => (
          <SlideImage bgimage={sliderImageURL + item}>
            <InnerLayer>
              {sliderImages.sliderLabel[index] && (
                <Caption>
                  <h2>{sliderImages.sliderLabel[index]}</h2>
                  <p>{sliderImages.sliderDesc[index]}</p>
                </Caption>
              )}
            </InnerLayer>
          </SlideImage>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default HomePageSlider3;
