import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import constant from "./constant";

const LinkWrapper = styled(Link)`
  display: inline-block;
  padding: 10px 4.5em 10px 10px;
  border: 2px solid #ccc;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.52px;
  text-decoration: none;
  color: #000;
  position: relative;
  transition: all 1s ease 0s;
  overflow: hidden;
  line-height: 1;
  &:before {
    content: "";
    width: 52px;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    border-left: 15px solid transparent;
    border-top: 44px solid ${constant.primaryColor};

    transition: all 1s ease 0s;
    z-index: 0;
  }
  svg {
    z-index: 1;
    color: #fff;
    position: absolute;
    right: 1.8em;
  }
  &:hover {
    background-color: ${constant.primaryColor};
    color: #fff;
    text-decoration: none;
    svg {
      color: #0e3360;
    }
    &:before {
      border-top-color: #fff;
    }
  }
`;

const AvailabilityWrapper = styled.div`
  display: inline-block;
  padding: 10px 8px;
  text-decoration: none;
  text-decoration: none;
  color: #0e3360;
  position: relative;
  transition: all 1s ease 0s;
  overflow: hidden;
  line-height: 1;
  background-color: #fff;
`;

const NormalLink = ({ to, text }) => (
  <LinkWrapper to={to}>
    {text} <FontAwesomeIcon icon={faChevronRight} />
  </LinkWrapper>
);

const AvailabilityBtn = ({ to, text }) => (
  <AvailabilityWrapper to={to}>{text}</AvailabilityWrapper>
);

export { NormalLink, AvailabilityBtn };
