import styled from "styled-components";
import media from "../../global/media";
import constant from "./constant";

import { faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid'

const SectionWrapper = styled.div`
  padding: 3em 0;
  background: ${(prop) => prop.bgColor || "#fff"};
  word-break: break-all;
  &.margin {
    ${media.md} {
    }
    line-height: 2;
    ul {
      padding-left: 1em;
    }
    ul.facilities {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 100%;
        ${media.sm} {
          width: 50%;
        }
        ${media.md} {
          width: 33%;
        }
        svg {
          color: #999;
          margin-right: 10px;
        }
      }
    }
  }
  h2 {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 30px;
    padding-bottom: 30px;
    position: relative;
    &.left {
      text-align: left;
    }
  }
  .heading-link {
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.sm} {
      justify-content: flex-end;
    }
  }
  .sub-headline {
    text-align: center;
  }
  .block-heading{
    text-align:center;
    span{
      font-size: 1.5rem;
      color: ${constant.secondaryColor};
      font-weight: 500;
    }
    h2{
      font-size: 1.5em;
      margin-top: 0px;
    }
    ${media.md}{
      text-align: center;
      span{
        font-size: 2.5rem;
        color: ${constant.secondaryColor};
        font-weight: 500;
      }
      h2{
        font-size: 2.5em;
      }
    }
  }
  &.noPadding{
    padding: 0;
  }
  .cardCol{
    margin: 20px 0px;
    .card-body{
      padding: 30px;
      font-weight: normal;
      ul li strong{
        font-weight: normal;
      }
    }
    &:hover{
      transform: translateY(-15px);
    }
  }
  &.promotion{
    display: block;
    width: 100%;
    ${media.md}{
      display: flex;
    }
  }
  .slick-prev:before {
    content: ${faChevronLeft};
    color: ${constant.primaryColor};
    font-size: 30px;
  }
  .slick-next:before {
    content: ${faChevronRight};
    color: ${constant.primaryColor};
    font-size: 30px;
  }
  .contactBlock{
    border: 1px solid #ccc;
    h2{
      font-size: 16px;
    }
    .email{
      font-size: 16px;
      font-weight: 600;
      padding: 10px 0px; 

    }
    .icon-settings, .heading{
      color: ${constant.primaryColor};
    }
  }
  .checked{
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${constant.primaryColor};
    padding: 5px;
  }
  .roomTypeContent{
    strong{
      font-weight: normal;
    }
  }
  .iconColor{
    color: ${constant.primaryColor};
  }
`;

export { SectionWrapper };
