//import { reviewApiAuthorizationKey } from "../global/constants";

async function fetchReviews(propertyId) {
  let response = await fetch(
    `https://review.staah.net/wsdl/reviewlist.php?request_for=getReviews&StaahID=${propertyId}`
  );
  return await response.json();
}

async function fetchWatchMyRate(
  propertyId,
  startDate,
  endDate,
  bookingEngine,
  accessKey
) {
  
  // var myHeaders = new Headers();
  // myHeaders.append("Access-Control-Allow-Origin", "*");
  // myHeaders.append("Access-Control-Allow-Credentials", "true");
  // myHeaders.append("Access-Control-Allow-Headers", "Origin,Content-Type, Authorization, x-id, Content-Length, X-Requested-With");
  // myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS");
  console.log('propertyId--1->',propertyId);
  console.log('propertyId--2->',startDate);
  console.log('propertyId--3->',endDate);
  console.log('propertyId--4->',bookingEngine);
  console.log('propertyId--5->',accessKey);

  const url =
    bookingEngine === "I"
      ? `https://cp.staah.net/common-cgi/motels/admin/MinRoomRate_wmrnew_validity.pl?propertyId=${propertyId}&checkin_date=${startDate}&checkout_date=${endDate}`
      : `https://watchmyrate.com/wmrwidgetData_react.php?propertyId=${propertyId}&checkin_cmdate=${startDate}&checkout_cmdate=${endDate}&access_key=${accessKey}&theme=react&domain_name=homes`;

  //let response = await fetch(url,{mode:'no-cors'});

  //let response = await  fetch(url)
  //return await response.json()

  try {
    let response = await  fetch(url)
    console.log('RRRRRRRRRRRRRRRR');
    console.log(response);
    return await response.json()
    // use the parsed data here
  } catch (error) {
    if (error instanceof SyntaxError && error.message.includes('JSON')) {
      console.error('Invalid JSON data');
     
    } else {
      console.error(error);
     
    }
  }
  


  
}

async function fetchDailyOffer(propertyId) {
  let response = await fetch(
    `https://cp.staah.net/common-cgi/motels/admin/daily_offer_new.pl?motelid=${propertyId}`
  );
  return await response.json();
}

export { fetchReviews, fetchWatchMyRate, fetchDailyOffer };
