import React from "react";
import styled from "styled-components";
import media from "../media";
import constant from "../../Template/Template7Pages/constant";
import BookingWidgetFlexComponent from "./BookingWidgetFlexComponent";

const BookingWidgetOutsideWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: left;
  transition: opacity 1s ease-in-out;
  &.default {
    z-index: -1;
    opacity: 0;
  }
  &.widgetOpen {
    z-index: 1000;
    animation: mainBgOpen 1s linear;
    animation-fill-mode: forwards;

    .bookingEngineWrapper {
      opacity: 1;
      transition: opacity 1s ease-in-out;
      /* animation:widgetBgOpen 0.5s linear;
      animation-delay:0.5s; */
    }
    .widgetBgOpacity {
      animation: widgetBgOpen 0.5s linear;
      /* animation-delay:1s; */
    }
    .closeButton {
      animation-delay: 0.8s;
      animation-fill-mode: forwards;
      z-index: 10;

      &:before {
        animation-fill-mode: forwards;
        animation: openBefore 0.8s ease;
        animation-delay: 0.8s;
      }
      &:after {
        animation-fill-mode: forwards;
        animation: openAfter 0.8s ease;
        animation-delay: 0.8s;
      }
    }
    .backgroundEffect {
      &:before {
        transform: scale(4);
      }
    }
    .propertyName {
      opacity: 1;
    }
  }
  &.widgetClose {
    animation: mainBgClose 2s linear;
    animation-fill-mode: forwards;
    .bookingEngineWrapper {
      opacity: 0;
      transition: opacity 1s ease-in-out;
      /* animation:widgetBgOpen 0.5s linear;
      animation-delay:0.5s; */
    }
    .widgetBgOpacity {
      animation: widgetBgClose 0.8s linear;
      animation-fill-mode: forwards;

      /* animation-delay:1s; */
    }
    .closeButton {
      animation-delay: 0.8s;
      animation-fill-mode: forwards;
      z-index: 10;

      &:before {
        animation-fill-mode: forwards;
        animation: openBefore 0.8s ease;
        animation-delay: 0.8s;
      }
      &:after {
        animation-fill-mode: forwards;
        animation: openAfter 0.8s ease;
        animation-delay: 0.8s;
      }
    }
    .backgroundEffect {
      &:before {
        transform: scale(0);
      }
    }
    .propertyName {
      opacity: 0;
      transition: 0.5s all;
    }
  }

  @keyframes widgetBgOpen {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes widgetBgClose {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
      z-index: -1;
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
  @keyframes mainBgOpen {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  @keyframes mainBgClose {
    0% {
      opacity: 1;
      background: rgba(0, 0, 0, 0.8);
    }
    80% {
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
`;

const BookingEngineWidgetComponentWrapper = styled.div`
  display: flex;
  position: absolute;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem 2rem;
  /* padding-top: 8rem; */
  height: auto;
  max-width: 300px;
  width: 100vw;
  z-index: 60;
  box-shadow: 0 0 0 #000;

  ${media.md} {
    padding: 4rem;
    min-height: 25rem;
    max-width: 1350px;
    width: 100vw;
  }
  h2 {
    text-align: center;
  }
  .label {
    font-size: 10px;
    text-transform: uppercase;
  }

  .bookingWidget {
    padding: 0 10px;
    width: 100%;
    font-size: 12px;
    /* color: ${constant.bodyColor}; */
    color: ${(prop) => (prop.textColor ? prop.textColor : "#646464")};

    ${media.md} {
      padding: 10px;
      display: flex;
    }
    .heading {
      font-size: 16px;
      display: block;
      /* color: ${constant.secondaryColor}; */
      color: ${(prop) => (prop.buttonColor ? prop.buttonColor : "#FFD700")};
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      ${media.md} {
        display: none;
      }
    }
    .bookingengine {
      display: none;
      opacity: 1;
      transition: all 5s ease-in;
      width: 100%;
      z-index: 1000;
      ${media.md} {
        display: flex;
      }
      &.show {
        display: block;
        ${media.md} {
          display: flex;
        }
        opacity: 1;
      }
    }
    .checkInWrapper,
    .checkOutWrapper {
      width: 100%;
      margin-bottom: 20px;
      border-bottom: solid 1px
        ${(prop) => (prop.borderColor ? prop.borderColor : "#FFD700")};

      ${media.md} {
        width: 54%;

        margin: 0 10px 0 0;
      }
      /* .react-datepicker{
        color:${(prop) => (prop.textColor ? prop.textColor : "#000")};
        border:${(prop) => (prop.borderColor ? prop.borderColor : "#000")};
        background-color: ${(prop) =>
        prop.secondaryColor ? prop.secondaryColor : "#000"};;
      } */
      .react-datepicker-wrapper {
        width: 100%;
        margin-top: 10px;

        .datepicker-input {
          padding: 0;
          font-size: 12px;
          border: 0;
          height: 29px;
          border-radius: 0;
          background: transparent;
          border-color: transparent;
          /* color: ${constant.blackColor}; */
          color: ${(prop) =>
            prop.datePickerColor ? prop.datePickerColor : "#000"};
          &:focus {
            outline: 0;
            /* border-bottom: solid 1px ${constant.bodyColor}; */
          }
        }
      }
    }
    input {
      border: 1px solid
        ${(prop) => (prop.borderColor ? prop.borderColor : "#999")};
      font-size: 14px;
      font-weight: 500;
      height: 45px;
      &:focus {
        border-color: none;
      }
    }
    .roomsWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.md} {
        margin-bottom: 0;
        margin: 0 10px;
      }
      ${media.lg} {
      }
      .capacityWrapper {
        height: 100%;
        border-bottom: solid 1px
          ${(prop) => (prop.borderColor ? prop.borderColor : "#999")};

        .roomInnerBlock {
          background-color: ${(prop) =>
            prop.roomBgColor ? prop.roomBgColor : "#fff"};
          color: ${(prop) =>
            prop.roomTextColor ? prop.roomTextColor : "#000"};
          .roomHead {
            background-color: ${(prop) =>
              prop.roomHeadBg ? prop.roomHeadBg : "#000"};
            color: ${(prop) =>
              prop.roomHeadText ? prop.roomHeadText : "#fff"};
          }
          .roomContent {
            background-color: ${(prop) =>
              prop.roomContentBg ? prop.roomContentBg : "#fff"};
            color: ${(prop) =>
              prop.roomContentText ? prop.roomContentText : "#000"};
            .button-wrapper {
              border: 1px solid
                ${(prop) => (prop.buttonBgColor ? prop.buttonBgColor : "#000")};
              button {
                background-color: ${(prop) =>
                  prop.buttonBgColor ? prop.buttonBgColor : "#000"};
              }
            }
          }
        }
        .popperContainer {
          background-color: ${(prop) =>
            prop.roomBgColor ? prop.roomBgColor : "#fff"};
          color: ${(prop) =>
            prop.roomTextColor ? prop.roomTextColor : "#000"};
          .arrow::after {
            background-color: ${(prop) =>
              prop.roomBgColor ? prop.roomBgColor : "#fff"};
          }
        }
      }
      .roomsInsideWrapper {
        height: 100%;
      }
      .adultWrapper {
        height: 100%;
        border-bottom: 1px solid
          ${(prop) => (prop.borderColor ? prop.borderColor : "#999")};
        select {
          margin-top: 10px;
          padding: 0;
          font-size: 12px;
          border: 0;
          height: 29px;
          border-radius: 0;
          background: ${(prop) =>
            prop.secondaryColor ? prop.secondaryColor : "transparent"};
          border-color: transparent;
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          outline: none;
        }
        option {
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          text-align: center;
        }
      }
      .childWrapper {
        height: 100%;
        border-bottom: 1px solid
          ${(prop) => (prop.borderColor ? prop.borderColor : "#999")};
        margin-left: 5px;
        select {
          margin-top: 10px;
          padding: 0;
          font-size: 12px;
          border: 0;
          height: 29px;
          border-radius: 0;
          background: ${(prop) =>
            prop.secondaryColor ? prop.secondaryColor : "transparent"};
          border-color: transparent;
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          outline: none;
        }
        option {
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          text-align: center;
        }
      }

      .roomsWrapperLabel {
        padding: 6px 0;
        font-size: 12px;
        border: 0;
        height: 29px;
        border-radius: 0;
        background: transparent;
        border-color: transparent;
        color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
        font-weight: 500;
        outline: none;
      }
    }
    .roomTypesWrapper {
      height: 100%;
      border-bottom: 1px solid
        ${(prop) => (prop.borderColor ? prop.borderColor : "#999")};
      margin-right: 5px;
      margin-bottom: 20px;
      width: 100%;

      ${media.md} {
        margin-bottom: 0px;
      }
      .roomType-heading {
        width: 100%;
      }
      .roomTypes-inputWrapper {
        select {
          width: 100%;
          margin-top: 10px;
          padding: 0;
          font-size: 12px;
          border: 0;
          height: 29px;
          border-radius: 0;
          background: ${(prop) =>
            prop.secondaryColor ? prop.secondaryColor : "transparent"};
          border-color: transparent;
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          outline: none;
        }
        option {
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          text-align: center;
        }
      }
    }
    .currencyWrapper {
      height: 100%;
      border-bottom: 1px solid
        ${(prop) => (prop.borderColor ? prop.borderColor : "#999")};

      .currency-heading {
      }
      .currency-input-wrapper {
        select {
          margin-top: 10px;
          padding: 0;
          width: 100%;
          font-size: 12px;
          border: 0;
          height: 29px;
          border-radius: 0;
          background: ${(prop) =>
            prop.secondaryColor ? prop.secondaryColor : "transparent"};
          border-color: transparent;
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          outline: none;
        }
        option {
          color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
          text-align: center;
          padding: 5px;
        }
      }
    }
    .promoWrapper {
      margin-bottom: 20px;
      border-bottom: solid 1px
        ${(prop) => (prop.borderColor ? prop.borderColor : "#999")};
      width: 100%;
      ${media.md} {
        margin-bottom: 0;
        margin: 0 10px 0 0;
      }
      ${media.lg} {
      }
      input {
        border: none;
        font-size: 12px;
        font-weight: 500;
        height: 30px;
        color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
        background: transparent;
        padding: 10px 0;
        &:focus {
          border: 0;
          outline: 0;
          /* border-bottom: solid 1px ${constant.bodyColor}; */
        }
      }
    }
    .flexiBoxWrapper {
      width: 100%;
      /* color:#eee; */
      margin-left: 0px;
      display: none;

      ${media.md} {
        display: block;

        min-width: 15%;
        margin-left: 5px;
        width: auto;
      }
      .input-wrapper {
        display: flex;
        justify-content: left;
      }
    }
    .buttonWrapper {
      width: 100%;
      position: relative;
      ${media.md} {
        position: absolute;
        width: 25%;
        transform: translate(120%, 150%);
      }
      button {
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: ${(prop) => (prop.buttonColor ? prop.buttonColor : "#000")};
        color: ${(prop) =>
          prop.secondaryColor ? prop.secondaryColor : "#eee"};
        height: 35px;
        margin-top: 0px;
        border: none;
        border-radius: 15px;
      }
    }
    .inp {
      padding: 0;
      font-size: 15px;
      border: 0;
      height: 29px;
      border-radius: 0;
      background: transparent;
      border-color: transparent;
      color: ${(prop) => (prop.primaryColor ? prop.primaryColor : "#000")};
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 15px;
      height: 29px;
      &.focus {
        border: none;
      }
    }
  }
  .promo-code-wrapper {
    margin-top: 7px;
  }
`;
const BackgroundEffect = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.3, 0.97, 0.74, 1.01);
  z-index: -1;

  .widgetBgOpacity {
    /* animation:widgetBgClose 0.5s linear;
      animation-fill-mode:forwards; */
  }
  &:before {
    content: "";
    position: absolute;
    right: -130rem;
    top: -60rem;
    border-radius: 90%;
    background-color: ${(prop) => (prop.widgetBg ? prop.widgetBg : "#eee")};
    width: 130rem;
    height: 60rem;
    transform: scale(0);
    transition: all 2.5s cubic-bezier(0.3, 0.97, 0.74, 1.01);
    z-index: -1;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 3rem;
  top: 3rem;
  width: 20px;
  height: 20px;
  color: transparent !important;
  cursor: pointer;
  user-select: none;
  font-size: 1.8rem;
  line-height: 2.4rem;
  z-index: -1;
  overflow: hidden;
  animation-fill-mode: forwards;

  ${media.md} {
    right: 4rem;
    top: 4rem;
  }
  &:hover {
    &:before {
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
    }

    &:after {
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
    }
  }
  &:before {
    content: "";
    width: 4rem;
    max-width: 2.4rem;
    height: 1px;
    background-color: ${(prop) => (prop.textColor ? prop.textColor : "#000")};
    /* opacity: 0; */
    position: absolute;
    left: 0;
    transform-origin: left;
    /* animation:closeBefore 0.8s ease; */
    animation-fill-mode: forwards;
    top: 0;
    transform: rotate(45deg);
    opacity: 1;
  }
  &:after {
    content: "";
    width: 4rem;
    height: 1px;
    background-color: ${(prop) => (prop.textColor ? prop.textColor : "#000")};
    position: absolute;
    left: 0;
    /* opacity: 0; */
    transform-origin: left;
    animation-fill-mode: forwards;
    bottom: 0;
    transform: rotate(-45deg);
    opacity: 1;
    /* animation: closeAfter 0.8s ease;  */
  }

  @keyframes openBefore {
    0% {
      max-width: 2.4rem;
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      top: 0;
      transform: rotate(45deg);
      opacity: 1;
      max-width: 3.3rem;
    }
  }
  @keyframes closeBefore {
    0% {
      top: 0;
      transform: rotate(45deg);
      opacity: 1;
      max-width: 3.3rem;
    }
    40% {
      opacity: 0;
    }
    100% {
      max-width: 2.4rem;
      opacity: 0;
    }
  }
  @keyframes openAfter {
    0% {
      max-width: 2.4rem;
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      bottom: 0;
      transform: rotate(-45deg);
      opacity: 1;
      max-width: 3.3rem;
    }
  }
  @keyframes closeAfter {
    0% {
      bottom: 0;
      transform: rotate(-45deg);
      opacity: 1;
      max-width: 3.3rem;
    }
    40% {
      opacity: 0;
    }
    100% {
      max-width: 2.4rem;
      opacity: 0;
    }
  }
`;

const PropertyNameWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  ${media.md} {
    display: block;
  }
  h2 {
    color: ${(prop) => (prop.textColor ? prop.textColor : "#646464")};
    text-transform: uppercase;
    font-size: 1.2em;
  }
`;

const BookingWidget2Component = ({
  propertyName,
  primaryColor,
  secondaryColor,
  textColor,
  backgroundColor,
  buttonColor,
  borderColor,
  datePickerColor,
  widgetBg,
  roomBgColor,
  roomTextColor,
  roomHeadBg,
  roomHeadText,
  roomContentBg,
  roomContentText,
  buttonBgColor,
}) => {
  // const maxGuestCapacity = () => {
  //   let maxGuestCapacity = 0;
  //   roomTypes.map((item) => {
  //     if (maxGuestCapacity < item.RoomTypeName.maxGuestCapacity) {
  //       maxGuestCapacity = item.RoomTypeName.maxGuestCapacity;
  //     }
  //   });

  //   return maxGuestCapacity;
  // };

  return (
    <div>
      <BookingWidgetOutsideWrapper id="bookingWidgetMain" className="default">
        <BookingEngineWidgetComponentWrapper
          className="bookingEngineWrapper"
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          buttonColor={buttonColor}
          borderColor={borderColor}
          datePickerColor={datePickerColor}
          roomBgColor={roomBgColor}
          roomTextColor={roomTextColor}
          roomHeadBg={roomHeadBg}
          roomHeadText={roomHeadText}
          roomContentBg={roomContentBg}
          roomContentText={roomContentText}
          buttonBgColor={buttonBgColor}
        >
          <PropertyNameWrapper textColor={textColor} className="propertyName">
            <h2>{propertyName}</h2>
          </PropertyNameWrapper>
          <CloseButton
            textColor={textColor}
            className="closeButton"
            id="widgetCloseButton"
          />
          <BackgroundEffect className="backgroundEffect" widgetBg={widgetBg} />
          <BookingWidgetFlexComponent
            id="mainBookingWidget"
            buttonLabel="Book"
            className="widgetBgOpacity"
          />
        </BookingEngineWidgetComponentWrapper>
      </BookingWidgetOutsideWrapper>
    </div>
  );
};

export default BookingWidget2Component;
