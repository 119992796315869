import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import Lightbox from "react-image-lightbox";
import { SectionWrapper } from "../Template8Pages/templateConstants";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
} from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NormalLink } from "../Template8Pages/Links";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { bookRoomUrl } from "../../global/constants";
import media from "../../global/media";
import constant from "../Template8Pages/constant";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";
import { Link } from "react-router-dom";

const AccommodationWrapper = styled.div`
  padding: 12px;
  .padding-y {
    margin-bottom: 15px;
  }
  /* ${media.sm}{
  padding: 36px;
}
${media.md}{
  padding: 48px;
}
${media.lg}{
  padding: 80px 61px;
} */
  /* .padding-y{
    padding:40px 17px; 

    ${media.sm}{
      padding:40px 17%;
    }
  } */
  .p-padding {
    padding: 18px 0;
    line-height: 1.7em;
  }
  ul {
    margin-left: 5px;
    margin-top: 15px !important;
    list-style-type: disc;
    ${media.sm} {
      margin-left: 30px;
    }
    /* li::before{
      content:'';
      position:absolute;
      width:5px;
      height:5px;
      background:#000;
      border-radius:50%;
      
      /* transform:translate(0, -50%);
      } */
  }
  .booknow {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    a {
      position: relative;
      margin: 0 10px;
      padding: 12px 38px;
      display: block;
      text-align: center;
      border: 2px solid #fff;
      font-size: 12px;
      text-transform: uppercase;
      -webkit-text-decoration: none;
      text-decoration: none;
      background: rgb(135, 28, 60);
      color: #fff;
      border-radius: 300px;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      font-weight: normal;
      line-height: 22px;
    }
  }
  .icon-padding {
    margin-right: 15px;
  }
`;

const AccommodationImageWrap = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
  img {
    margin-top: 10px;
    object-fit: cover;
    max-height: 156px;
    width: 100%;
    ${media.sm} {
      max-height: 400px;
    }
  }
  .row {
    justify-content: flex-start;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const SliderWrapper = styled.div`
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
  .slick-slider {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 40px;
    height: 40px;
    background: rgba(255, 250, 250, 0.5);
    &:before {
      font-size: 25px;
    }
    &:hover {
      background: rgba(255, 250, 250, 0.8);
      /* background: ${constant.primaryColor}; */
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #000;
  }

  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
`;

const SlideImage = styled.div`
  img {
    width: 99%;
    object-fit: cover;
    object-position: center;
    height: 250px;
    ${media.sm} {
      height: 200px;
    }
    ${media.md} {
      height: 250px;
    }
    ${media.lg} {
      height: 280px;
    }
  }
`;

const NoHeaderImage = styled.div`
  height: 100px;
  ${media.sm} {
    height: 150px;
  }
`;


const LinkWrapper = styled(Link)`
  /* padding: 5px 10px; */
  color: ${constant.bodyColor};
  position: relative;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.6px;
  span {
    font-size: 15px;
  }
`;

const RoomTypeComponent = ({ data, propertyId, templateData }) => {
  // let bookingEngine=templateData.

  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const roomImages = [];
  const imagesMain = data.RoomTypeImages.Images;
  const totalRooms = data.RoomTypeName.totalRooms;
  const maxGuestCapacity = data.RoomTypeName.maxGuestCapacity;

  const breadCrumbName = templateData.menuLinks.menuLink.filter(i=> i.pageId === "2" );
  console.log(breadCrumbName);
  const openLightbox = (type, key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  imagesMain.map((item, index) =>
    roomImages.push({
      image: sliderImageURL + item,
      imgLabel: data.RoomTypeImages.imgLabel[index],
      type: "roomTypes",
    })
  );

  const facilities = data.RoomTypeFacilities
    ? data.RoomTypeFacilities.RooomFacility &&
      data.RoomTypeFacilities.RooomFacility.Facility
      ? data.RoomTypeFacilities.RooomFacility.Facility
      : []
    : [];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.RoomTypeName.section}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      <NoHeaderImage />
      {data.PreviousPageURL && (
        <BreadCrumbComponent 
          previousUrl = {data.PreviousPageURL}
          currentUrl = {data.RoomTypeName.section}
          previousName={breadCrumbName[0].MenuLinkpageName}
        />
      )}
      <AccommodationWrapper>
        <SectionWrapper className="margin padding-y">
          <Grid>
            <Row>
              <Col md={12}>
                <h2 class="left">{data.RoomTypeName.section}</h2>
                <div
                  className="p-padding roomTypeContent"
                  dangerouslySetInnerHTML={{
                    __html: decodeContent(data.RoomTypeDescription),
                  }}
                ></div>
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
        <AccommodationImageWrap>
          <Row>
            {imagesMain.length > 0 ? (
              // <SliderWrapper>
              //   <Slider {...settings}>
              //     {imagesMain.map((item, index) => (
              //       <SlideImage key={index}>
              //         <img
              //           src={sliderImageURL + item}
              //           alt=""
              //           onClick={() => openLightbox("roomTypes", index)}
              //         />
              //       </SlideImage>
              //     ))}
              //   </Slider>
              // </SliderWrapper>

              imagesMain.map((item, index) => (
                <Col xs={6} md={imagesMain.length <= 3 ? 4 : 3}>
                  <ImageWrapper>
                    <img
                      src={sliderImageURL + item}
                      alt=""
                      onClick={() => openLightbox("roomTypes", index)}
                    />
                  </ImageWrapper>
                </Col>
              ))
            ) : (
              <img src={noImageUrl} alt="" />
            )}
          </Row>
        </AccommodationImageWrap>
        <SectionWrapper className="padding-y">
          <Row>
            <Col xs={12} md={12}>
              <Grid className="roomTypeContent ">
                <Row>
                  <Col md={8}>
                    <h4>room specifications:</h4>
                    {totalRooms && maxGuestCapacity && (
                      <Row>
                        <Col md={12}>
                          <p className="p-padding">
                            {totalRooms} Rooms, sleep {maxGuestCapacity} persons
                            / per room
                          </p>
                        </Col>
                      </Row>
                    )}
                    {facilities.length > 0 && (
                      <ul className="facilities room-specification">
                        {data.RoomTypeFacilities.RooomFacility.Facility.map(
                          (item, index) => {
                            return (
                              item !== "" && (
                                <li key={index}>
                                  <FontAwesomeIcon icon={faChevronRight} />
                                  {item}
                                </li>
                              )
                            );
                          }
                        )}
                      </ul>
                    )}
                  </Col>

                  <Col md={4} className="booknow">
                    <NormalLink
                      href={`${bookRoomUrl(data.RoomcatgId)}`}
                      text="Book Now"
                      className="btn-line"
                    />
                  </Col>
                </Row>
              </Grid>
            </Col>
          </Row>
        </SectionWrapper>
        {/* {facilities.length > 0 && (
          <SectionWrapper className="margin" bgColor="#f5f5f5">
            <Grid>
              <Row>
                <Col xs={12} md={12}>
                  <h2>Room Facilities</h2>
                </Col>
                <Col xs={12} md={12}>
                  <ul className="facilities">
                    {facilities.map((value, index) => (
                      <li>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="checked"
                        />{" "}
                        {decodeURIComponent(value)}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Grid>
          </SectionWrapper>
        )} */}
      </AccommodationWrapper>
      {open && (
        <Lightbox
          mainSrc={roomImages[photoIndex].image}
          nextSrc={roomImages[(photoIndex + 1) % roomImages.length]}
          prevSrc={
            roomImages[(photoIndex + roomImages.length - 1) % roomImages.length]
          }
          onCloseRequest={() => setOpen(false)}
          imageCaption={roomImages[photoIndex].imgLabel}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + roomImages.length - 1) % roomImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % roomImages.length)
          }
        />
      )}
    </div>
  );
};

export default RoomTypeComponent;
