import React from "react";

import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import RoomDetailComponent from "./RoomDetailComponent";
import { NormalLink } from "../Template9Pages/Links";
import { bookNowUrl } from "../../global/constants";
import styled from "styled-components";
import media from "../../global/media";


const LinkWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
`;
const NoHeaderImage = styled.div`
    height: 100px;
    background: ${prop => prop.bgColor || "#191919"};

    /* ${media.md}{
  height: 100px;
  } */
`

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const accommodationSlugName = templateData.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  const heading= templateData.menuLinks.menuLink.filter((item) =>
  item.pageId === "2" 
);
console.log(heading);
  return (
    <>
    <NoHeaderImage/>
    <SectionWrapper className="margin" bgColor="#191919">
      <Grid>
        <Row>
          <Col xs={12}>
            { heading[0] && 
              <h2 class="left">
              {heading[0].MenuLinkpageName}
            </h2>
            }
            
          </Col>
        </Row>
        <Row >
          {roomTypes.map((value, index) => {
            return (
              <Col lg={12} md={12} sm={12} xs={12} className="cardCol" key={index}>
                <RoomDetailComponent
                  number={index}
                  value={value}
                  mainSlugName={`${process.env.PUBLIC_URL}` + accommodationSlugName[0]["menuSlugName"]}
                  whiteLabelURL={templateData.whiteLabelURL}
                />
              </Col>
            );
          })}
        </Row>
      </Grid>
    </SectionWrapper>
    </>
  );
};

export default AccommodationComponent;
