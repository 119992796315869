import styled from "styled-components";
import media from "../../global/media";

const SectionWrapper = styled.div`
  padding: 3em 0;
  background: ${(prop) => prop.bgColor || "#fff"};
  word-break: break-all;
  &.margin {
    ${media.md} {
    }
    line-height: 2;
    ul {
      padding-left: 1em;
    }
    ul.facilities {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 100%;
        ${media.sm} {
          width: 50%;
        }
        ${media.md} {
          width: 33%;
        }
        svg {
          color: #999;
          margin-right: 10px;
        }
      }
    }
  }
  h2 {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 30px;
    padding-bottom: 30px;
    position: relative;
    &.left {
      text-align: left;
    }
  }
  .heading-link {
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.sm} {
      justify-content: flex-end;
    }
  }
  .sub-headline {
    text-align: center;
  }
`;

export { SectionWrapper };
