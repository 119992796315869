import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "styled-components";
import { propertyImageURL } from "../../global/constants";
import { NormalLink } from "../Template4Pages/Links";
import BookingWidgetComponent from "../../global/BookingWidgetComponent";
import media from "../../global/media";
import constant from "./constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUsers } from '@fortawesome/fontawesome-free-solid';

const HomePageWelcomeComponentWrapper = styled.div`
  padding: 1em 0;
  width: 80%;
  margin: 0 auto;
  h2 {
    margin-bottom: 40px;
    position: relative;
    font-size: 2em;
    font-weight: 500;
  }
  p {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #666;
    word-break: break-all;
    column-gap: 30px;
    column-width: 16em;
  }
  img {
    width: 100%;
  }
  ${media.md}{
    padding: 4em 0;
  }

`;

const AccommodationBlock = styled.a`
  display: block;
  width: 100%;
  margin-top: 20px;
  position: relative;
  text-decoration: none;
  color: ${constant.primaryColor};
  border: 1px solid #eee;
  &:hover{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  }
`;

const AccommodationImageWrap = styled.div`
  height: ${(prop) => prop.mobileHeight || prop.height};
  ${media.sm} {
    height: ${(prop) => prop.height};
  }
  width: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &:hover {
  }
`;
const AccommodationBlockContent = styled.div`
  padding: 10px 15px;
  h3 {
    font-size: 1em;
    text-transform: uppercase;
    padding: 20px 0;
    color: #000;
    width: 100%;
    font-weight: 600;
    margin: 0;
  }
  p {
    line-height: 2;
  }
`;

const BookingEngingeWidgetComponentWrapper = styled.div`
  padding: 50px 0;
  z-index: 1;
  position: relative;
  background: ${constant.greyColor};
  ${media.md} {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
  }
  h2 {
    text-align: center;
  }

  .bookingWidget {
    padding: 30px;

    display: flex;
    font-size: 14px;
    font-weight: bold;
    flex-wrap: wrap;
    //justify-content: ;
    align-items: center;
    .heading {
      font-size: 25px;
      display: none;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }
    .checkInWrapper,
    .checkOutWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 46%;
        margin-right: 20px;
      }
      ${media.md} {
        width: 20%;
        margin-right: 0;
      }
      .react-datepicker-wrapper {
        width: 100%;
        margin-top: 10px;
        .datepicker-input {
          border-radius: 0;
          padding: 10px;
          font-size: 16px;
          border: 1px solid #ccc;
        }
      }
    }
    input{
      border: 1px solid lightgrey;
      font-size: 16px;
      font-weight: 500;
      height: 65px;
      &:focus{
        border-color: none;
      }
    }
    .roomsWrapper {
      width: 100%;
      margin-bottom: 20px;

      ${media.sm} {
        width: 46%;
        margin-right: 20px;
      }
      ${media.md} {
        width: 20%;
        margin-right: 0;
      }

      .roomsWrapperLabel {
        background: #fff;
        width: 100%;
        padding: 10px;
        border: 1px solid lightgrey;
        font-size: 16px;
        font-weight: 500;
        height: 65px;
        margin-top: 10px;
        display:flex; 
        align-items:center;
      }
      
    }
    .flexibleWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 18%;
      }
      input {
        margin-top: 10px;
        padding: 10px;
        border: solid 1px #ccc;
        font-size: 12px;
        width: 100%;
      }
    }
    .buttonWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.md} {
        width: 18%;
      }
      button {
        font-size:15px;
        padding: 15px;
        width: 100%;
        background: ${constant.primaryColor};
        color: #fff;
        height: 65px;
        margin-top: 10px;
        border: none;
        &:hover{
          background: ${constant.secondaryColor};
        }
      }
    }
  }
`;

const CardWrapper = styled.div`
  width: auto;
  
  .slick-slide {
    padding: 0 10px;
  }
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58,49,56, .8);
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: rgb(135,27,42, .8);
    }
  }
  .slick-prev {
    top: unset;
    bottom: -12%;
    left: 30%;
    ${media.md}{
      left: 40%;
      bottom: -15%;
    }
  }
  .slick-next{
    top: unset;
    bottom: -12%;
    right: 30%;
    ${media.md}{
      right: 40%;
      bottom: -15%;
    }
  }
  .slick-prev:before, .slick-next:before{
    font-family: none;
  }
  &:hover{
    img{
      transition:all 0.4s ease-in-out;
      transform:scale(1.2);  
      opacity:0.6;  
    }
  }
`;

const NormalLinkExtend = styled(NormalLink)`
  margin: 10px 10px;

  & :hover{
    background-color: rgb(58,49,56, .8);
  }
`;

const contentLimit = 400;

const cardSettings = {
  slidesToShow: 2,
  arrows: true,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 1,
        centerMode: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}

const HomePageWelcomeComponent = ({
  propertyName,
  content,
  maxGuestCapacity,
  propertyId,
  roomTypes,
}) => (
  <div>
    <BookingEngingeWidgetComponentWrapper>
      <BookingWidgetComponent
        maxGuestCapacity={maxGuestCapacity}
        propertyId={propertyId}
      />
    </BookingEngingeWidgetComponentWrapper>
    <HomePageWelcomeComponentWrapper>
      <Grid>
        <Row>
          <Col xs={12} md={4}>
            <h2>{propertyName}</h2>
            <p>
              {decodeURIComponent(content.substring(0, contentLimit)) + "..."}
            </p>
            <br />
          </Col>
          <Col xs={12} md={8}>
            <CardWrapper>
              <Slider {...cardSettings}>
                  {roomTypes.map((item, index) => (
                    <AccommodationBlock href={item.RoomTypeName.slugName} key={index}>
                      <AccommodationImageWrap
                        height="300px" className="block-content"
                      >
                        <img
                          src={propertyImageURL + item.RoomTypeImages.Images[0]}
                          alt=""
                        />
                      </AccommodationImageWrap>
                      <AccommodationBlockContent className="block-content">
                        <h3>{item.RoomTypeName.section}</h3>
                        <p>
                          <Row>
                            <Col md={12}>
                              <FontAwesomeIcon icon={faHome} />&nbsp;&nbsp;
                              {item.RoomTypeName.totalRooms} Rooms
                            </Col>
                            <Col md={12}>
                              <FontAwesomeIcon icon={faUsers} />&nbsp;&nbsp;
                              {item.RoomTypeName.maxGuestCapacity} persons/per room
                            </Col>
                          </Row>
                        </p>
                        {/* <p>{item.RoomTypeDescription.substring(0, 100) + "..."}</p> */}
                          <NormalLinkExtend
                            to={item.RoomTypeName.slugName}
                            text="Read more"
                          />
                      </AccommodationBlockContent>
                    </AccommodationBlock>
                  ))}
              </Slider>
            </CardWrapper>
          </Col>
        </Row>
      </Grid>
    </HomePageWelcomeComponentWrapper>
  </div>
);

export default HomePageWelcomeComponent;
