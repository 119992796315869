import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "react-flexbox-grid"

import { SectionWrapper } from "../Template9Pages/templateConstants"
import {
    sliderImageURL,
    noImageUrl,
    decodeContent,
} from "../../global/constants"
import MetaTags from "../../global/MetaTags"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faHome,
    faUsers,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { NormalLink } from "../Template9Pages/Links"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { bookRoomUrl } from "../../global/constants"
import media from "../../global/media"
import constant from "../Template9Pages/constant"
import { Link } from "react-router-dom"
import InsidePageHeaderComponent from "./InsidePageHeaderComponent"

const RoomTypeWrapper = styled.div`
position: relative;

.breadCrumb-wrapper{
  position: absolute;
  width: 100%;
  z-index: 98;
  top: 85px;
  display: none;
  ${media.sm}{
  display: block;
  }
  ${media.sm}{
  top: 190px;
  }
}
`;
const AccommodationImageWrap = styled.div`
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
    position: relative;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    /* ul {
        margin-top: 40px !important;
    } */
    .booknow a {
        width: auto;
        display: inline-block;
        border-radius: 0;
        padding: 10px 20px;
    }
    .iconColor{
      color:${constant.bgBlack};
      transition: all 0.3s ease-in-out;
      &:hover{
        color: #fff;
      }
    }
    .noPadding{
      padding-top: 0;
    }
`

const SliderWrapper = styled.div`
    height: 300px;
    overflow: hidden;
    .slick-slider {
        height: 100%;
    }
    .slick-arrow {
        z-index: 10;
        font-size: 2em;
        width: 50px;
        height: 50px;
        background: ${constant.primaryColor};
        &:before {
            font-size: 30px;
        }
        &:hover {
            background: ${constant.primaryColor};
        }
    }
    .slick-prev:before,
    .slick-next:before {
        font-family: none;
        color: #fff;
    }

    .slick-prev {
        left: 20px;
        top: calc(300px - 50%);
    }
    .slick-next {
        right: 20px;
        top: calc(300px - 50%);
    }
`

const SlideImage = styled.div`
    img {
        width: 100%;
    }
`




const LinkWrapper = styled.a`
    background: ${(prop) => prop.bgColor ? prop.bgColor: constant.primaryColor ? constant.primaryColor :  "#fff"};
    outline: 0;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    margin: 1rem 0 1rem 0;
    padding: 1em 2em;
    border: 1px solid transparent;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    :hover{
      background: #fff;
      color: #000;
    }
`

const RoomTypeComponent = ({ data, templateData }) => {
    const facilities = data.RoomTypeFacilities
        ? data.RoomTypeFacilities.RooomFacility &&
          data.RoomTypeFacilities.RooomFacility.Facility
            ? data.RoomTypeFacilities.RooomFacility.Facility
            : []
        : []
    const breadCrumbName = templateData.menuLinks.menuLink.filter(
        i => i.pageId === "2"
    )

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return (
        <RoomTypeWrapper>
            <MetaTags
                metaTitle={
                    data.metaTitle ? data.metaTitle : data.RoomTypeName.section
                }
                metaDesc={data.metaDesc ? data.metaDesc : ""}
                metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
                propertyFavicon={templateData.propertyDetails.propertyFavicon}
            />
            <InsidePageHeaderComponent
                heading={data.RoomTypeName.section}
                content={data.menuLinkName}
                bgImageOne={"#000"}
                bgImageTwo={"#000"}
                data={data}
                roomType={true}
                facilities={facilities}
                previousUrl={data.PreviousPageURL}
                currentUrl={data.RoomTypeName.section}
                previousName={breadCrumbName[0].MenuLinkpageName}
                LogoWidth={templateData.propertyDetails.propertyLogoWidth}
            />
        </RoomTypeWrapper>
    )
}

export default RoomTypeComponent
