import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "react-flexbox-grid"
import { SectionWrapper } from "./templateConstants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MapComponent from "../../global/MapComponent"
import media from "../../global/media"
import constant from "./constant"
import { decodeContent } from "../../global/constants"
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

// import {useForm} from 'react-hook-form';
import {
    faMapMarkerAlt,
    faPhoneAlt,
    faEnvelope,
    faFax,
    faMobileAlt,
} from "@fortawesome/free-solid-svg-icons"
import { faChrome } from "@fortawesome/free-brands-svg-icons"
// import constant from "./constant";

const ContactWrap = styled.div`
    .icon-span {
        width: 45px;
        margin-right: 10px;
    }
    h2 {
        font-style: normal;
        letter-spacing: -0.02em;
        line-height: 1em;
        text-transform: none;
        padding: 17px 0;
    }
    ${media.md} {
        display: flex;
    }
`

const ContactDetails = styled.div`
    width: 100%;

    padding: 40px;
    h3 {
        font-size: 24px;
        font-weight: normal;
    }
    .contact-detail {
        display: flex;
        align-items: center;
        .icon-settings {
            font-size: 30px;
            color: #212121;
            margin-right: 20px;
        }
    }

    .contactMargin {
        padding: 17px 0;
        a {
            color: ${constant.secondaryColor};
        }
    }
    ${media.md} {
        padding: 17px 0;
        line-height: 2.5;
        h3 {
            font-size: 18px;
            text-transform: uppercase;
            .icon-settings {
                font-size: 20px;
            }
        }
    }
`

const MapWrapper = styled.div`
    width: 100%;
    height: 50%;
    position: relative;
    padding: 17px 0;
`

const ContactForm = styled.div`
    width: 100%;
    padding: 17px 0;
    margin-left: 50px;
    h2 {
        font-size: 35px;
        white-space: normal;
        word-break: keep-all !important;
    }
    label {
        font-family: quiet-sans;
        font-weight: 300;
        font-style: normal;
        font-size: 18px;
        letter-spacing: 0.01em;
        line-height: 1.7em;
        text-transform: none;
        color: #292929;
    }
    input,
    textarea {
        width: 100%;
        padding: 12px;
        margin: 6px 0 4px;
        border: 1px solid #ccc;
        background: #fafafa;
        color: #000;
        font-family: sans-serif;
        font-size: 14px;
        line-height: normal;
        box-sizing: border-box;
        border-radius: 2px;
    }
    button {
        display: inline-block;
        width: auto;
        height: auto;
        padding: 1em 2.5em;
        text-align: center;
        cursor: pointer;
        outline: none;
        appearance: none;
        text-transform: uppercase;
        border: 2px solid #000;
        background: transparent;
        color: #000;
        border-radius: 3px;
        margin-top: 17px;
        font-weight: 600;
        letter-spacing: 3px;
        transition: 0.5s all ease-in-out;
        &:hover {
            color: #fff;
            background: #000;
        }
    }
`

const ContactUsComponent = ({ templateData }) => {
    const contact = templateData.propertyDetails.contactInfo
    const latitude = templateData.propertyDetails.propertyLatitude
    const longitude = templateData.propertyDetails.propertyLongitude
    const googleMap = templateData.propertyDetails.googleMapApi
    const googleMapIcon = templateData.propertyDetails.googleMapIcon
    const address = templateData.propertyDetails.contactInfo.address
    const propertyDetails = templateData.propertyDetails
    return (
        <>
            <BreadCrumbComponent currentUrl={"Contact Us"} />
            <SectionWrapper className="margin" bgColor="#fff">
                <ContactWrap>
                    <ContactDetails>
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <h2 class="left">Get In Touch</h2>
                                </Col>
                                <Col xs={12} ms={12}>
                                    <MapWrapper>
                                        <MapComponent
                                            latitude={latitude}
                                            longitude={longitude}
                                            googleMap={googleMap}
                                            address={address}
                                            iconImage={googleMapIcon}
                                            mapHeight="50vh"
                                            propertyDetails={propertyDetails}
                                        />
                                    </MapWrapper>
                                </Col>

                                <Col xs={12} md={6} className="contactMargin">
                                    <p class="contact-detail">
                                        <span className="icon-span">
                                            <FontAwesomeIcon
                                                icon={faMapMarkerAlt}
                                                className="icon-settings"
                                            />
                                        </span>
                                        {decodeURIComponent(
                                            contact.address.location
                                        )}{" "}
                                        {contact.city}
                                        {contact.address1}{" "}
                                        {contact.address.country}
                                    </p>
                                </Col>
                                {contact.telephone.phone && (
                                    <Col
                                        xs={12}
                                        md={12}
                                        className="contactMargin"
                                    >
                                        <p class="contact-detail">
                                            <span className="icon-span">
                                                <FontAwesomeIcon
                                                    icon={faPhoneAlt}
                                                    className="icon-settings"
                                                />
                                            </span>
                                            {contact.telephone.phone}
                                        </p>
                                    </Col>
                                )}
                                {contact.telephone.fax && (
                                    <Col
                                        xs={12}
                                        md={12}
                                        className="contactMargin"
                                    >
                                        <p class="contact-detail">
                                            <span className="icon-span">
                                                <FontAwesomeIcon
                                                    icon={faFax}
                                                    className="icon-settings"
                                                />
                                            </span>
                                            {contact.telephone.fax}
                                        </p>
                                    </Col>
                                )}

                                {contact.freephone && (
                                    <Col
                                        xs={12}
                                        md={12}
                                        className="contactMargin"
                                    >
                                        <p class="contact-detail">
                                            <span className="icon-span">
                                                <FontAwesomeIcon
                                                    icon={faMobileAlt}
                                                    className="icon-settings"
                                                />
                                            </span>
                                            {contact.freephone}
                                        </p>
                                    </Col>
                                )}
                                {contact.telephone.website && (
                                    <li>
                                        <a
                                            href={
                                                "http://" +
                                                contact.telephone.website
                                            }
                                            target="_blank"
                                        >
                                            <span className="icon-span">
                                                <FontAwesomeIcon
                                                    icon={faChrome}
                                                    className="icon-settings"
                                                />
                                            </span>
                                            {decodeURIComponent(
                                                contact.telephone.website
                                            )}
                                        </a>
                                    </li>
                                )}
                                {contact.email && (
                                    <Col
                                        xs={12}
                                        md={12}
                                        className="contactMargin"
                                    >
                                        <p class="contact-detail">
                                            <span className="icon-span">
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    className="icon-settings"
                                                />
                                            </span>

                                            <a
                                                href={`mailto:${decodeURIComponent(
                                                    contact.email
                                                )}`}
                                            >
                                                {decodeURIComponent(
                                                    contact.email
                                                )}
                                            </a>
                                        </p>
                                    </Col>
                                )}
                            </Row>
                        </Grid>
                    </ContactDetails>
                </ContactWrap>
            </SectionWrapper>
        </>
    )
}

export default ContactUsComponent
