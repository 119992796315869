import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import HomePageWelcomeComponent from "./HomePageWelcomeComponent";
import {propertyImageURL} from "../../global/constants";
import { SectionWrapper } from "../Template4Pages/templateConstants";
import media from "../../global/media";
import { fetchReviews } from "../../providers/api";
import constant from "./constant";
// import { NormalLink } from "../Template4Pages/Links";
import { formatDate1, calculateRating } from "../../global/functions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import MapComponent from "../../global/MapComponent";
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Lightbox from "react-image-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReviewBlock = styled.div`
  display: block;
  margin: 10px 0;
  padding: 20px;
  border-radius: 0px;
  text-align: center;
  position: relative;
`;
const ReviewTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  color: ${constant.primaryColor};
`;
const ReviewLabel = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
`;
const Rating = styled.div`
  margin-bottom: 20px;
`;
const ReviewName = styled.div`
  margin-bottom: 20px;
  font-style: italic;
`;
const ChannelName = styled.div``;

// const ReviewWrapper = styled.div``;

const GalleryImageWrap = styled.div`
  height: 300px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:hover{
      opacity: .5;
    }
    &:before{
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      z-index: 1;
      content: '+';
      color: #fff;
      font-size: 100px;
    }
  }
  .inner-layer{
    background: rgba(15,22,42,.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  &:hover{
    .inner-layer{
      cursor: pointer;
      background: rgb(15,22,42,.8);
      display: block;
    }
    h2{
      z-index: -1;
    }
    &:before{
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      z-index: 1;
      content: '+';
      color: #fff;
      font-size: 100px;
    }
  }
`;

const SliderWrapper = styled.div`
  height: auto;
  margin: auto;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58,49,56, .8);
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: rgb(135,27,42, .8);
    }
  }
  .slick-prev:before, .slick-next:before{
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    top: unset;
    bottom: -20%;
    left: 30%;
    ${media.md}{
      top: 50%;
      left: 20px;
      bottom: unset;
    }
  }
  .slick-next{
    top: unset;
    bottom: -20%;
    right: 30%;
    ${media.md}{
      top: 50%;
      right: 20px;
      bottom: unset;
    }
  }
`;

// const CardWrapper = styled.div`
//   width: auto;
// `;

// const PromotePage = styled.div`
//   width: 100%;
//   position: relative;
//   height: 50vh;
//   ${media.md} {
//     width: 25%;
//     height: 75vh;
//   }
//   img{
//     width: 100%;
//     object-fit: cover;
//     height: 100%;
//   }
//   h2{
//     color: #fff;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 100%;
//   }
// `;

const ImageWrapper = styled.div`
  .slick-container {
    height: 60%;
  }
  .slick-slider, .slick-list, .slick-track {
    height: 300px;
  }
`;

// const NormalLinkExtend = styled(NormalLink)`
//   margin: 10px 10px;
// `;

const MapWrapper = styled.div`
  margin: 20px 0px;
  position: relative;
`;

const LinkWrapper = styled(Link)`
  padding: 20px 5px;
  margin: 15px 0;
  color: #fff;
  position: relative;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  display: block;
  background: ${constant.primaryColor};

  &:hover{
  background: ${constant.secondaryColor};

  }
`;

// const PromotionCard = styled.span``;

const HomePageComponent = ({ templateData, maxGuestCapacity }) => {
  const roomTypes = templateData.roomTypes.roomType;

  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState([]);

  // const promotion = templateData.menuLinks.menuLink;

  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];

  const contact = templateData.propertyDetails.contactInfo;
  const latitude = templateData.propertyDetails.propertyLatitude;
  const longitude = templateData.propertyDetails.propertyLongitude;
  const googleMap = templateData.propertyDetails.googleMapApi;
  const googleMapIcon = templateData.propertyDetails.googleMapIcon;
  const address = templateData.propertyDetails.contactInfo.address;

  const email = contact.email.split(',');//console.log(email);
  
  templateData.propertyImages.image.map((value) => images.push(propertyImageURL + value));
  
  const openLightbox = (key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  function getReviews(propertyID) {
    fetchReviews(propertyID).then((data) => {
      setResultData(data && data.resultValue ? data.resultValue : []);
      setLoading(false);
    });
  }

  useEffect(() => {
    getReviews(templateData.propertyDetails.propertyID);
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  const gallerySettings = {
    slidesToShow: 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      }
    ]
  }

  return (
    <div>
      <HomePageWelcomeComponent
        propertyName={templateData.propertyDetails.propertyName}
        content={templateData.propertyDetails.propertyDescription}
        image={templateData.propertyImages.image[0]}
        maxGuestCapacity={maxGuestCapacity}
        propertyId={templateData.propertyDetails.propertyID}
        roomTypes={roomTypes}
      />
      <SectionWrapper className="promotion1">
          <ImageWrapper>
            <Slider {...gallerySettings}>
                {images.slice(0, 12).map((value, index) => (
                  <GalleryImageWrap key={index} className="img-overlay">
                    <img src={value} onClick={() => openLightbox(index)} alt=""/>
                  </GalleryImageWrap>
                ))}
            </Slider>
          </ImageWrapper>
      </SectionWrapper>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      {templateData.reviewMinderWidget === "1" && (
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={12} className="block-heading">
              <h2>Testimonials</h2>
            </Col>
          </Row>
          <SliderWrapper>
            <Slider {...settings}>
              {!loading ? (
                resultData &&
                resultData.reviewlist &&
                resultData.reviewlist.slice(0, 4).map((item, index) => (
                  <ReviewBlock key={index}>
                      <ReviewTitle>{item.review_title}</ReviewTitle>
                      <ReviewLabel
                        dangerouslySetInnerHTML={{
                          __html: item.your_view,
                        }}
                      ></ReviewLabel>
                      <Rating>
                        {calculateRating(
                          item.rating_overall,
                          constant.goldColor
                        )}
                      </Rating>
                      <ReviewName>
                        {item.guest_name} ({formatDate1(item.review_time)})
                      </ReviewName>
                      <ChannelName>
                        Review from: {item.channel_name}
                      </ChannelName>
                    </ReviewBlock>
                ))
                ) : (
                  <Col xs={12}>Loading...</Col>
                )}
            </Slider>
          </SliderWrapper>
        </Grid>
      </SectionWrapper>
      )}
      <SectionWrapper>
        <Grid>
          <Row className="contactBlock">
            <Col md={4} xs={12}>
              <h2 class="heading">Contact us for direct reservation:</h2>
            </Col>
            <Col md={2} xs={12} className="contactContent">
              <h2>
                <FontAwesomeIcon
                      icon={faPhoneAlt}
                      className="icon-settings"
                /> {contact.telephone.phone}
              </h2>
            </Col>
            <Col md={4} xs={12} className="contactContent">
              {email.map((item, index) => (
                <div class="email">
                  <FontAwesomeIcon
                      icon={faEnvelope}
                      className="icon-settings"
                  /> {item}
                </div>
              ))}
            </Col>
            <Col md={2} xs={12}>
              <LinkWrapper to='/accommodation/'>
                CHECK AVAILABLITY
              </LinkWrapper>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      <MapWrapper>
        <Grid>
          <MapComponent
            latitude={latitude}
            longitude={longitude}
            googleMap={googleMap}
            address={address}
            iconImage={googleMapIcon}
            mapHeight="400px"
          />
        </Grid>
      </MapWrapper>
    </div>
  );
};

export default HomePageComponent;
