import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import CapacityComponent from "./CapacityComponent";
import { formatDate } from "../../global/functions";
import { bookNowUrl } from "../../global/constants";

const BookingWidget = styled.div`
  h2 {
    font-size: 25px;
  }
  .datepicker-input {
    width: 100%;
  }
  button {
    cursor: pointer;
  }
`;

const BookingWidgetComponent = ({ maxGuestCapacity, propertyId }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  // const [device, setDevice] = useState(2);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [roomBox, setRoomBox] = useState(false);
  const [promoCode, setPromoCode] = useState("");

  const [room, setRooms] = useState([
    {
      adult: 1,
      child: 0,
    },
  ]);
  const [roomString, setRoomString] = useState([
    {
      adult: 1,
      child: 0,
    },
  ]);

  const handleStartDate = (date) => {
    setStartDate(date);
    setEndDate(addDays(date, 1));
    setPickerOpen(true);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    setPickerOpen(false);
  };
  const handleInput = (e) => {
    setPromoCode(e.target.value);
  };

  const handleCheckAvailability = () => {
    let roomParameter = "";
    room.map((item, index) => {
      roomParameter += `&adult${index}=${item.adult}&child${index}=${item.child}`;
    });
    window.location.href = `${bookNowUrl}${propertyId}&checkIn=${formatDate(
      startDate
    )}&checkOut=${formatDate(endDate)}&noofrooms=${room.length
      }${roomParameter}&ccode=${promoCode}`;
    return false;
  }
    ;

  // const DatepickerContainer = ({ className, children }) => {
  //   return (
  //     <div style={{ padding: "16px", background: "#216ba5", color: "#fff" }}>
  //       <div className={className}>
  //         <div style={{ background: "#f0f0f0" }}>
  //           What is your favorite day?
  //         </div>
  //         <div style={{ position: "relative" }}>{children}</div>
  //       </div>
  //     </div>
  //   );
  // };

  // const deviceDetect = () =>typeof window.orientation !== "undefined" ? setDevice(2) : setDevice(1);
  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <BookingWidget className="bookingWidget">
            <div className="heading">Book Your Rooms</div>
            <div className="checkInWrapper">
              <div>Arrival</div>
              <DatePicker
                selected={startDate}
                className="datepicker-input"
                onChange={(date) => handleStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                monthsShown={2}
                minDate={new Date()}
                dateFormat={"dd/MM/yyyy"}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
            <div class="checkOutWrapper">
              <div>Departure</div>
              <DatePicker
                //ref={endRef}
                selected={endDate}
                className="datepicker-input"
                onChange={(date) => handleEndDate(date)}
                selectsEnd
                //open={pickerOpen}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={"dd/MM/yyyy"}
                monthsShown={2}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
            <div className="roomsWrapper">
              <div>Rooms</div>
              <CapacityComponent
                roomBox={roomBox}
                setRoomBox={setRoomBox}
                room={room}
                setRooms={setRooms}
                roomString={roomString}
                setRoomString={setRoomString}
                maxGuestCapacity={maxGuestCapacity}
              />
            </div>
            <div className="flexibleWrapper">
              <div>Promo code</div>
              <input
                type="text"
                onChange={handleInput}
                value={promoCode}
                className="promo-code"
              />
            </div>
            <div className="buttonWrapper">
              <div>&nbsp;</div>
              <button type="button" onClick={handleCheckAvailability}>
                Check Availability
              </button>
            </div>
          </BookingWidget>
        </Col>
      </Row>
    </Grid>
  );
};
export default BookingWidgetComponent;
/*<div className="checkbox-div top-padding">
                <input type="checkbox" value="1" className="checkbox" />
                Flexible Dates
              </div>*/
