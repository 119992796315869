import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import Lightbox from "react-image-lightbox";
import CommonComponent from "./CommonComponent";
import { propertyImageURL, sliderImageURL } from "../../global/constants";
import "react-image-lightbox/style.css";

const GalleryWrapper = styled.div`
  margin-top: 40px;
`;
const GalleryImageWrap = styled.div`
  height: 160px;
  margin-bottom: 20px;
  
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
    transition: all .2s ease-in-out;
    & :hover{
      transform: scaleY(1.2);
    }
  }
`;

const GalleryComponent = ({ propertyImage, roomTypes }) => {
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];
  propertyImage.map((value) => images.push(propertyImageURL + value));
  roomTypes.map(
    (item) =>
      item.RoomTypeImages &&
      item.RoomTypeImages.Images.map((item1) =>
        images.push(sliderImageURL + item1)
      )
  );
  const openLightbox = (key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  return (
    <div>
      <CommonComponent>
        <h2>Gallery</h2>
        <GalleryWrapper>
          <Row>
            {images.map((value, index) => (
              <Col xs={12} sm={6} md={4} lg={3} key={index}>
                <GalleryImageWrap>
                  <img src={value} onClick={() => openLightbox(index)} alt=""/>
                </GalleryImageWrap>
              </Col>
            ))}
          </Row>
        </GalleryWrapper>
      </CommonComponent>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default GalleryComponent;
