import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapComponent from "../../global/MapComponent";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import constant from "./constant";
const ContactWrap = styled.div`
  width: 100%;
  margin-top: 50px;
  position: relative;
  .contact-detail {
    position: relative;
    padding-left: 50px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    width: 33%;
    float: left;
    .icon-settings {
      background-color: ${constant.primaryColor};
      line-height: 34px;
      position: absolute;
      left: 0;
      top: 0;
      height: 34px;
      width: 34px;
      text-align: center;
      color: #fff;
      font-size: 10px;
      border-radius: 100%;
      padding: 10px;
    }
  }
  h3{
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 500;
  }
  p{
    line-height: 1.7em;
    font-size: 0.9rem;
  }
`;

const ContactUsComponent = ({ templateData }) => {
  const contact = templateData.propertyDetails.contactInfo;
  const latitude = templateData.propertyDetails.propertyLatitude;
  const longitude = templateData.propertyDetails.propertyLongitude;
  const googleMap = templateData.propertyDetails.googleMapApi;
  const googleMapIcon = templateData.propertyDetails.googleMapIcon;
  const address = templateData.propertyDetails.contactInfo.address;
  return (
    <SectionWrapper className="margin">
      <Grid>
        <h2> Contact Us</h2>
        <Row>
          <Col xs={6}>
            <ContactWrap>
              <Row>
                <Col xs={12} md={12} className="contact-detail">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="icon-settings"
                  />
                  <h3>Address</h3>
                  <p>
                    {contact.address.location} {contact.city} {contact.address1}{" "}
                    {contact.address.country}
                  </p>
                </Col>
                <Col xs={12} md={12} className="contact-detail">
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="icon-settings"
                  />
                  <h3>Telephone</h3>
                  <p>Fax: {contact.telephone.fax}</p>
                  <p>Phone: {contact.telephone.phone}</p>
                  <p>FreePhone: {contact.freephone}</p>
                </Col>
                <Col xs={12} md={12} className="contact-detail">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="icon-settings"
                  />
                  <h3>Email Address</h3>
                  <p>{contact.email}</p>
                </Col>
              </Row>
            </ContactWrap>
          </Col>
          <Col xs={6}>
            <ContactWrap>
              <MapComponent
                latitude={latitude}
                longitude={longitude}
                googleMap={googleMap}
                address={address}
                iconImage={googleMapIcon}
                mapHeight="400px"
              />
            </ContactWrap>
          </Col>
        </Row>
      </Grid>
    </SectionWrapper>
  );
};

export default ContactUsComponent;
