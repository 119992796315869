import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import HomePageWelcomeComponent from "./HomePageWelcomeComponent";
import {propertyImageURL} from "../../global/constants";
import { SectionWrapper } from "../Template3Pages/templateConstants";
import media from "../../global/media";
import { fetchReviews } from "../../providers/api";
import constant from "./constant";
import { NormalLink } from "../Template4Pages/Links";
import { formatDate1, calculateRating } from "../../global/functions";
import Lightbox from "react-image-lightbox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
// import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUsers } from '@fortawesome/fontawesome-free-solid';

const AccommodationBlock = styled.a`
  display: block;
  width: 100%;
  margin-top: 20px;
  position: relative;
  text-decoration: none;
  padding-right: 20px;
  color: ${constant.primaryColor};
`;
const AccommodationImageWrap = styled.div`
  height: ${(prop) => prop.mobileHeight || prop.height};
  ${media.sm} {
    height: ${(prop) => prop.height};
  }
  width: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &:hover {
  }
`;
const AccommodationBlockContent = styled.div`
  padding: 20px 20px 40px;
  h3 {
    font-size: 1em;
    text-transform: uppercase;
    padding: 20px 0;
    color: #000;
    width: 100%;
    font-weight: 600;
    margin: 0;
  }
  p {
    line-height: 2;
  }
`;
const ReviewBlock = styled.div`
  display: block;
  margin: 10px 0;
  padding: 20px;
  border-radius: 0px;
  text-align: center;
  position: relative;
`;
const ReviewTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  color: ${constant.primaryColor};
`;
const ReviewLabel = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
`;
const Rating = styled.div`
  margin-bottom: 20px;
`;
const ReviewName = styled.div`
  margin-bottom: 20px;
  font-style: italic;
`;
const ChannelName = styled.div``;

// const ReviewWrapper = styled.div``;

const GalleryImageWrap = styled.div`
  height: 300px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`;

const SliderWrapper = styled.div`
  height: auto;
  margin: auto;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    &:before {
      font-size: 30px;
    }
  }
  .slick-prev {
    left: 60px;
  }
  .slick-next {
    right: 60px;
  }
  ${media.md} {
    width: 600px;
  }
`;

const CardWrapper = styled.div`
  width: auto;
`;

const PromotePage = styled.div`
  width: 100%;
  position: relative;
  ${media.md} {
    width: 25%;
    &:hover{
      cursor: pointer;
      margin-top: -2%;
      transition-duration: .8s;
      img{
        height: 80vh;
        transition-duration: .8s;
      }
      .inner-layer{
        height: 80vh;
        transition-duration: .8s;
      }
    }
  }
  img{
    width: 100%;
    object-fit: cover;
    height: 50vh;
    ${media.md} {
      height: 75vh;
    }
  }
  h2{
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .inner-layer{
    background: rgba(15,22,42,.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    ${media.md} {
      height: 75vh;
    }
  }
`;

const ImageWrapper = styled.div`
  .slick-container {
    height: 60%;
  }
  .slick-slider, .slick-list, .slick-track {
    height: 300px;
  }
`;

const NormalLinkExtend = styled(NormalLink)`
  margin: 10px 10px;
`;

// const PromotionCard = styled.span``;

const HomePageComponent = ({ templateData, maxGuestCapacity }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState([]);

  const promotion = templateData.menuLinks.menuLink;

  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];
  
  templateData.propertyImages.image.map((value) => images.push(propertyImageURL + value));
  
  const openLightbox = (key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  function getReviews(propertyID) {
    fetchReviews(propertyID).then((data) => {
      setResultData(data && data.resultValue ? data.resultValue : []);
      setLoading(false);
    });
  }

  useEffect(() => {
    getReviews(templateData.propertyDetails.propertyID);
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true
  };

  const cardSettings = {
    dots: true,
    centerMode: true,
    slidesToShow: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  }

  const gallerySettings = {
    slidesToShow: 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  }

  return (
    <div>
      <HomePageWelcomeComponent
        propertyName={templateData.propertyDetails.propertyName}
        content={templateData.propertyDetails.propertyDescription}
        image={templateData.propertyImages.image[0]}
        maxGuestCapacity={maxGuestCapacity}
        propertyId={templateData.propertyDetails.propertyID}
      />
      <SectionWrapper className="promotion">
        {promotion.slice(0, 5).map((item, index) => item.headerImage && (
          <PromotePage key={index}>
            <img src={propertyImageURL + item.headerImage} alt=""/>
            <div className="inner-layer">
              <h2>{item.menuLinkName}</h2>
            </div>
          </PromotePage>
        ))}
      </SectionWrapper>
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={12} className="block-heading">
              <span>Comfort</span>
              <h2>Accommodation</h2>
            </Col>
          </Row>
          <CardWrapper>
            <Slider {...cardSettings}>
                {roomTypes.map((item, index) => (
                  <AccommodationBlock href={item.RoomTypeName.slugName} key={index}>
                    <AccommodationImageWrap
                      height="300px"
                    >
                      <img
                        src={propertyImageURL + item.RoomTypeImages.Images[0] } alt=""
                      />
                    </AccommodationImageWrap>
                    <AccommodationBlockContent className="block-content">
                      <h3>{item.RoomTypeName.section}</h3>
                      <p>
                        <Row>
                          <Col md={12}>
                            <FontAwesomeIcon icon={faHome} />&nbsp;&nbsp;
                            {item.RoomTypeName.totalRooms} Rooms
                          </Col>
                          <Col md={12}>
                            <FontAwesomeIcon icon={faUsers} />&nbsp;&nbsp;
                            {item.RoomTypeName.maxGuestCapacity} persons/per room
                          </Col>
                        </Row>
                      </p>
                      <p>{item.RoomTypeDescription.substring(0, 100) + "..."}</p>
                        <NormalLinkExtend
                          to={item.RoomTypeName.slugName}
                          text="Read more"
                        />
                    </AccommodationBlockContent>
                  </AccommodationBlock>
                ))}
            </Slider>
          </CardWrapper>
        </Grid>
      </SectionWrapper>
      {templateData.reviewMinderWidget === "1" && (
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={12} className="block-heading">
              <span>Review</span>
              <h2>What They Say</h2>
            </Col>
          </Row>
          <SliderWrapper>
            <Slider {...settings}>
              {!loading ? (
                resultData &&
                resultData.reviewlist &&
                resultData.reviewlist.slice(0, 4).map((item, index) => (
                  <ReviewBlock key={index}>
                      <ReviewTitle>{item.review_title}</ReviewTitle>
                      <ReviewLabel
                        dangerouslySetInnerHTML={{
                          __html: item.your_view,
                        }}
                      ></ReviewLabel>
                      <Rating>
                        {calculateRating(
                          item.rating_overall,
                          constant.primaryColor
                        )}
                      </Rating>
                      <ReviewName>
                        {item.guest_name} ({formatDate1(item.review_time)})
                      </ReviewName>
                      <ChannelName>
                        Review from: {item.channel_name}
                      </ChannelName>
                    </ReviewBlock>
                ))
                ) : (
                  <Col xs={12}>Loading...</Col>
                )}
            </Slider>
          </SliderWrapper>
        </Grid>
      </SectionWrapper>
      )}
      <SectionWrapper className="noPadding">
          <ImageWrapper>
            <Slider {...gallerySettings}>
                {images.slice(0, 5).map((value, index) => (
                  <GalleryImageWrap key={index}>
                    <img src={value} onClick={() => openLightbox(index)} alt=""/>
                  </GalleryImageWrap>
                ))}
            </Slider>
          </ImageWrapper>
      </SectionWrapper>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default HomePageComponent;
