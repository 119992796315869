import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { library } from "@fortawesome/fontawesome-svg-core";
import { faChrome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constant from "../../Template/Template10Pages/constant";
import media from "../../global/media";
import JoinOurNewsLetter from "./JoinOurNewsLetterWidget";

const FooterLayout10Wrapper = styled.div`
  width: 100%;
  padding: 10px 10px 50px;

  line-height: 1.8;
  background: ${(prop) => prop.bgColor || constant.primaryColor};

  ${media.sm} {
    text-align: left;
    padding: 50px;
  }

  .icon-span {
    width: 25px;
    margin-right: 10px;
  }
  .skyhi {
    font-size: 12px;
    margin-top: 20px;
  }
  h3 {
    color: #fff;
    font-size: 1.25em;
    margin: 20px 0;
  }
  .contact {
    padding : 0;
    list-style: none;
    flex-wrap: wrap;
    text-transform: uppercase;
    letter-spacing: 1.4;
    color: #fff;
    font-size: 12px;
    position: relative;
    display: flex;

    li {
      margin-bottom: 10px;
      display: block;
      width: 100%;
      ${media.sm} {
        text-align: left;
      }
      .icon-settings {
        font-size: 18px;
        color: #212121;
        margin-right: 10px;
      }
      .contact-label {
        color: ${constant.greyColor};
        padding-right: 10px;
      }
      &.contact-email {
        text-transform: none;
      }
    }

    a,
    p {
      display: flex;
      align-items: center;
    }
  }
  h2 {
    margin-bottom: 10px;
    font-size: 24px;
    position: relative;
    font-weight: lighter;
    padding: 5px 0 5px 0;
  }
  p,
  a {
    font-weight: 300;
    font-style: normal;
    font-size: 12px;

    letter-spacing: 0.01em;
    line-height: 1.7em;
    text-transform: none;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    position: relative;
    text-decoration: none;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .center {
    padding: 0 30%;
  }
  p {
    a {
      font-size: 12px;
    }
  }
`;
const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* margin: 20px 0; */
  flex-wrap: wrap;

  h3 {
    text-align: center;
    color: ${constant.greyColor};
    width: 100%;
    margin-bottom: 5px;
    ${media.sm} {
      text-align: right;
    }
  }
`;

const SocialLink = styled.a`
  color: ${constant.greyColor};
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    color: ${constant.greyColor};
  }
  &:hover {
    color: ${constant.whiteColor};
    /* background: #000; */
    svg {
      color: #fff;
    }
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-bottom: 2em;
  ${media.sm} {
    padding-top: 4em;
    padding-bottom: 4em;
  }
  img {
    max-width: 200px;
    &.logo {
      width: ${(prop) => (prop.width ? prop.width / 2 + "px" : "auto")};
      height: ${(prop) => (prop.height ? prop.height / 2 + "px" : "auto")};
      ${media.sm} {
        width: ${(prop) => (prop.width ? prop.width + "px" : "auto")};
        height: ${(prop) => (prop.height ? prop.height + "px" : "auto")};
      }
    }
  }
`;

const NavBar = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 20px;
  .nav-link {
    color: ${constant.blackColor};
    padding: 0 15px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const FooterLayout10 = ({
  contactInfo,
  socialLinks,
  propertyDetails,
  propertyImages,
  menuLinks,
  footerTop,
  // onNavClick,
  reviewMinderWidget,
  data
}) => {
  // const [open, setOpen] = useState(false);
  // const [photoIndex, setPhotoIndex] = useState(0);

  const onNavExternalClick = (externalLink) => {
    window.open(externalLink, "_blank");
  };
  return (
    <FooterLayout10Wrapper bgColor={constant.primaryColor}>
      <Grid>
        <Row>
          <Col xs={12}>
            <ImgWrapper
              width={propertyDetails.propertyLogoWidth || "auto"}
              height={propertyDetails.propertyLogoHeight || "auto"}
            >
              <img
                className="logo"
                src={propertyDetails.propertyLogo}
                alt={propertyDetails.propertyName}
              />
            </ImgWrapper>
          </Col>
          <Col xs={12} sm={4}>
            <h3>OUR ADDRESS</h3>
            <ul className="contact">
              <li>
                <strong>{propertyDetails.propertyName}</strong>
              </li>
              {contactInfo.address && (
                <li>
                  <a
                    href={
                      "http://maps.google.com/?ie=UTF8&hq=&ll=" +
                      propertyDetails.propertyLatitude +
                      "," +
                      propertyDetails.propertyLongitude +
                      "&z=13"
                    }
                    target="_blank"
                  >
                    {decodeURIComponent(contactInfo.address.location)}{" "}
                    {contactInfo.address.city}, {contactInfo.address.country}
                  </a>
                </li>
              )}
            </ul>
          </Col>
          <Col xs={12} sm={4}>
            <h3>RESERVATION</h3>
            <ul className="contact">
              {contactInfo.telephone.phone && (
                <li>
                  <a
                    href={
                      "tel:" +
                      decodeURIComponent(contactInfo.telephone.phone).replace(
                        /\s/g,
                        ""
                      )
                    }
                  >
                    {/* <span className="icon-span">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      className="icon-settings"
                    />
                  </span> */}
                    Tel.: {decodeURIComponent(contactInfo.telephone.phone)}
                  </a>
                </li>
              )}
              {contactInfo.telephone.fax && (
                <li>
                  <p>
                    {/* <span className="icon-span">
                    <FontAwesomeIcon icon={faFax} className="icon-settings" />
                  </span> */}
                    Fax: {decodeURIComponent(contactInfo.telephone.fax)}
                  </p>
                </li>
              )}
              {contactInfo.freephone && (
                <li>
                  <p>
                    {/* <span className="icon-span">
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className="icon-settings"
                    />
                  </span> */}
                    Free Phone: {decodeURIComponent(contactInfo.freephone)}
                  </p>
                </li>
              )}
              {contactInfo.email && (
                <li>
                  <a href={"mailto:" + decodeURIComponent(contactInfo.email)}>
                    {/* <span className="icon-span">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="icon-settings"
                    />
                </span> */}
                    Email: {decodeURIComponent(contactInfo.email)}
                  </a>
                </li>
              )}
              {contactInfo.website && (
                <li>
                  <a href={"http://" + contactInfo.website} target="_blank">
                    {/* <span className="icon-span">
                    <FontAwesomeIcon
                      icon={faChrome}
                      className="icon-settings"
                    />
                </span> */}
                    Web: {decodeURIComponent(contactInfo.website)}
                  </a>
                </li>
              )}
            </ul>
          </Col>

          <Col xs={12} sm={4}>
            <Row>
              <SocialWrapper>
                {
                  data.is_newsletter === "1"
                    ?
                    <>
                      <JoinOurNewsLetter data={data}/>
                    </>
                    :
                    <>
                    </>

                }

              </SocialWrapper>
            </Row>
            <Row>
              <Col xs={12}>
                <SocialWrapper>
                  {socialLinks.facebook && (
                    <SocialLink target="_blank" href={socialLinks.facebook}>
                      <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                    </SocialLink>
                  )}
                  {socialLinks.twitter && (
                    <SocialLink target="_blank" href={socialLinks.twitter}>
                      <FontAwesomeIcon icon={["fab", "twitter"]} />
                    </SocialLink>
                  )}
                  {socialLinks.linkedIn && (
                    <SocialLink target="_blank" href={socialLinks.linkedIn}>
                      <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                    </SocialLink>
                  )}
                  {socialLinks.googelepluse && (
                    <SocialLink target="_blank" href={socialLinks.googelepluse}>
                      <FontAwesomeIcon icon={["fab", "google-plus-g"]} />
                    </SocialLink>
                  )}
                  {socialLinks.tripadvisor && (
                    <SocialLink target="_blank" href={socialLinks.tripadvisor}>
                      <i class="fa fa-tripadvisor"></i>
                    </SocialLink>
                  )}
                  {socialLinks.instagram && (
                    <SocialLink target="_blank" href={socialLinks.instagram}>
                      <FontAwesomeIcon icon={["fab", "instagram"]} />
                    </SocialLink>
                  )}
                </SocialWrapper>
              </Col>
            </Row>
          </Col>

          <Col xs={12}>
            <p className="skyhi">
              &copy; 2021 {propertyDetails.propertyName} Designed by{" "}
              <a href="https://www.staah.com" target="_blank">
                STAAH
              </a>
            </p>
          </Col>
        </Row>
      </Grid>
    </FooterLayout10Wrapper>
  );
};

export default FooterLayout10;
