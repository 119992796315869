const constant = {
  primaryColor: "#191919",
  secondaryColor: "#fff",
  bgBlack: "rgba(255,255,255, 0.2)",
  borderColor: "#343434",
  blackColor: "#000",
  footerColor: "#496184",
  goldColor: "#d4af37",
  headerColor: "#fff",
  bodyColor: "#fff",
  whiteColor: "#fff",
  buttonColor: "#000",
  greyColor:"rgba(255,255,255, 0.7)"
};
export default constant;
