import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapComponent from "../../global/MapComponent";
import media from "../../global/media";
import constant from "./constant";
// import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
  faFax,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

// import constant from "./constant";

const ContactWrap = styled.div`
  h2 {
    font-weight: normal;
  }
  ${media.md} {
    display: flex;
  }
`;

const ContactDeatils = styled.div`
  width: 100%;

  padding: 40px;
  h3 {
    font-size: 24px;
    font-weight: normal;
  }
  .contact-detail {
    display: flex;
    align-items: center;
    .icon-settings {
      font-size: 25px;
      color: #212121;
      margin-right: 20px;
    }
  }

  .contactMargin {
    margin: 10px 0px;
    a {
      color: ${constant.secondaryColor};
    }
  }
  ${media.md} {
    width: 40%;
    padding: 20px 30px;
    line-height: 2.5;
    h3 {
      font-size: 18px;
      text-transform: uppercase;
      .icon-settings {
        font-size: 20px;
      }
    }
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  ${media.md} {
    width: 60%;
  }
`;

const ContactUsComponent = ({ templateData }) => {
  const contact = templateData.propertyDetails.contactInfo;
  const latitude = templateData.propertyDetails.propertyLatitude;
  const longitude = templateData.propertyDetails.propertyLongitude;
  const googleMap = templateData.propertyDetails.googleMapApi;
  const googleMapIcon = templateData.propertyDetails.googleMapIcon;
  const address = templateData.propertyDetails.contactInfo.address;
  const propertyDetails = templateData.propertyDetails;

  let currentPosition = {
    lat: 19.0676902,
    lng: 72.8374327,
  }

  return (
    <SectionWrapper className="margin" bgColor="#f5f5f5">
      <BreadCrumbComponent
        currentUrl={"Contact Us"}
        bgColor="#f5f5f5"
      />
      <ContactWrap>
        <ContactDeatils>
          <Grid>
            <Row>
              <Col xs={12} md={12}>
                <h2>
                  Get In Touch<span></span>
                </h2>
              </Col>
              <Col xs={12} md={12} className="contactMargin">
                <p class="contact-detail">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="icon-settings"
                  />
                  {decodeURIComponent(contact.address.location)} {contact.city}
                  {contact.address1} {contact.address.country}
                </p>
              </Col>
              {contact.telephone.phone && (
                <Col xs={12} md={12} className="contactMargin">
                  <p class="contact-detail">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      className="icon-settings"
                    />
                    {contact.telephone.phone}
                  </p>
                </Col>
              )}
              {contact.telephone.fax && (
                <Col xs={12} md={12} className="contactMargin">
                  <p class="contact-detail">
                    <FontAwesomeIcon icon={faFax} className="icon-settings" />
                    {contact.telephone.fax}
                  </p>
                </Col>
              )}

              {contact.freephone && (
                <Col xs={12} md={12} className="contactMargin">
                  <p class="contact-detail">
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className="icon-settings"
                    />
                    {contact.freephone}
                  </p>
                </Col>
              )}
              {contact.email && (
                <Col xs={12} md={12} className="contactMargin">
                  <p class="contact-detail">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="icon-settings"
                    />
                    <a href={`mailto:${decodeURIComponent(contact.email)}`}>
                      {decodeURIComponent(contact.email)}
                    </a>
                  </p>
                </Col>
              )}
            </Row>
          </Grid>
        </ContactDeatils>

        <MapWrapper>
          <MapComponent
            latitude={latitude}
            longitude={longitude}
            googleMap={googleMap}
            address={address}
            iconImage={googleMapIcon}
            mapHeight="50vh"
            propertyDetails={propertyDetails}
          />
          {/* <GoogleMap
            // mapContainerStyle={mapStyles}
            zoom={13}
            center={currentPosition}
            style={{ height: "100%" }}>
            {currentPosition.lat ? (
              <Marker position={currentPosition} draggable={false} />
            ) : null}
          </GoogleMap> */}
        </MapWrapper>
      </ContactWrap>
    </SectionWrapper>
  );
};

export default ContactUsComponent;
