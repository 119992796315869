import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import {  noImageUrl } from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SubItemPagesComponentWrapper = styled.div`
  padding: 3em 0;
  h2{
    line-height: 1.25;
    font-size: 2.25rem;
    font-weight: 500;
  }
  p{
    line-height: 1.7em;
    font-size: 0.9rem;
  }
`;

const SliderWrapper = styled.div`
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    &:before {
      font-size: 30px;
    }
  }
  .slick-prev {
    left: 25px;
  }
  .slick-next {
    right: 40px;
  }
`;

const SlideImage = styled.div`
  img {
    width: 100%;
  }
`;

const HeaderImage = styled.div`
  height: 200px;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
`;

const SubItemPagesComponent = ({ data, templateData }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <SubItemPagesComponentWrapper>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.websitetitle}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.headerImage && <HeaderImage bgImage={data.headerImage} />}
      <SectionWrapper className="margin">
        <Grid>
          <Row>
            <Col xs={12} md={6}>
              {data.websitepageImagesSub ? (
                <SliderWrapper>
                  <Slider {...settings}>
                    {data.websitepageImagesSub.MPImagesSub.map(
                      (item, index) => (
                        <SlideImage>
                          <img src={item} alt=""/>
                        </SlideImage>
                      )
                    )}
                  </Slider>
                </SliderWrapper>
              ) : (
                <img src={noImageUrl} alt=""/>
              )}
            </Col>
            <Col xs={12} md={6}>
              <h2>{data.websitetitle}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.websitepagecontent,
                }}
              ></div>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </SubItemPagesComponentWrapper>
  );
};

export default SubItemPagesComponent;