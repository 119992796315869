import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import constant from "./constant";

const LinkWrapper = styled(Link)`
  padding: 10px 20px;
  margin: 10px 10px;
  color: ${constant.secondaryColor};
  position: relative;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  border: 2px solid ${constant.secondaryColor};
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  display: block;
  &:hover{
    color: #fff;
    background: ${constant.secondaryColor};
  }
`;

const AvailabilityWrapper = styled.div`
  display: inline-block;
  padding: 10px 8px;
  text-decoration: none;
  text-decoration: none;
  color: #0e3360;
  position: relative;
  transition: all 1s ease 0s;
  overflow: hidden;
  line-height: 1;
  background-color: #fff;
`;

const NormalLink = ({ to, text }) => (
  <LinkWrapper to={to}>
    {text}
  </LinkWrapper>
);

const AvailabilityBtn = ({ to, text }) => (
  <AvailabilityWrapper to={to}>{text}</AvailabilityWrapper>
);

export { NormalLink, AvailabilityBtn };
