const constant = {
  primaryColor: "#000",
  secondaryColor: "#c39b38",
  bgBlack: "rgba(255,255,255, 0.2)",
  borderColor: "#333",
  blackColor: "#000",
  footerColor: "#496184",
  goldColor: "#FFD700",
  headerColor: "transparent",
  bodyColor: "#646464",
  wColor: "#919191"
};
export default constant;
