import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "styled-components";
// import { propertyImageURL } from "../../global/constants";
// import { NormalLink } from "../Template1Pages/Links";
import BookingWidgetComponent from "../Template1Pages/BookingWidgetComponent";
import media from "../../global/media";
import constant from "./constant";

const HomePageWelcomeComponentWrapper = styled.div`
  padding: 4em 0;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  border-bottom: solid 1px ${constant.borderColor};

  h2 {
    margin-bottom: 40px;
    position: relative;
    font-size: 2em;
  }
  p {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #666;
    word-break: break-all;
  }
  img {
    width: 100%;
  }
`;

const BookingEngingeWidgetComponentWrapper = styled.div`
  padding: 50px 0;
  z-index: 1;
  position: relative;
  background: ${constant.greyColor};
  ${media.md} {
  }
  h2 {
    text-align: center;
  }

  .bookingWidget {
    padding: 30px;

    display: flex;
    font-size: 14px;
    font-weight: bold;
    flex-wrap: wrap;
    //justify-content: ;
    align-items: center;
    .heading {
      font-size: 25px;
      display: none;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }
    .checkInWrapper,
    .checkOutWrapper {
      width: 100%;
      margin-right: 1%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 20%;
      }
      .react-datepicker-wrapper {
        width: 100%;
        margin-top: 10px;
        .datepicker-input {
          border-radius: 0;
          padding: 10px;
          font-size: 12px;
          border: 1px solid #ccc;
        }
      }
    }
    .roomsWrapper {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 1%;

      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 20%;
      }

      .roomsWrapperLabel {
        background: #fff;
        width: 100%;
        padding: 10px;
        border: solid 1px #ccc;
        font-size: 12px;
        font-weight: normal;
      }
    }
    .flexibleWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.sm} {
        width: 48%;
      }
      ${media.md} {
        width: 18%;
      }
      margin-right: 1%;

      input {
        margin-top: 8px;
        padding: 10px;
        border: solid 1px #ccc;
        font-size: 12px;
        width: 100%;
      }
    }
    .buttonWrapper {
      width: 100%;
      margin-bottom: 20px;
      ${media.md} {
        width: 18%;
      }
      button {
        padding: 15px;
        width: 100%;
        background: ${constant.primaryColor};
        color: #fff;
      }
    }
  }
`;

const contentLimit = 400;

const HomePageWelcomeComponent = ({
  propertyName,
  content,
  image,
  maxGuestCapacity,
  propertyId,
}) => (
  <div>
    <BookingEngingeWidgetComponentWrapper>
      <h2>Check Availability</h2>
      <BookingWidgetComponent
        maxGuestCapacity={maxGuestCapacity}
        propertyId={propertyId}
      />
    </BookingEngingeWidgetComponentWrapper>

    <HomePageWelcomeComponentWrapper>
      <Grid>
        <Row>
          <Col xs={12} md={12}>
            <h2>Welcome to {propertyName}</h2>
            <p>
              {decodeURIComponent(content.substring(0, contentLimit)) + "..."}
            </p>
            <br />
          </Col>
        </Row>
      </Grid>
    </HomePageWelcomeComponentWrapper>
  </div>
);

export default HomePageWelcomeComponent;
