import React from "react";
import styled from "styled-components";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
  bookNowUrl,
  bookRoomUrl,
} from "../../global/constants";
import constant from "./constant";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import media from "../../global/media";

const AccommodationWrapper = styled(Link)`
  height: 100%;
  background: #191919;
  text-decoration: none;
  color: ${constant.bodyColor};
  display: block;
  margin-bottom:30px;
  ${media.xs}{
  margin-bottom:0;

  }
.ChangeOrderImage{
  order: 0;
  @media  screen and (min-width: 576px) {
    order: 2;
    }
  
.ChangeImageWrapper{
  @media  screen and (min-width: 576px) {
    margin-left:0;
    margin-top: 0;
    }
}

}
.ChangeOrderContent{
  .card-body{
    @media  screen and (min-width: 576px) {
    margin-left:0;
    margin-top: 0;
    }
  }
}
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translate(0%, 0%);

  }
  p {
    white-space: nowrap;
    font-weight: 400;

  }
  h3 {
    padding-left: 0;
    font-size: 1.3rem;
    font-weight: 500;
    color: ${constant.whiteColor};
    text-transform: none;
    letter-spacing: 1px;
    line-height: 1.2em;
  }

  .card-desc {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.6px;
  }
  .card-body{
    @media  screen and (min-width: 576px) {
      margin-left: calc(10%);
      margin-top: 0;
    }
    
  }
`;

const AccommodationImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  ${media.xs} {
    margin-top: 0;
  }
  img{
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
  /* padding: 5px 10px; */
  color: ${constant.bodyColor};
  position: relative;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.6px;
  span {
    font-size: 15px;
  }
`;
const RoomDetailComponent = ({ value, mainSlugName,whiteLabelURL, number }) => (
  <AccommodationWrapper
    onClick={() => window.scrollTo(0, 0)}
    to={ `${process.env.PUBLIC_URL}` + mainSlugName + value.RoomTypeName.slugName}
  >
    <Row>
      <Col xs={12} sm={6} md={6}  className={` ${ number%2!==0 ? "ChangeOrderImage" : ""}`}>
        <AccommodationImageWrapper className="ChangeImageWrapper">
          {value.RoomTypeImages.Images.length > 0 ? (
            <img
              
              src={sliderImageURL + value.RoomTypeImages.Images[0]}
              alt=""
            />
          ) : (
            <img src={noImageUrl} class="card-img" alt="" />
          )}
        </AccommodationImageWrapper>
      </Col>
      <Col xs={12} sm={6} md={6} className={` ${ number%2!==0 ? "ChangeOrderContent" : ""}`}>
        <div className="card-body">
          <h3>{value.RoomTypeName.section}</h3>
          <div
            className="card-desc"
            dangerouslySetInnerHTML={{
              __html: decodeContent(value.RoomTypeDescription),
            }}
          ></div>
          <div className="accommodationBtn">
            <LinkWrapper>
              Read More <span>→</span>
            </LinkWrapper>
          </div>
        </div>
      </Col>
    </Row>
  </AccommodationWrapper>
);
export default RoomDetailComponent;
