import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import constant from "../Template8Pages/constant";

const LinkWrapper = styled.a`
  padding: 21px 34px;
  margin: 15px 0;
  color: #fff;
  position: relative;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  /* width: 100%; */
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: .24em;
  display: inline-block;
  border-radius: 3px;
  text-transform:uppercase;
  border-color:${constant.buttonColor};
  background: ${constant.buttonColor};
 
`;

const AvailabilityWrapper = styled.div`
  display: inline-block;
  padding: 10px 8px;
  text-decoration: none;
  text-decoration: none;
  color: #0e3360;
  position: relative;
  transition: all 1s ease 0s;
  overflow: hidden;
  line-height: 1;
  background-color: #fff;
`;

const NormalLink = ({ href, text }) => (
  <LinkWrapper href={href} target="_blank">
    {text}
  </LinkWrapper>
);

const AvailabilityBtn = ({ to, text }) => (
  <AvailabilityWrapper target="_blank" to={to}>{text}</AvailabilityWrapper>
);

export { NormalLink, AvailabilityBtn };
