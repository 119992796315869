import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constant from "../../Template/Template4Pages/constant";
// import { Link, NavLink } from "react-router-dom";
// import media from "../../global/media";

const FooterLayout4Wrapper = styled.div`
  width: 100%;
  color: #fff;
  padding: 2em 0;
  word-break: break-all;
  line-height: 1.8;
  background: ${constant.secondaryColor};
  h3 {
    margin-bottom: 20px;
  }
  a {
    color: #fff;
    text-decoration: none;
    }
`;

const SocialWrapper=styled.div`
  display:inline-block;
  transition:all 0.4s linear;
  margin-right: 20px;
  border: solid 1px #fff;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
   transform:scale(1.2);
   background-color:${constant.primaryColor};
   border-color:#000;
   opacity:0.5;

   a{
    color:#000;

   }
  }
`;

const SocialLink = styled.a`
  color: #fff;
  width:100%;

`;

const FooterLayout4 = ({ contactInfo, socialLinks}) => (
  
  <FooterLayout4Wrapper>
    <Grid>
      <Row>
        <Col md={6} xs={12}>
        <h3>CONTACT US</h3>
          <p>
            {contactInfo.address.location}
            <br />
            {contactInfo.address.city}, {contactInfo.address.country}
          </p>
          <p>
            Telephone:
            <a href={`tel://${contactInfo.telephone.phone.replace(/\s/g, "")}`}>
              {contactInfo.telephone.phone}
            </a>
          </p>
          <p>
            Email:
            <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
          </p>
          <p>
            Web:
            <a href={contactInfo.website}>{contactInfo.website}</a>
          </p>
        </Col>
        <Col md={6} xs={12}>
            {socialLinks.facebook && (
              <SocialWrapper>
                <SocialLink href={socialLinks.facebook}  >
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                </SocialLink>
              </SocialWrapper>
                
            )}
            {socialLinks.linkedIn && (
              <SocialWrapper>
                <SocialLink href={socialLinks.linkedIn}>
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                </SocialLink>
              </SocialWrapper>
                
            )}
            {socialLinks.tripadvisor && (
              <SocialWrapper>
                <SocialLink href={socialLinks.tripadvisor}>
                <FontAwesomeIcon icon={["fab", "tripadvisor"]} />
                </SocialLink>
              </SocialWrapper>
               
            )}
        </Col>
      </Row>
    </Grid>
  </FooterLayout4Wrapper>
);

export default FooterLayout4;
