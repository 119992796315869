import React from "react";
import styled from "styled-components";
// import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import media from "../../global/media";
import { propertyImageURL } from "../../global/constants";
// import { bookNowUrl } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import constant from "../../Template/Template3Pages/constant";

const HeaderLayout3Wrapper = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  padding: 15px;
  background: ${constant.primaryColor};
  position: fixed;
  ${media.md} {
    display: none;
    position: relative;
  }
  img{
    height: 50px;
  }
`;

// const NavBarLinkBook = styled.a`
//   position: relative;
//   background: ${constant.primaryColor};
//   padding: 10px 60px;
//   width: calc(100% - 20px);
//   margin: 10px;
//   padding: 20px;
//   display: block;
//   text-align: center;
//   text-transform: uppercase;
//   color: #fff;
//   text-decoration: none;
//   ${media.sm} {
//     position: absolute;
//     width: auto;
//     right: 10px;
//     top: 25%;
//   }
// `;
const NavBarWrapper = styled.div`
  font-size: 1.4em;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  transition: all 0.5ms ease-in;
  color: #000;
  display: flex;
  align-items: center;
  .icon {
    color: #fff;
    position: absolute;
    right: 10px;
  }
  ${media.sm} {
    position: absolute;
    width: auto;
    top: 35%;
    right: 10px;
  }
  ${media.md} {
    display: none;
  }
`;

const HeaderLayout3 = ({ propertyDetails, onNavClick }) => (
  <HeaderLayout3Wrapper>
    <Link to="/">
      <img src={propertyImageURL + propertyDetails.propertyLogo}
        alt={propertyDetails.propertyName}
      />
    </Link>
    <NavBarWrapper onClick={() => onNavClick(false)}>
           <FontAwesomeIcon icon={faBars} className="icon" />
    </NavBarWrapper>
  </HeaderLayout3Wrapper>
);

export default HeaderLayout3;
