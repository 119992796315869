import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { Link } from "react-router-dom";
import HomePageWelcomeComponent from "../Template6Pages/HomePageWelcomeComponent";
import {propertyImageURL} from "../../global/constants";
import { SectionWrapper } from "../Template4Pages/templateConstants";
import media from "../../global/media";
import { fetchReviews } from "../../providers/api";
import constant from "../Template6Pages/constant";
// import { NormalLink } from "../Template6Pages/Links";
import { formatDate1, calculateRating } from "../../global/functions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
// import MapComponent from "../../global/MapComponent";
import {  faCheck, faHome, faUsers } from '@fortawesome/free-solid-svg-icons';
import Lightbox from "react-image-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccommodationBlock = styled.a`
  display: block;
  width: 100%;
  margin: 20px 0 60px 0;
  position: relative;
  text-decoration: none;
  padding: 0 5px;
  color: ${constant.primaryColor};
  background-color: #fff;
  transition: .3s ease;
  &:hover{
    transform: translateY(-15px);
  }
  ${media.md}{
    padding-right: 20px;
  }
`;
const AccommodationImageWrap = styled.div`
  height: ${(prop) => prop.mobileHeight || prop.height};
  ${media.sm} {
    height: ${(prop) => prop.height};
  }
  width: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
const AccommodationBlockContent = styled.div`
  padding: 20px 20px;
  border: 1px solid #eee;
  border-top: none;
  h3 {
    font-size: 1em;
    text-transform: uppercase;
    padding: 20px 0;
    color: #000;
    width: 100%;
    font-weight: 600;
    margin: 0;
  }
  p {
    line-height: 2;
  }
  .icons{
    border-top: 1px solid #eee;
    padding: 10px 0;
    margin-top: 10px;
    .iconColor{
      color: ${constant.secondaryColor};
    }
  }
`;

const ReviewBlock = styled.div`
  display: block;
  margin: 10px 0;
  padding: 20px;
  border-radius: 0px;
  text-align: center;
  position: relative;
`;
const ReviewTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  color: ${constant.primaryColor};
`;
const ReviewLabel = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
`;
const Rating = styled.div`
  margin-bottom: 20px;
`;
const ReviewName = styled.div`
  margin-bottom: 20px;
  font-style: italic;
`;
const ChannelName = styled.div``;

// const ReviewWrapper = styled.div``;

const GalleryImageWrap = styled.div`
  height: 300px;
  ${media.md}{
    height: 750px;
  }
  img {
    object-fit: cover;
    height: 100%;;
    width: 100%;
    cursor: pointer;
    &:hover{
      opacity: .5;
    }
    &:before{
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      z-index: 1;
      content: '+';
      color: #fff;
      font-size: 100px;
    }
  }
  .inner-layer{
    background: rgba(15,22,42,.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  &:hover{
    .inner-layer{
      cursor: pointer;
      background: rgb(15,22,42,.8);
      display: block;
    }
    h2{
      z-index: -1;
    }
    &:before{
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      z-index: 1;
      content: '+';
      color: #fff;
      font-size: 100px;
    }
  }
`;

const SliderWrapper = styled.div`
  height: auto;
  margin: auto;
  margin-bottom: 6em;
  .slick-list {
    height: 100%;
  }
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58,49,56, .8);
    border: 1px solid #eee;
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before, .slick-next:before{
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    top: unset;
    bottom: -20%;
    left: 30%;
    ${media.md}{
      top: unset;
      bottom: -20%;
      left: 40%;
    }
  }
  .slick-next{
    top: unset;
    bottom: -20%;
    right: 30%;
    ${media.md}{
      top: unset;
      bottom: -20%;
      right: 40%;
    }
  }
  ${media.md}{
    width: 750px;
  }
`;

const CardWrapper = styled.div`
  width: auto;
  background: #fff;
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58,49,56, .8);
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before, .slick-next:before{
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    top: unset;
    bottom: -20%;
    left: 30%;
    ${media.md}{
      top: 50%;
      left: -10%;
      bottom: unset;
    }
  }
  .slick-next{
    top: unset;
    bottom: -20%;
    right: 30%;
    ${media.md}{
      top: 50%;
      right: -10%;
      bottom: unset;
    }
  }
`;

// const PromotePage = styled.div`
//   width: 100%;
//   position: relative;
//   height: 50vh;
//   ${media.md} {
//     width: 25%;
//     height: 75vh;
//   }
//   img{
//     width: 100%;
//     object-fit: cover;
//     height: 100%;
//   }
//   h2{
//     color: #fff;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 100%;
//   }
// `;

const PromotionWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  ${media.md}{
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  .slick-container {
    height: 60%;
    ${media.md}{
      height: 100%;
    }
  }
  .slick-slider, .slick-list, .slick-track {
    height: 300px;
    ${media.md}{
      height: 600px;
    }
  }
  ${media.md}{
    width: 50%;
  }
  .slick-arrow {
    display: none !important;
    z-index: 10;
    font-size: 2em;
    width: 60px;
    height: 60px;
    background: #fff;
    &:before {
      font-size: 30px;
    }
    &:hover{
      background: ${constant.secondaryColor};
      transition: 0.5s;
    }
    ${media.md}{
      display: block !important;
    }
  }
  .slick-prev:before, .slick-next:before{
    font-family: none;
    color: ${constant.primaryColor};
  }

  .slick-prev {
    top: unset;
    right: 80px;
    bottom: 0;
    left: unset;
  }
  .slick-next{
    top: unset;
    right: 20px;
    bottom: 0;
  }
`;

// const NormalLinkExtend = styled(NormalLink)`
//   margin: 10px 10px;
// `;

// const LinkWrapper = styled(Link)`
//   padding: 20px 5px;
//   margin: 15px 0;
//   color: #fff;
//   position: relative;
//   text-align: center;
//   font-weight: bold;
//   text-decoration: none;
//   border-radius: 5px;
//   font-size: 14px;
//   width: 100%;
//   display: block;
//   background: ${constant.primaryColor};
// `;

const FacilityWrapper = styled.div`
  width: 100%;
  background: #F8F8F8;
  ul.facilities {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      font-size: 12px;
      ${media.md}{
        width: 33%;
        font-size: 16px;
      }
      line-height: 1.8;
      svg {
        color: #999;
        margin-right: 10px;
      }
    }
  }
  h2{
    text-align: center;
    padding: 10px;
  }
  .centerContent{
    position: relative;
    padding: 1em .5em;
    .checked{
      color: #fff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: ${constant.primaryColor};
      padding: 5px;
    }
    ${media.md}{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 5em;
    }
  }
  ${media.md}{
    width: 50%;
  }
`;

// const PromotionCard = styled.span``;

const HomePageComponent = ({ templateData, maxGuestCapacity }) => {
  const roomTypes = templateData.roomTypes.roomType;

  const facilities = templateData.propertyDetails.facilities.facility;

  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState([]);

  // const promotion = templateData.menuLinks.menuLink;

  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];

  // const contact = templateData.propertyDetails.contactInfo;
  // const latitude = templateData.propertyDetails.propertyLatituds
  
  templateData.propertyImages.image.map((value) => images.push(propertyImageURL + value));
  
  const openLightbox = (key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  function getReviews(propertyID) {
    fetchReviews(propertyID).then((data) => {
      setResultData(data && data.resultValue ? data.resultValue : []);
      setLoading(false);
    });
  }

  useEffect(() => {
    getReviews(templateData.propertyDetails.propertyID);
  });

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  const gallerySettings = {
    slidesToShow: 1
  }

  const cardSettings = {
    dots: false,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <div>
      <HomePageWelcomeComponent
        propertyName={templateData.propertyDetails.propertyName}
        content={templateData.propertyDetails.propertyDescription}
        image={templateData.propertyImages.image[0]}
        maxGuestCapacity={maxGuestCapacity}
        propertyId={templateData.propertyDetails.propertyID}
        roomTypes={roomTypes}
      />
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={12}>
              <h2>Accommodation</h2>
            </Col>
          </Row>
          <CardWrapper>
            <Slider {...cardSettings}>
                {roomTypes.map((item, index) => (
                  <AccommodationBlock href={item.RoomTypeName.slugName} key={index}>
                    <AccommodationImageWrap
                      height="300px"
                    >
                      <img
                        src={propertyImageURL + item.RoomTypeImages.Images[0]} alt=""
                      />
                    </AccommodationImageWrap>
                    <AccommodationBlockContent className="block-content">
                      <h3>{item.RoomTypeName.section}</h3>
                      <p>{item.RoomTypeDescription.substring(0, 100) + "..."}</p>
                      <p className="icons">
                        <Row>
                          <Col md={12}>
                            <FontAwesomeIcon icon={faHome} className="iconColor" />&nbsp;&nbsp;
                            {item.RoomTypeName.totalRooms} Rooms
                          </Col>
                          <Col md={12}>
                            <FontAwesomeIcon icon={faUsers} className="iconColor" />&nbsp;&nbsp;
                            {item.RoomTypeName.maxGuestCapacity} persons/per room
                          </Col>
                        </Row>
                      </p>
                    </AccommodationBlockContent>
                  </AccommodationBlock>
                ))}
            </Slider>
          </CardWrapper>
        </Grid>
      </SectionWrapper>
      <PromotionWrapper>
          <ImageWrapper>
            <Slider {...gallerySettings}>
                {images.slice(0, 12).map((value, index) => (
                  <GalleryImageWrap key={index}>
                    <img src={value} onClick={() => openLightbox(index)} alt=""/>
                  </GalleryImageWrap>
                ))}
            </Slider>
          </ImageWrapper>
          <FacilityWrapper>
            <Grid>
              <div className="centerContent">
                <h2>Facilities</h2>
                <ul className="facilities">
                  {facilities.slice(0, 36).map((item, index) => (
                    <li>
                      <FontAwesomeIcon icon={faCheck} className="checked"/>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          </FacilityWrapper>
      </PromotionWrapper>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      {templateData.reviewMinderWidget === "1" && (
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={12}>
              <h2>Testimonials</h2>
            </Col>
          </Row>
          <SliderWrapper>
            <Slider {...settings}>
              {!loading ? (
                resultData &&
                resultData.reviewlist &&
                resultData.reviewlist.slice(0, 4).map((item, index) => (
                  <ReviewBlock key={index}>
                      <ReviewTitle>{item.review_title}</ReviewTitle>
                      <ReviewLabel
                        dangerouslySetInnerHTML={{
                          __html: item.your_view,
                        }}
                      ></ReviewLabel>
                      <Rating>
                        {calculateRating(
                          item.rating_overall,
                          constant.goldColor
                        )}
                      </Rating>
                      <ReviewName>
                        {item.guest_name} ({formatDate1(item.review_time)})
                      </ReviewName>
                      <ChannelName>
                        Review from: {item.channel_name}
                      </ChannelName>
                    </ReviewBlock>
                ))
                ) : (
                  <Col xs={12}>Loading...</Col>
                )}
            </Slider>
          </SliderWrapper>
        </Grid>
      </SectionWrapper>
      )}
    </div>
  );
};

export default HomePageComponent;
