import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { NavLink } from "react-router-dom";
import media from "../../global/media";
import constant from "../../Template/Template6Pages/constant";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import LanguageTranslatComponent from "../../global/LanguageTranslatComponent";

const NavLayout7Wrapper = styled.div`
  transition: display 0.5ms ease-in-out;

  background: ${constant.primaryColor};

  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 20;
  top: 0;
  right: -250px;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */

  .icon {
    display: flex;
    font-size: 2em;
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      height: 40px;
      width: 1px;
      position: absolute;
      right: -7px;
      top: 0;
      margin-top: -10px;
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;
const NavBarWrapper = styled.div`
  padding: 15px;
  color: ${constant.secondaryColor};
  ${media.md} {
    display: flex;
    margin-top: 0;
    align-items: center;
  }
  img {
    height: 80px;
  }
`;

const NavBarUl = styled.ul`
  list-style-type: none;
  margin: 0;
  background: ${constant.primaryColor};
  position: relative;
  display: none;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${constant.primaryColor};
  }

  &::-webkit-scrollbar {
    width: 10px;
    z-index: 1000;
    background-color: ${constant.primaryColor};
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c39b38;
  }
  ${media.md} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &.desktopPopupMenu {
    display: ${(prop) =>
      prop.navigationToggle === "block" ? "block" : "none"};
    position: absolute;
    z-index: 1;
    width: 100%;
    top: -17px;
    left: 0;
    height: 100vh;
    overflow-y: scroll;
    transition: all 0.5ms fade;
    padding: 20px;
    padding-top: 90px;

    ${media.xs} {
      padding-top: 20px;
    }
    ${media.md} {
      > li {
        width: 100%;
      }
    }
  }
`;
const NavBarLi = styled.li`
  position: relative;
  margin-right: 10px;
  padding: 10px;
  overflow: hidden;

  .faIcon {
    position: absolute;
    right: 10px;
  }
  &.hidden {
    display: none;
  }
  ${media.md} {
    display: inline-block;
  }
  .sublinks {
    position: relative;
    transform: translate(-50%, -50%);
    &:after {
      position: absolute;
      right: 10px;
      content: ${faChevronDown};
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .nav-link {
    text-align: left;
    display: block;
    padding: 5px;
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    position: relative;
    transition: all 0.4s;
    &:hover {
      color: ${constant.secondaryColor};
    }
  }
`;

const NavBarLiUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
  background: ${constant.primaryColor};
  width: 100%;
  display: none;
  &[data-index="${(prop) => prop.subMenuIndex}"] {
    display: block;
  }
  bottom: auto !important;
  z-index: 1;
  position: relative;
`;
const NavBarLiUlLi = styled.li`
  padding: 0;

  border-bottom: 1px solid ${constant.secondaryColor};

  &:last-child {
    border-bottom: none;
  }
  .nav-link {
    padding: 10px;
  }
`;

const NavLayout7 = ({
  navigationSticky,
  navigationToggle,
  menuLinks,
  onNavClick,
  reviewMinderWidget,
}) => {
  let selectedMenuItem = "";

  menuLinks.map(
    (item) =>
      item.subLinks &&
      item.subLinks.map((item1) => {
        if (window.location.pathname === item1.menuSlugName) {
          selectedMenuItem = item.menuSlugName;
        }
      })
  );
  let navHeight = 0;
  useEffect(() => {
    function handleHeight(event) {
      navHeight = document.getElementById("navigation").offsetHeight;
    }
    document.addEventListener("load", handleHeight);
  });

  const [subMenuIndex, setSubMenuIndex] = useState(0);
  const subNav = (index) => {
    setSubMenuIndex(index);
  };

  const onNavExternalClick = (externalLink) => {
    window.open(externalLink, "_blank");
  };
  return (
    <NavLayout7Wrapper
      id="navigation"
      navigationToggle={navigationToggle}
      menuCount={menuLinks.length}
      navHeight={navHeight}
    >
      <Grid>
        <Row>
          <Col xs={12} md={12}>
            <NavBarWrapper
              className={navigationSticky ? "sticky" : ""}
              onClick={() => onNavClick(false)}
            >
              <NavBarUl
                navigationToggle={navigationToggle}
                className="desktopPopupMenu"
              >
                {menuLinks.map((item, index) =>
                  item.menuSlugName === "/reviews/" ||
                  item.menuSlugName === "/review/" ? (
                    reviewMinderWidget === "1" && (
                      <NavBarLi key={index}>
                        <NavLink
                          activeClassName="active-link"
                          onClick={() => onNavClick(false)}
                          className={`nav-link ${
                            selectedMenuItem === item.websiteSlugName
                              ? "active-link"
                              : ""
                          }`}
                          to={`${process.env.PUBLIC_URL}`+ item.menuSlugName}
                          exact
                        >
                          {item.menuLinkName}
                        </NavLink>
                      </NavBarLi>
                    )
                  ) : item.menuExternalLink ? (
                    <NavBarLi key={index}>
                      <NavLink
                        onClick={() =>
                          onNavExternalClick(item.menuExternalLink)
                        }
                        to="#"
                        className={`nav-link`}
                      >
                        {item.menuLinkName}
                      </NavLink>
                    </NavBarLi>
                  ) : (
                    <NavBarLi key={index}>
                    
                      <NavLink
                        activeClassName="active-link"
                        onClick={() => onNavClick(false)}
                        className={`nav-link ${
                          selectedMenuItem === item.websiteSlugName
                            ? "active-link"
                            : ""
                        }`}
                        to={item.menuSlugName}
                        exact
                      >
                        {item.menuLinkName}
                      </NavLink>
                    </NavBarLi>
                  )
                )}
                <div className="langDbBtnSidebar-7">
                  <LanguageTranslatComponent></LanguageTranslatComponent>
                </div>
              </NavBarUl>
            </NavBarWrapper>
          </Col>
        </Row>
      </Grid>
    </NavLayout7Wrapper>
  );
};

export default NavLayout7;
