import styled from "styled-components";
import media from "../../global/media";
import constant from "./constant";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/fontawesome-free-solid";

const SectionWrapper = styled.div`
  background: ${(prop) => prop.bgColor || "#fff"};

  position: relative;
  scroll-snap-type: y mandatory;
  .minheight {
    min-height: 60vh;
  }
  .section-padding {
    padding: 3em 1.5em;
    ${media.sm} {
      padding: 4em 8em;
    }
    ${media.md} {
      padding: 4em 10em;
    }
  }

  .room-types {
    justify-content: center;
  }
  ul.facilities {
    margin: 1.5em 0;
    padding: 0;

    display: flex;
    list-style-type: none;
    width: 100%;
    flex-wrap: wrap;
    li {
      width: 100%;
      font-size: 14px;

      margin-bottom: 10px;
      color: ${constant.bodyColor};
      ${media.sm} {
        width: 50%;
      }
      ${media.md} {
        width: 33%;
        font-size: 18px;
      }

      line-height: 1.8;
      svg {
        color: ${constant.bodyColor};
        margin-right: 10px;
      }
    }
    &.room-specification {
      padding: 0;
      ${media.md} {
        padding-left: 40px;
      }
      li {
        width: 100%;
        ${media.md} {
          width: 100%;
        }
      }
    }
  }
  &.margin {
    ${media.md} {
    }
    line-height: 2;
    ul {
    }
  }

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0.01em;
    line-height: 1.9em;
    text-transform: none;
    color: ${constant.bodyColor};
    /* padding:0 10px; */
  }
  h2 {
    margin-bottom: 15px;
    font-size: 25px;
    position: relative;
    font-weight: lighter;

    margin-top: 45px;
    text-align: center;
    /* padding:0 10px; */

    ${media.sm} {
    }
    span {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 2px;
      background: ${constant.secondaryColor};
    }
    &.left {
      text-align: left;
    }
  }
  h3 {
    /* margin-top:10px; */
    margin-bottom: 10px;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 1.4em;
    text-transform: uppercase;
    /* padding:5px 10px; */
    ${media.sm} {
      line-height: 1.6em;
      font-size: 18px;
      /* padding:0 10px; */
    }
  }
  .heading-link {
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.sm} {
      justify-content: flex-end;
    }
  }
  .sub-headline {
    text-align: center;
  }
  .block-heading {
    text-align: center;
    span {
      font-size: 1.5rem;
      color: ${constant.secondaryColor};
      font-weight: 500;
    }
    h2 {
      font-size: 1.5em;
      margin-top: 0px;
    }
    ${media.md} {
      text-align: center;
      span {
        font-size: 2.5rem;
        color: ${constant.secondaryColor};
        font-weight: 500;
      }
      h2 {
        font-size: 2em;
      }
    }
  }
  .image-fullWidth {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &.noPadding {
    padding: 0;
  }
  .cardCol {
    margin: 20px 0px;
    transition: translateY 5s ease-in;
    .card-body {
      padding: 20px;
      ${media.sm} {
      }
      font-weight: normal;
      ul li strong {
        font-weight: normal;
      }
    }
  }
  &.promotion {
    display: block;
    width: 100%;
    ${media.md} {
      display: flex;
    }
  }
  .slick-prev:before {
    content: ${faChevronLeft};
    color: ${constant.primaryColor};
    font-size: 30px;
  }
  .slick-next:before {
    content: ${faChevronRight};
    color: ${constant.primaryColor};
    font-size: 30px;
  }
  .contactBlock {
    border: 1px solid #ccc;
    h2 {
      font-size: 16px;
    }
    .email {
      font-size: 16px;
      font-weight: 600;
      padding: 10px 0px;
    }
    .icon-settings,
    .heading {
      color: ${constant.primaryColor};
    }
  }
  .checked {
    color: #919191;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
  .roomTypeContent {
    color: ${constant.bodyColor};
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0.01em;
    line-height: 2em;
    text-transform: none;
    margin-bottom: 15px;
    /* padding:0 10px; */
    strong {
      font-weight: normal;
    }
    h4 {
      text-transform: uppercase;
      font-weight: 500;
      padding-bottom: 10px;
      letter-spacing: 0.15em;
      line-height: 1.6em;
    }
    a {
      color: rgb(135, 28, 60);
    }
  }
  .iconColor {
    color: ${constant.primaryColor};
  }
  ul {
    color: ${constant.bodyColor};
    padding-left: 20px;
    /* list-style-type: none; */
  }

  li {
    color: ${constant.bodyColor};
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .content-desc {
    a {
      color: ${constant.secondaryColor};
    }
  }
  .accommodationBtn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 20px;
  }
`;

export { SectionWrapper };
