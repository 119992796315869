import React, { useState } from "react";
import styled from "styled-components";
// import { Grid, Row, Col } from "react-flexbox-grid";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import CapacityComponent from "./CapacityComponent";
import { formatDate } from "../../global/functions";
import { bookNowUrl } from "../../global/constants";
import constant from "./constant";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const BookingWidget = styled.div`
  h2 {
    font-size: 25px;
  }
  .datepicker-input {
    width: 100%;
  }
  button {
    cursor: pointer;
  }
  .inp{
    font-size: 24px !important;
    font-weight: 500;
    height: 70px;
    background: ${constant.primaryColor};
    border: none !important;
    color: #fff;
    outline: none !important;
    padding: 10px 10px 10px 0 !important;
  }
  .roomsWrapper{
    .roomsWrapperLabel{
      background: ${constant.primaryColor} !important;
      border: none !important;
      font-size: 24px !important;
      padding: 15px 10px 10px 0 !important;
    }
  }
  .label{
    font-size: 18px;
  }
`;

const BookingWidgetComponent = ({ maxGuestCapacity, propertyId }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  // const [device, setDevice] = useState(2);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [roomBox, setRoomBox] = useState(false);
  const [promoCode, setPromoCode] = useState("");

  const [room, setRooms] = useState([
    {
      adult: 1,
      child: 0,
    },
  ]);
  const [roomString, setRoomString] = useState([
    {
      adult: 1,
      child: 0,
    },
  ]);

  const handleStartDate = (date) => {
    setStartDate(date);
    setEndDate(addDays(date, 1));
    setPickerOpen(true);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    setPickerOpen(false);
  };
  const handleInput = (e) => {
    setPromoCode(e.target.value);
  };

  const handleCheckAvailability = () => {
    let roomParameter = "";
    room.map((item, index) => {
      roomParameter += `&adult${index}=${item.adult}&child${index}=${item.child}`;
    });
    window.location.href = `${bookNowUrl}${propertyId}&checkIn=${formatDate(
      startDate
    )}&checkOut=${formatDate(endDate)}&noofrooms=${
      room.length
    }${roomParameter}&ccode=${promoCode}`;
  };

  // const DatepickerContainer = ({ className, children }) => {
  //   return (
  //     <div style={{ padding: "16px", background: "#216ba5", color: "#fff" }}>
  //       <div className={className}>
  //         <div style={{ background: "#f0f0f0" }}>
  //           What is your favorite day?
  //         </div>
  //         <div style={{ position: "relative" }}>{children}</div>
  //       </div>
  //     </div>
  //   );
  // };

  // const deviceDetect = () =>typeof window.orientation !== "undefined" ? setDevice(2) : setDevice(1);
  return (
    <BookingWidget className="bookingWidget">
            <div className="heading">Book Your Rooms</div>
            <div className="checkInWrapper">
              <div className="label">Arrival</div>
                  <DatePicker
                  type="date"
                  readOnly="true"
                    selected={startDate}
                    className="datepicker-input inp"
                    onChange={(date) => handleStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={2}
                    minDate={new Date()}
                    dateFormat={"dd/MM/yyyy"}
                  />
            </div>
            <div class="checkOutWrapper">
              <div className="label">Departure</div>
              <DatePicker
              type="date"
              readOnly="true"
                //ref={endRef}
                selected={endDate}
                className="datepicker-input inp"
                onChange={(date) => handleEndDate(date)}
                selectsEnd
                //open={pickerOpen}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={"dd/MM/yyyy"}
                monthsShown={2}
              />
            </div>
            <div className="roomsWrapper">
              <div className="label">Rooms</div>
              <CapacityComponent
                roomBox={roomBox}
                setRoomBox={setRoomBox}
                room={room}
                setRooms={setRooms}
                roomString={roomString}
                setRoomString={setRoomString}
                maxGuestCapacity={maxGuestCapacity}
                className="inp"
              />
            </div>
            <div className="flexibleWrapper">
              <div className="label">Promo code</div>
              <input
                type="text"
                onChange={handleInput}
                value={promoCode}
                className="promo-code inp"
                placeholder="Promo Code"
              />
            </div>
            <div className="buttonWrapper">
              <div className="label">&nbsp;</div>
              <button type="button" onClick={handleCheckAvailability}>
                Check Availability
              </button>
            </div>
          </BookingWidget>
  );
};
export default BookingWidgetComponent;