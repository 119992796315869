import React, { useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants.js";
import media from "../../global/media";
import constant from "./constant";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoomDetailHomePageComponent from "./RoomDetailHomePageComponent";
import { decodeContent, randomImageGenerator } from "../../global/constants";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${media.sm} {
  }

  .card-center {
    display: flex;
    justify-content: center;
  }
  span {
    font-weight: 600;
  }
`;
const AboutUsWrapper = styled.div`
  background: #f9f9f9;
  padding: 40px;
  .image-wrap {
    img {
      max-width: 100%;
      ${media.md} {
        margin-top: -80px;
      }
    }
  }
  .content-wrap {
    a {
      all: unset;
      text-decoration: none;
      cursor: pointer;
      font-weight: normal;
      transition: all 0.5s ease-in-out;
      color: #a08033;
      &:hover {
      }
    }
  }
`;
const PromotionWrapper = styled.div`
  background: ${(prop) => prop.bgColor || "#fff"};
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  width: 95%;
  margin-left: 0;
  ${media.sm} {
    margin-left: 5%;
    flex-direction: row;
  }
  .centerContent {
    position: relative;
    padding: 1em 0.5em;
    width: 100%;
    display: flex;
  }
`;

const HomePageComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const facilities = templateData.propertyDetails.facilities.facility;
  const accommodationSlugName = templateData.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  const contentLimit = 400;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    speed: 1000,
    cssEase: "linear",

    arrow: true,
  };

  return (
    <div>
      <SectionWrapper bgColor="#fff" textColor="#000">
        <Grid>
          <Row>
            <Col xs={12}>
              <AboutUsWrapper>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="content-wrap">
                      <h2>{templateData.propertyDetails.propertyName}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            templateData.aboutUs === "1"
                              ? decodeContent(
                                  templateData.propertyDetails.propertyDescription.substring(
                                    0,
                                    contentLimit
                                  )
                                ) +
                                "..." +
                                `<a href=${process.env.PUBLIC_URL}/about-us/>Read more</a>`
                              : decodeContent(
                                  templateData.propertyDetails
                                    .propertyDescription
                                ),
                        }}
                      ></p>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="image-wrap">
                      <img
                        src={randomImageGenerator(
                          templateData.propertyImages.image
                        )}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </AboutUsWrapper>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      <SectionWrapper bgColor="#fff" padding="0">
        <CardWrapper>
          {roomTypes.map((item, index) => (
            <RoomDetailHomePageComponent
              value={item}
              mainSlugName={`${process.env.PUBLIC_URL}` + accommodationSlugName[0]["menuSlugName"]}
            />
          ))}
        </CardWrapper>
      </SectionWrapper>

      <SectionWrapper bgColor="#fff" textColor="#000">
        <Grid>
          <PromotionWrapper bgColor="#fff">
            <Row>
              <Col xs={12}>
                <h2 class="left">Property Facilities</h2>
              </Col>
            </Row>
            <div className="centerContent">
              <ul className="facilities">
                {facilities.slice(0, 36).map((item, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon={faChevronRight} />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </PromotionWrapper>
        </Grid>
      </SectionWrapper>
    </div>
  );
};

export default HomePageComponent;
