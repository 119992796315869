import React, {useState} from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constant from "../../Template/Template6Pages/constant";
import { Link } from "react-router-dom";
import media from "../../global/media";
import {propertyImageURL} from "../../global/constants";
import Lightbox from "react-image-lightbox";

const FooterLayout6Wrapper = styled.div`
  width: 100%;
  color: #fff;
  padding: 2em 0;
  word-break: break-all;
  line-height: 1.8;
  background: ${constant.primaryColor};
  h3 {
    margin-bottom: 20px;
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover{
      color: ${constant.secondaryColor};
    }
  }
  img{
    height: 50px;
    ${media.md}{
      height: 150px;
    }
  }
  .gallery{

    .image-wrap:hover{
      transition:all 0.4s ease-in-out;
      transform:scale(1.1);
      opacity:0.7;
    }
    img{
      width: 100%;
      object-fit: cover;
      height: 50px;
      cursor: pointer;
    }
  }
`;

const SocialLink = styled.a`
  color: #fff;
  border-radius: 5px;
  padding: 8px 12px;
  margin-right: 20px;
  border: solid 1px #fff;
  &:hover{
    color: ${constant.secondaryColor};
    border: solid 1px ${constant.secondaryColor};
  }
`;

const FooterLayout6 = ({ contactInfo, socialLinks, propertyDetails, propertyImages }) => {
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];
  propertyImages.image.map((value) => images.push(propertyImageURL + value));

  const openLightbox = (key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  return(
    <FooterLayout6Wrapper>
      <Grid>
        <Row>
          <Col xs={12} md={2}>
              <Link to="/">
                  <img
                  src={propertyDetails.propertyLogo}
                  alt={propertyDetails.propertyName}
                  />
              </Link>
          </Col>
          <Col xs={12} md={3} className="gallery">
            <h3>Gallery</h3>
            <Row>
              {images.slice(0, 6).map((value, index) => (
                <Col md={4} xs={4} className='image-wrap'>
                  <img src={value} onClick={() => openLightbox(index)} alt=""/>
                </Col>
              ))}
            </Row>
          </Col>
          {open && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
            />
          )}
          <Col xs={12} md={5}>
            <h3>Contact Us</h3>
            <p>
              {contactInfo.address.location}
              <br />
              {contactInfo.address.city}, {contactInfo.address.country}
            </p>
            <p>
              Telephone:
              <a href={`tel://${contactInfo.telephone.phone.replace(/\s/g, "")}`}>
                {contactInfo.telephone.phone}
              </a>
            </p>
            <p>
              Email:
              <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
            </p>
            <p>
              Web:
              <a href={contactInfo.website}>{contactInfo.website}</a>
            </p>
          </Col>
          <Col xs={12} md={2}>
              <h3>Stay in Touch</h3>
            {socialLinks.facebook && (
              <SocialLink href={socialLinks.facebook}>
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
              </SocialLink>
            )}
            {socialLinks.linkedIn && (
              <SocialLink href={socialLinks.linkedIn}>
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
              </SocialLink>
            )}
            {socialLinks.tripadvisor && (
              <SocialLink href={socialLinks.tripadvisor}>
                <FontAwesomeIcon icon={["fab", "tripadvisor"]} />
              </SocialLink>
            )}
          </Col>
        </Row>
        
      </Grid>
    </FooterLayout6Wrapper>
  );
}

export default FooterLayout6;
