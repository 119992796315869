import React, { useState } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "styled-components";
import { decodeContent } from "../../global/constants";

// import BookingWidgetFlexComponent from "../../global/WidgetComponent/BookingWidgetFlexComponent";
import media from "../../global/media";
import constant from "../Template8Pages/constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SectionWrapper } from "./templateConstants";
import { NavLink } from "react-router-dom";

const HomePageWelcomeComponentWrapper = styled.div`
  padding: 4em 0;
  text-align: center;

  p {
    font-size: 18px;
    line-height: 1.9em;
    letter-spacing: 0.5px;
    color: ${constant.bodyColor};
    padding: 10px 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  /* ${media.sm} {
    padding:4em 8em ;
  }
  ${media.md} {
    padding:4em 12em ;
  }
  ${media.lg} {
    padding:4em 15em ;
  } */

  .text-button {
    position: relative;
    transition: 0.6s all ease-in-out;
    a {
      all: unset;
      text-decoration: none;
      cursor: pointer;
      color: rgb(135, 28, 60);
      font-weight: normal;
      transition: all 0.5s ease-in-out;
      &:hover {
        color: #000;
      }
    }
  }
`;
const BookingWidgetComponent = styled.div`
  width: 100%;
  color: #000;
  background-color: rgb(255, 250, 250);
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const LinkWrapper = styled(NavLink)`
  padding: 8px 12px;
  color: #000;
  background: rgb(255, 250, 250);
  border-radius: 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  border: 2px solid #000;
  font-size: 16px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: rgb(255, 250, 250);
    background: #000;
  }
`;

const contentLimit = 400;

const HomePageWelcomeComponent = ({ propertyName, content, aboutUs }) => (
  <div>
    <HomePageWelcomeComponentWrapper>
      <SectionWrapper>
        <Grid>
          <Row>
            <Col xs={12} md={12}>
              <h2>{propertyName}</h2>
              <div className="text-button">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      aboutUs === "1"
                        ? decodeContent(content.substring(0, contentLimit)) +
                          "..." +
                          ` <a href=${process.env.PUBLIC_URL}/about-us/>Read more</a>`
                        : decodeContent(content),
                  }}
                ></p>
                {/* {aboutUs === "1" && (
                  <LinkWrapper to="/about-us/">Read more</LinkWrapper>
                )} */}
              </div>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </HomePageWelcomeComponentWrapper>
  </div>
);

export default HomePageWelcomeComponent;
