import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constant from "../../Template/Template3Pages/constant";
import { Link } from "react-router-dom";
import media from "../../global/media";

const FooterLayout3Wrapper = styled.div`
  width: 100%;
  color: #fff;
  padding: 2em 0;
  word-break: break-all;
  line-height: 1.8;
  background: ${constant.primaryColor};
  h3 {
    margin-bottom: 20px;
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover{
      color: ${constant.secondaryColor};
    }
  }
  img{
    height: 50px;
    ${media.md}{
      height: 150px;
    }
  }
`;

const SocialLink = styled.a`
  color: #fff;
  border-radius: 5px;
  padding: 8px 12px;
  margin-right: 20px;
  border: solid 1px #fff;
  &:hover{
    color: ${constant.secondaryColor};
    border: solid 1px ${constant.secondaryColor};
  }
`;

const FooterLayout3 = ({ contactInfo, socialLinks, menuLinks, propertyDetails }) => (
    
  <FooterLayout3Wrapper>
    <Grid>
      <Row>
        <Col xs={12} md={3}>
            <Link to="/">
                <img
                src={propertyDetails.propertyLogo}
                alt={propertyDetails.propertyName}
                />
            </Link>
        </Col>
        <Col xs={12} md={5}>
          <h3>CONTACT US</h3>
          <p>
            {contactInfo.address.location}
            <br />
            {contactInfo.address.city}, {contactInfo.address.country}
          </p>
          <p>
            Telephone:
            <a href={`tel://${contactInfo.telephone.phone.replace(/\s/g, "")}`}>
              {contactInfo.telephone.phone}
            </a>
          </p>
          <p>
            Email:
            <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
          </p>
          <p>
            Web:
            <a href={contactInfo.website}>{contactInfo.website}</a>
          </p>
        </Col>
        <Col xs={12} md={4}>
            <h3>STAY IN TOUCH</h3>
          {socialLinks.facebook && (
            <SocialLink href={socialLinks.facebook}>
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </SocialLink>
          )}
          {socialLinks.linkedIn && (
            <SocialLink href={socialLinks.linkedIn}>
              <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
            </SocialLink>
          )}
          {socialLinks.tripadvisor && (
            <SocialLink href={socialLinks.tripadvisor}>
              <FontAwesomeIcon icon={["fab", "tripadvisor"]} />
            </SocialLink>
          )}
        </Col>
      </Row>
    </Grid>
  </FooterLayout3Wrapper>
);

export default FooterLayout3;
