import React from "react";

import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "./templateConstants";
import RoomDetailComponent from "./RoomDetailComponent";
import { NormalLink } from "../Template8Pages/Links";
import { bookNowUrl } from "../../global/constants";
import styled from "styled-components";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

const LinkWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
`;

const AccommodationComponent = ({ templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const accommodationSlugName = templateData.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  return (
    <SectionWrapper className="margin" bgColor="#fff">
      <BreadCrumbComponent 
        currentUrl = {"Accommodation"}
        
      />
      <Grid>
        <Row>
          <Col xs={12}>
            <h2 class="left">
              All Accommodation at {templateData.propertyDetails.propertyName}
            </h2>
          </Col>
        </Row>
        <Row className="room-types">
          {roomTypes.map((value, index) => {
            return (
              <Col lg={12} md={12} xs={12} className="cardCol" key={index}>
                <RoomDetailComponent
                  value={value}
                  mainSlugName={`${process.env.PUBLIC_URL}${accommodationSlugName[0]["menuSlugName"]}`}
                  whiteLabelURL={templateData.whiteLabelURL}
                />
              </Col>
            );
          })}
        </Row>
      </Grid>
    </SectionWrapper>
  );
};

export default AccommodationComponent;
